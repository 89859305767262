import React, { useState } from 'react';
import { StyleSheet, View, ScrollView, FlatList, Text, Alert, TouchableOpacity } from 'react-native';
import { Card, Title, Button, Provider as PaperProvider, Portal, Modal } from 'react-native-paper';

// INITIAL DUMMY DATA
// Includes subgame4 under "Basketball - 2023"
const initialFolderData = [
  {
    id: 'folder1',
    type: 'folder',
    title: 'Football - 2023',
    games: [
      {
        id: 'game1',
        title: 'Kids',
        subGames: [
          { id: 'subgame0', title: 'Practice Session', type: 'subgame' }
        ]
      },
      {
        id: 'game2',
        title: 'Adults',
        subGames: [
          { id: 'subgame1', title: 'Match A', type: 'subgame' },
          { id: 'subgame2', title: 'Match B', type: 'subgame' },
          { id: 'subgame3', title: 'Friendly Game', type: 'subgame' }
        ]
      }
    ]
  },
  {
    id: 'folder2',
    type: 'folder',
    title: 'Basketball - 2023',
    games: [
      {
        id: 'game3',
        title: 'Match C',
        subGames: [
          { id: 'subgame4', title: 'Warmup Game', type: 'subgame' }
        ]
      }
    ]
  }
];

// MODAL: DISPLAYS MOVE OPTIONS
const MoveOptionsModal = ({ visible, onDismiss, onSelect, folders }) => {
  /**
   * We build two types of options for each folder:
   * 1) The folder itself as a "folder root" (e.g., "Football - 2023 (folder root)")
   * 2) Each game inside that folder (e.g., "Football - 2023 / Kids", "Football - 2023 / Adults")
   */
  const options = [];

  folders.forEach(folder => {
    // Option 1: The folder itself (folder root)
    options.push({
      key: `root-${folder.id}`,
      label: `${folder.title} (folder root)`,
      destination: { folderId: folder.id, gameId: null } // gameId=null => add as a new game in that folder
    });

    // Option 2: Each game in the folder
    folder.games.forEach(game => {
      options.push({
        key: `${folder.id}-${game.id}`,
        label: `${folder.title} / ${game.title}`,
        destination: { folderId: folder.id, gameId: game.id }
      });
    });
  });

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalContainer}>
        <Text style={styles.modalTitle}>Move To:</Text>
        {options.map(option => (
          <TouchableOpacity
            key={option.key}
            style={styles.optionItem}
            onPress={() => onSelect(option.destination)}
          >
            <Text>{option.label}</Text>
          </TouchableOpacity>
        ))}
        <Button mode="contained" onPress={onDismiss} style={styles.closeButton}>
          Cancel
        </Button>
      </Modal>
    </Portal>
  );
};

/**
 * NESTED FOLDER COMPONENT
 * Renders a folder that appears inside the subGames of a game (recursive approach).
 */
const NestedFolder = ({ id, type, title, games, onMovePress }) => {
  return (
    <View style={styles.nestedFolderContainer}>
      <Card style={styles.nestedFolderCard}>
        <Card.Content>
          <Title>{title}</Title>
          <Text style={styles.idText}>Folder ID: {id}</Text>
          <Button mode="outlined" onPress={() => onMovePress({ type, id, title })}>
            Move Folder
          </Button>
        </Card.Content>
      </Card>
      {games && games.length > 0 ? (
        games.map(game => (
          <GameCard key={game.id} {...game} onMovePress={onMovePress} />
        ))
      ) : (
        <Text style={styles.noGamesText}>No Games Available</Text>
      )}
    </View>
  );
};

/**
 * FOLDER CARD (TOP-LEVEL)
 * Renders a top-level folder with its games.
 */
const FolderCard = ({ id, type, title, games, onMovePress }) => (
  <View style={styles.folderContainer}>
    <Card style={styles.folderCard}>
      <Card.Content>
        <Title>{title}</Title>
        <Text style={styles.idText}>Folder ID: {id}</Text>
        <Button mode="outlined" onPress={() => onMovePress({ type, id, title })}>
          Move Folder
        </Button>
      </Card.Content>
    </Card>
    <View style={styles.gamesContainer}>
      {games && games.length > 0 ? (
        games.map(game => (
          <GameCard key={game.id} {...game} onMovePress={onMovePress} />
        ))
      ) : (
        <Text style={styles.noGamesText}>No Games Available</Text>
      )}
    </View>
  </View>
);

/**
 * GAME CARD
 * Renders a single game and its subGames (which may include folders or subgames).
 */
const GameCard = ({ id, title, subGames, onMovePress }) => (
  <View style={styles.gameContainer}>
    <Card style={styles.gameCard}>
      <Card.Content>
        <Title>{title}</Title>
        <Text style={styles.idText}>Game ID: {id}</Text>
      </Card.Content>
    </Card>

    {subGames && subGames.length > 0 ? (
      <View style={styles.subGamesContainer}>
        <FlatList
          data={subGames}
          keyExtractor={item => item.id}
          numColumns={1}
          renderItem={({ item }) => {
            if (item.type === 'folder') {
              // It's a nested folder
              return (
                <NestedFolder
                  key={item.id}
                  {...item}
                  onMovePress={onMovePress}
                />
              );
            } else {
              // It's a normal subgame
              return (
                <View style={styles.subGameWrapper}>
                  <Card style={styles.subGameCard}>
                    <Card.Content>
                      <Title>{item.title}</Title>
                      <Text style={styles.idText}>SubGame ID: {item.id}</Text>
                    </Card.Content>
                  </Card>
                  <Button
                    mode="outlined"
                    onPress={() => onMovePress({ type: 'subgame', id: item.id, title: item.title })}
                    style={styles.subGameButton}
                  >
                    Move SubGame
                  </Button>
                </View>
              );
            }
          }}
          contentContainerStyle={styles.subGamesList}
        />
      </View>
    ) : (
      <Text style={styles.noGamesText}>No Sub Games Available</Text>
    )}
  </View>
);

export default function App() {
  // State for folder data
  const [folders, setFolders] = useState(initialFolderData);
  // State for modal visibility
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  // State for the item being moved
  const [itemToMove, setItemToMove] = useState(null);

  // Called when user presses "Move" on a folder or subgame
  const handleMovePress = item => {
    setItemToMove(item);
    setMoveModalVisible(true);
  };

  // Called when the user selects a destination from the modal
  const handleMoveDestinationSelect = destination => {
    if (!itemToMove) return;

    if (itemToMove.type === 'folder') {
      moveFolder(itemToMove.id, destination);
    } else if (itemToMove.type === 'subgame') {
      moveSubgame(itemToMove.id, itemToMove.title, destination);
    }

    setMoveModalVisible(false);
    setItemToMove(null);
  };

  /**
   * Move an entire folder (with its nested games/subgames) either:
   *  - into the folder's "games" array if gameId=null (folder root)
   *  - or into subGames of a specific game
   */
  const moveFolder = (folderId, destination) => {
    const movingFolder = findFolderInState(folders, folderId);
    if (!movingFolder) return;

    // Remove from wherever it currently is
    let updatedFolders = removeFolderFromState(folders, folderId);

    // If gameId is null => treat as a new game in the folder
    if (destination.gameId === null) {
      const destFolderIndex = updatedFolders.findIndex(f => f.id === destination.folderId);
      if (destFolderIndex === -1) {
        Alert.alert('Destination folder not found');
        return;
      }
      const destFolder = updatedFolders[destFolderIndex];

      // We'll treat the moving folder as a "game" in that folder's games array
      const newGameId = `game_${movingFolder.id}`;
      const newGame = {
        id: newGameId,
        title: movingFolder.title + ' (as game)',
        subGames: movingFolder.games || [],
      };
      destFolder.games.push(newGame);

      setFolders([...updatedFolders]);
    } else {
      // Otherwise, place it in subGames of the specified game
      const destFolderIndex = updatedFolders.findIndex(f => f.id === destination.folderId);
      if (destFolderIndex === -1) {
        Alert.alert('Destination folder not found');
        return;
      }
      const destFolder = updatedFolders[destFolderIndex];
      const destGameIndex = destFolder.games.findIndex(g => g.id === destination.gameId);
      if (destGameIndex === -1) {
        Alert.alert('Destination game not found');
        return;
      }
      destFolder.games[destGameIndex].subGames.push(movingFolder);

      setFolders([...updatedFolders]);
    }
  };

  /**
   * Move a subgame to either:
   *  - folder root => new game in that folder
   *  - subGames of an existing game
   *
   *  FIX: if we move subgame to folder root, let's actually place
   *       that subgame in the new game we create, so we can see it
   */
  const moveSubgame = (subgameId, subgameTitle, destination) => {
    let updatedFolders = removeSubgameById(folders, subgameId);

    if (destination.gameId === null) {
      // "folder root" => create a new game in that folder,
      // and actually put the subgame inside it
      const destFolderIndex = updatedFolders.findIndex(f => f.id === destination.folderId);
      if (destFolderIndex === -1) {
        Alert.alert('Destination folder not found');
        return;
      }
      const destFolder = updatedFolders[destFolderIndex];

      const newGameId = `game_${subgameId}`;
      // FIX: subGames includes the subgame so we can see it
      const newGame = {
        id: newGameId,
        title: subgameTitle + ' (as game)',
        subGames: [
          {
            id: subgameId,
            title: subgameTitle,
            type: 'subgame'
          }
        ]
      };
      destFolder.games.push(newGame);

      setFolders(updatedFolders);
    } else {
      // Move into subGames of an existing game
      const destFolderIndex = updatedFolders.findIndex(f => f.id === destination.folderId);
      if (destFolderIndex === -1) {
        Alert.alert('Destination folder not found');
        return;
      }
      const destFolder = updatedFolders[destFolderIndex];
      const destGameIndex = destFolder.games.findIndex(g => g.id === destination.gameId);
      if (destGameIndex === -1) {
        Alert.alert('Destination game not found');
        return;
      }

      // Add subgame
      destFolder.games[destGameIndex].subGames.push({
        id: subgameId,
        title: subgameTitle,
        type: 'subgame'
      });

      setFolders(updatedFolders);
    }
  };

  // Helper: Find a folder by ID (top-level or nested)
  const findFolderInState = (foldersState, folderId) => {
    // Check top-level
    const topLevel = foldersState.find(f => f.id === folderId);
    if (topLevel) return topLevel;

    // Otherwise, search nested subGames
    for (let folder of foldersState) {
      for (let game of folder.games) {
        for (let sub of game.subGames) {
          if (sub.type === 'folder' && sub.id === folderId) {
            return sub;
          }
        }
      }
    }
    return null;
  };

  // Helper: Remove a folder from top-level or nested subGames
  const removeFolderFromState = (foldersState, folderId) => {
    // Remove from top-level
    let updated = foldersState.filter(f => f.id !== folderId);

    // Also remove from subGames anywhere
    updated = updated.map(folder => ({
      ...folder,
      games: folder.games.map(game => ({
        ...game,
        subGames: game.subGames.filter(sub => {
          if (sub.type === 'folder' && sub.id === folderId) {
            return false;
          }
          return true;
        })
      }))
    }));

    return updated;
  };

  // Helper: Remove a subgame from any subGames array
  const removeSubgameById = (foldersState, subgameId) => {
    return foldersState.map(folder => ({
      ...folder,
      games: folder.games.map(game => ({
        ...game,
        subGames: game.subGames.filter(sub => sub.id !== subgameId)
      }))
    }));
  };

  return (
    <PaperProvider>
      <ScrollView contentContainerStyle={styles.container}>
        {folders.map(folder => (
          <FolderCard
            key={folder.id}
            {...folder}
            onMovePress={handleMovePress}
          />
        ))}
      </ScrollView>

      <MoveOptionsModal
        visible={moveModalVisible}
        onDismiss={() => setMoveModalVisible(false)}
        onSelect={handleMoveDestinationSelect}
        folders={folders}
      />
    </PaperProvider>
  );
}

// STYLES
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 10,
    backgroundColor: '#fff',
  },
  folderContainer: {
    marginBottom: 20,
  },
  folderCard: {
    marginBottom: 10,
    elevation: 4,
    borderRadius: 8,
    backgroundColor: '#e0f7fa',
  },
  gamesContainer: {
    marginLeft: 10,
  },
  noGamesText: {
    color: '#777',
    textAlign: 'center',
    marginVertical: 10,
  },
  gameContainer: {
    marginBottom: 15,
    marginLeft: 10,
  },
  gameCard: {
    marginBottom: 5,
    elevation: 3,
    borderRadius: 8,
    backgroundColor: '#f1f8e9',
  },
  subGamesContainer: {
    marginLeft: 10,
  },
  subGamesList: {
    justifyContent: 'flex-start',
  },
  subGameWrapper: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 5,
  },
  subGameCard: {
    elevation: 2,
    borderRadius: 8,
    backgroundColor: '#fff3e0',
  },
  subGameButton: {
    marginTop: 5,
  },
  idText: {
    marginTop: 5,
    fontSize: 12,
    color: '#555',
  },
  // Nested folder styles
  nestedFolderContainer: {
    marginVertical: 10,
    marginLeft: 10,
  },
  nestedFolderCard: {
    marginBottom: 10,
    elevation: 2,
    borderRadius: 8,
    backgroundColor: '#ffe0b2',
  },
  // Modal styles
  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    margin: 20,
    borderRadius: 8,
  },
  modalTitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  optionItem: {
    paddingVertical: 8,
    borderBottomWidth: 0.5,
    borderColor: '#ccc',
  },
  closeButton: {
    marginTop: 10,
  },
});
