// make dates - orginal 


import React, { useState, useCallback, useEffect } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Pressable, Text, View } from "react-native";
import { TextInput, Provider as PaperProvider, DefaultTheme } from "react-native-paper";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import { collection, addDoc, Timestamp, getDocs, doc, updateDoc } from "firebase/firestore";
import { database } from "../componenets/firebase"; // Make sure this path is correct
import moment from 'moment';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

// Custom theme from Sandbox.js
const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#007bff', // Bootstrap's primary blue
    accent: '#0056b3', // A darker shade of blue for accents
  },
  icons: {
    back: <MaterialCommunityIcons name="arrow-left" size={24} color="#007bff" />, // Ensure the icon color matches the theme
  },
};


const MakeDates = ({ navigation, route }) => {
  const { editing, gameData } = route.params;

  // Initialize state with the existing data if editing, or defaults if adding a new date
  const [date, setDate] = useState(editing && gameData?.date ? new Date(gameData.date) : new Date());
  const [time, setTime] = useState(editing ? gameData?.time || { hours: 0, minutes: 0 } : { hours: 0, minutes: 0 });
  const [location, setLocation] = useState(editing ? gameData?.location || '' : '');
  const [gameTitle, setGameTitle] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [currentMainGameId, setCurrentMainGameId] = useState('');
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {
    // Attempt to extract the MainGameId from the route parameters for both adding and editing scenarios
    const mainGameIdFromAdding = route.params?.mainGameId;
    const mainGameIdFromEditing = route.params?.gameData?.currentMainGameId;
  
    // Determine the correct MainGameId to use
    const determinedMainGameId = mainGameIdFromEditing || mainGameIdFromAdding;
  
    if (determinedMainGameId) {
      setCurrentMainGameId(determinedMainGameId.toString()); // Ensure it's set as a string to match Firestore document IDs
    } else {
      console.warn('No MainGameId provided in route params.');
      // Here, you might want to handle the case where no valid ID is found, 
      // e.g., redirecting the user back or displaying an error.
    }
  }, [route.params]);
  

  const onDismissDatePicker = useCallback(() => setOpenDatePicker(false), []);
  // Update the onConfirmDate function to set the new date
  const onConfirmDate = useCallback((params) => {
    setOpenDatePicker(false); // Close the date picker modal
    setDate(new Date(params.date)); // Set the new date
  }, []); 
   const onDismissTimePicker = useCallback(() => setTimePickerVisible(false), []);
 // const onConfirmTime = useCallback(({ hours, minutes }) => {/* Time selection logic */}, []);

 const onConfirmTime = useCallback(({ hours, minutes }) => {
  setTimePickerVisible(false);
  setTime({ hours, minutes });
}, []);



  const SaveDate = async () => {

  //  console.log(`Saving Date, Editing Mode: ${editing}, Game Data ID: ${gameData?.id}`);
    

    
    if (!currentMainGameId) {
      console.error('Invalid MainGameId. Cannot save the date.');
      return;
    }
  
    const collectionRef = collection(database, "MainGame", currentMainGameId, "TheGame");
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const formattedTime = `${time.hours}:${time.minutes < 10 ? '0' : ''}${time.minutes}`;
  
    if (editing && gameData?.id) {
      // In edit mode and have an ID, so update the existing document
      const docRef = doc(database, "MainGame", currentMainGameId, "TheGame", gameData.id);
    //  console.log(`Updating existing date with ID: ${gameData.id}`);

      await updateDoc(docRef, {
        gameTitle,
        location,
        date: formattedDate,
        time: formattedTime,
        //gamedate: formattedDate,

        // Note: Depending on your requirements, you might not want to update the index or created timestamp here
      });


      // Code to update players' subcollection starts here
      const playersCollectionRef = collection(database, "MainGame", currentMainGameId, "TheGame", gameData.id, "Players");
      const querySnapshot = await getDocs(playersCollectionRef);
      const updatePromises = querySnapshot.docs.map((playerDoc) => {
        return updateDoc(playerDoc.ref, {
          date: formattedDate, // Assuming you want to update the 'date' field in each player document
          gamedate: formattedDate,

        });
      });
  
      try {
        await Promise.all(updatePromises);
      //  console.log("All player dates updated successfully.");
      } catch (error) {
      //  console.error("Error updating player dates:", error);
      }
      // Code to update players' subcollection ends here
  
    } else {
     // console.log("Adding new date");

      // Not in edit mode, add a new document
      const querySnapshot = await getDocs(collectionRef);
      let maxIndex = 0;
      let isSameDay = false;
  
      querySnapshot.forEach((doc) => {
        const gameData = doc.data();
        if (moment(gameData.date).isSame(moment(date), "day")) {
          isSameDay = true;
          maxIndex = Math.max(maxIndex, gameData.index);
        }
      });
  
      const newGameIndex = isSameDay ? maxIndex + 1 : 1;
  
      await addDoc(collectionRef, {
        gameTitle,
        location,
        date: formattedDate,
        //gamedate: formattedDate,
        time: formattedTime,
        created: Timestamp.now(),
        index: newGameIndex,
      });
    }
  
    navigation.goBack();
  };
  

  return (
    <PaperProvider theme={customTheme}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={styles.container}>

        {editing && <Text style={styles.editModeText}>Edit options</Text>}



        {/* <TextInput
  label="Location"
  value={location}
  onChangeText={setLocation}
  mode="outlined"
  theme={{ colors: { primary: customTheme.colors.primary, underlineColor: 'transparent', } }}
  style={{ width: '90%', marginBottom: 20 }}
/> */}
          <Pressable style={styles.button} onPress={() => setOpenDatePicker(true)}>
            <Text style={styles.buttonText}>Pick Date</Text>
          </Pressable>
          <DatePickerModal
            mode="single"
            visible={openDatePicker}
            onDismiss={onDismissDatePicker}
            date={date}
            onConfirm={onConfirmDate}
          />

          <Pressable style={styles.button} onPress={() => setTimePickerVisible(true)}>
            <Text style={styles.buttonText}>Pick Time</Text>
          </Pressable>
          <TimePickerModal
          visible={timePickerVisible}
          onDismiss={onDismissTimePicker}
          onConfirm={onConfirmTime}
          hours={time.hours} // Ensure this is a number
          minutes={time.minutes} // Ensure this is a number
          label="Select time" // Optional: for accessibility
          />

          {date && (
            <Text style={styles.text}>
              Selected date: {moment(date).format('YYYY-MM-DD')}
            </Text>
          )}
          {time && (
            <Text style={styles.text}>
              Selected time: {`${time.hours}:${time.minutes < 10 ? '0' : ''}${time.minutes}`}
            </Text>
          )}
{/* 
          <TextInput
            style={styles.textInput}
            placeholder="Name this game (optional)"
            onChangeText={setGameTitle}
            value={gameTitle}
          /> */}

          <TextInput
            label="Game location"
            value={location}
            onChangeText={setLocation}
            mode="outlined"
            theme={{colors: {primary: 'blue', underlineColor: 'transparent'}}}     
            style={{ width: '90%', marginBottom: 20, height: 60, }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          <Pressable style={styles.button} onPress={SaveDate}>
            <Text style={styles.buttonText}>Save Game Date and Time</Text>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    </PaperProvider>
  );
};

export default MakeDates;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  textInput: {
    marginBottom: 20,
    width: '90%',
    padding: 10,
    borderColor: '#007bff', // Primary blue as border color
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: 'white', // Add this if you want a white background for the text input
  },
  button: {
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#007bff', // Use primary blue for buttons
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
  },
  text: {
    margin: 10,
    color: '#0056b3', // Darker blue for text for better readability

  },

  editModeText: {
    fontSize: 24,
    color: '#0056b3', // Feel free to change the color to suit your app's theme
    marginBottom: 20,
  },



});
