import React, { useState } from "react";
import { StyleSheet, Alert, View } from "react-native";
import {
  Portal,
  Dialog,
  Paragraph,
  RadioButton,
  TextInput,
  HelperText,
  Button,
  TouchableRipple,
} from "react-native-paper";
import {
  doc,
  setDoc,
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { database } from "../../componenets/firebase";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// Array of sports with associated icons
const sports = [
  { label: "Football (Soccer)", icon: "soccer" },
  { label: "Basketball", icon: "basketball" },
  { label: "Tennis", icon: "tennis-ball" },
  { label: "Volleyball", icon: "volleyball" },
  { label: "Handball", icon: "handball" },
  { label: "Table Tennis", icon: "table-tennis" },
  { label: "Dart", icon: "dart" },
  { label: "Baseball/Softball", icon: "baseball" },
  { label: "American Football", icon: "football-helmet" },
  { label: "Rugby Union", icon: "rugby" },
  { label: "Badminton", icon: "badminton" },
  { label: "Golf", icon: "golf" },
  { label: "Ice Hockey", icon: "hockey-puck" },
  { label: "Cycling", icon: "bike" },
  { label: "Swimming", icon: "swim" },
  { label: "Martial Arts", icon: "karate" },
  { label: "Other", icon: "dots-horizontal" },
];

// For Board Games, we take the top 10 most played from the full cardGames list
const boardGames = [
  { label: "Catan", icon: "castle" },
  { label: "Ticket to Ride", icon: "train" },
  { label: "Carcassonne", icon: "castle" },
  { label: "Dominion", icon: "cards" },
  { label: "Stone Age", icon: "hammer" },
  { label: "Lords of Waterdeep", icon: "city" },
  { label: "Lost Cities: The Board Game", icon: "compass" },
  { label: "King of Tokyo", icon: "crown" },
  { label: "Brass: Birmingham", icon: "factory" },
  { label: "Pandemic", icon: "virus" },
  { label: "Other", icon: "dots-horizontal" },
];

// New array for Card games (for the new Game Type "Card games")
const cardGamesType = [
  { label: "Poker (Texas Hold’em)", icon: "cards-heart" },
  { label: "Bridge", icon: "bridge" },
  { label: "Blackjack", icon: "cards-diamond" },
  { label: "Solitaire", icon: "cards-outline" },
  { label: "Rummy", icon: "cards-spade" },
  { label: "Uno", icon: "alpha-u-box" },
  { label: "Hearts", icon: "heart" },
  { label: "Spades", icon: "cards" },
  { label: "Other", icon: "dots-horizontal" },
];

// Array of esports with associated icons
const esports = [
  { label: "League of Legends", icon: "sword-cross" },
  { label: "Dota 2", icon: "sword" },
  { label: "Counter-Strike: Global Offensive", icon: "crosshairs" },
  { label: "Fortnite", icon: "rocket" },
  { label: "Overwatch", icon: "shield" },
  { label: "Call of Duty", icon: "bullseye" },
  { label: "Valorant", icon: "target" },
  { label: "Rainbow Six Siege", icon: "shield-star" },
  { label: "PUBG", icon: "pistol" },
  { label: "StarCraft II", icon: "star" },
  { label: "Command & Conquer", icon: "star" },
  { label: "Other", icon: "dots-horizontal" },
];


// Function to generate a doc ID with date prefix
function generateDocIdWithDatePrefix(type) {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");
  const datePrefix = `${yyyy}-${mm}-${dd}`;
  const randomNumber = Math.floor(Math.random() * 9000000 + 1000000);
  return `${datePrefix}-${type}-${randomNumber}`;
}

// Function to generate a unique Room ID
function generateRoomId() {
  let roomId = "";
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 8; i++) {
    roomId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return roomId;
}

// Check if a roomId already exists in Firestore
async function roomIdExists(roomId) {
  const roomCollectionRef = collection(database, "MainGame");
  const q = query(roomCollectionRef, where("roomId", "==", roomId));
  const snapshot = await getDocs(q);
  return !snapshot.empty;
}

// Fetch user data from Firestore
async function fetchUserData(uid) {
  const usersRef = collection(database, "users");
  const q = query(usersRef, where("userId", "==", uid));
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    return snapshot.docs[0].data();
  }
  return null;
}

export default function MyGameCreate({ visible, onDismiss, parentId = null, navigation }) {
  const [gameName, setGameName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedType, setSelectedType] = useState("Match");
  const [selectedVisibility, setSelectedVisibility] = useState("Private");
  const [selectedGameMode, setSelectedGameMode] = useState("team");
  // State for Game Type; default set to "Sports"
  const [selectedGameCategory, setSelectedGameCategory] = useState("Sports");
  // State for the sports selection with default "Football (Soccer)"
  const [selectedSportType, setSelectedSportType] = useState("Football (Soccer)");
  // State for the board game selection with default "Catan"
  const [selectedBoardGame, setSelectedBoardGame] = useState("Catan");
  // State for the esports selection with default "League of Legends"
  const [selectedEsports, setSelectedEsports] = useState("League of Legends");
  // New state for the Card games selection with default "Poker (Texas Hold’em)"
  const [selectedCardGameType, setSelectedCardGameType] = useState("Poker (Texas Hold’em)");
  const [nameError, setNameError] = useState(false);
  // Control dialogs: Match, Sports, Board Games, Esports, and Card Games pop-ups
  const [matchDialogVisible, setMatchDialogVisible] = useState(false);
  const [sportDialogVisible, setSportDialogVisible] = useState(false);
  const [boardGameDialogVisible, setBoardGameDialogVisible] = useState(false);
  const [esportsDialogVisible, setEsportsDialogVisible] = useState(false);
  const [cardGamesDialogVisible, setCardGamesDialogVisible] = useState(false);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  async function handleNextOrCreate() {
    if (!currentUser) {
      Alert.alert("Error", "No user is logged in.");
      onDismiss();
      return;
    }
    if (!gameName.trim()) {
      setNameError(true);
      return;
    }
    setNameError(false);

    if (selectedType === "Folder") {
      // Create game immediately if type is Folder
      handleCreateGame();
    } else {
      // For Match, show the Match dialog pop-up
      setMatchDialogVisible(true);
    }
  }

  async function handleCreateGame() {
    const docId = generateDocIdWithDatePrefix(selectedType);
    let generatedRoomId = null;
    if (selectedType === "Match") {
      do {
        generatedRoomId = generateRoomId();
      } while (await roomIdExists(generatedRoomId));
    }
    let userName = "";
    try {
      const userData = await fetchUserData(currentUser.uid);
      userName = userData && userData.name ? userData.name : currentUser.email.split("@")[0];
    } catch (error) {
      console.error("Error fetching user data:", error);
      userName = currentUser.email.split("@")[0];
    }
    const finalGameName = gameName.trim();

    // Save the selection into gameTitle based on the game category.
    let finalGameTitle = "";
    if (selectedGameCategory === "Sports") {
      finalGameTitle = selectedSportType;
    } else if (selectedGameCategory === "Board Games") {
      finalGameTitle = selectedBoardGame;
    } else if (selectedGameCategory === "Esports") {
      finalGameTitle = selectedEsports;
    } else if (selectedGameCategory === "Card games") {
      finalGameTitle = selectedCardGameType;
    }

    const gameData = {
      docId,
      userId: currentUser.uid,
      isPublic: selectedVisibility === "Public",
      type: selectedType,
      mode: selectedType === "Match" ? selectedGameMode : null,
      parentId,
      created: Timestamp.now(),
      name: finalGameName,
      description,
      scoringMethod: "points",
      view: selectedVisibility,
      members: selectedType === "Match" ? 1 : 0,
      imageUrl: `https://picsum.photos/seed/${docId}/200/200`,
      roomId: selectedType === "Match" ? generatedRoomId : null,
      winPoints: 1,
      losePoints: 0,
      drawPoints: 0,
      attendancePoints: 0,
      absentPoints: 0,
      TeenGoalPoints: 0,
      pointsEnabled: true,
      gameCategory: selectedGameCategory,
      gameTitle: finalGameTitle,
      memberOfGame: [
        {
          email: currentUser.email,
          name: userName,
          uid: currentUser.uid,
          isAdmin: true,
          isOwner: true,
          role: "verified",
        },
      ],
    };

    try {
      const gameDocRef = doc(collection(database, "MainGame"), docId);
      await setDoc(gameDocRef, gameData);
      console.log(`Game created with id: ${docId}`);
      
      if (selectedType === "Match") {
        // Check for Dart Tournament first
        if (
          selectedGameCategory === "Sports" &&
          selectedSportType === "Dart" &&
          selectedGameMode === "Tournament"
        ) {
          navigation.navigate("DartTournament", {
            MainGameId: docId,
            gameName: finalGameName,
            roomId: generatedRoomId,
          });
        } 
        // New condition: If it's a Card games tournament and the selected card game is "Spades"
        else if (
         // selectedGameCategory === "Card games" &&
         // selectedCardGameType === "Spades" &&
          selectedGameMode === "Tournament"
        ) {
          console.log("Card games tournament for Spades detected.");
          navigation.navigate("StartTournament", {
            MainGameId: docId,
            gameName: finalGameName,
            roomId: generatedRoomId,
          });
        } else {
          // Otherwise, go to the default Game Date screen
          navigation.navigate("Game Date", {
            MainGameId: docId,
            gameName: finalGameName,
            roomId: generatedRoomId,
            gameMode: selectedGameMode,
          });
        }
      } else {
        onDismiss();
      }
      
    } catch (err) {
      console.error("Error creating game:", err);
      Alert.alert("Error", "Failed to create game.");
    }
    

    // Reset state after creation
    setGameName("");
    setDescription("");
    setSelectedType("Folder");
    setSelectedVisibility("Private");
    setSelectedGameMode("team");
    setSelectedGameCategory("Sports");
    setSelectedSportType("Football (Soccer)");
    setSelectedBoardGame("Catan");
    setSelectedEsports("League of Legends");
    setSelectedCardGameType("Poker (Texas Hold’em)");
    setMatchDialogVisible(false);
    setSportDialogVisible(false);
    setBoardGameDialogVisible(false);
    setEsportsDialogVisible(false);
    setCardGamesDialogVisible(false);
    onDismiss();
  }

  return (
    <Portal>
      {/* Main Create Game Dialog */}
      {!matchDialogVisible &&
        !sportDialogVisible &&
        !boardGameDialogVisible &&
        !esportsDialogVisible &&
        !cardGamesDialogVisible && (
          <Dialog visible={visible} onDismiss={onDismiss}>
            <Dialog.Title>Create Game</Dialog.Title>
            <Dialog.Content>
              <TextInput
                label="Game Name"
                value={gameName}
                onChangeText={(text) => {
                  setGameName(text);
                  if (text.trim()) setNameError(false);
                }}
                error={nameError}
                style={styles.input}
              />
              <HelperText type="error" visible={nameError}>
                Game Name is required
              </HelperText>
              <TextInput
                label="Description (optional)"
                value={description}
                onChangeText={setDescription}
                style={styles.input}
              />
              <Paragraph style={styles.paragraph}>Choose Type:</Paragraph>
              <RadioButton.Group onValueChange={setSelectedType} value={selectedType}>
                <RadioButton.Item label="Folder" value="Folder" />
                <RadioButton.Item label="Match" value="Match" />
              </RadioButton.Group>
              <Paragraph style={styles.paragraph}>Choose Visibility:</Paragraph>
              <RadioButton.Group onValueChange={setSelectedVisibility} value={selectedVisibility}>
                <RadioButton.Item label="Private (only members)" value="Private" />
                <RadioButton.Item label="Public" value="Public" />
              </RadioButton.Group>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={onDismiss}>Cancel</Button>
              <Button mode="contained" onPress={handleNextOrCreate}>
                {selectedType === "Folder" ? "Create" : "Next"}
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}

      {/* Match Dialog */}
      {matchDialogVisible && (
        <Dialog visible onDismiss={() => setMatchDialogVisible(false)}>
          <Dialog.Title>Match</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Choose Mode:</Paragraph>
            <RadioButton.Group onValueChange={setSelectedGameMode} value={selectedGameMode}>
              <RadioButton.Item label="Team" value="team" />
              <RadioButton.Item label="Individual (PVP)" value="Individual" />
              <RadioButton.Item label="Tournament" value="Tournament" />
            </RadioButton.Group>
            <Paragraph style={styles.paragraph}>Choose Game Type:</Paragraph>
            <RadioButton.Group onValueChange={setSelectedGameCategory} value={selectedGameCategory}>
              <RadioButton.Item label="Sports" value="Sports" />
              <RadioButton.Item label="Board Games" value="Board Games" />
              <RadioButton.Item label="Card games" value="Card games" />
              <RadioButton.Item label="Esports" value="Esports" />
              <RadioButton.Item label="Quiz" value="Quiz" />
            </RadioButton.Group>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setMatchDialogVisible(false)}>Back</Button>
            {(selectedGameCategory === "Sports" ||
              selectedGameCategory === "Board Games" ||
              selectedGameCategory === "Esports" ||
              selectedGameCategory === "Card games") ? (
              <Button
                mode="contained"
                onPress={() => {
                  setMatchDialogVisible(false);
                  if (selectedGameCategory === "Sports") {
                    setSportDialogVisible(true);
                  } else if (selectedGameCategory === "Board Games") {
                    setBoardGameDialogVisible(true);
                  } else if (selectedGameCategory === "Esports") {
                    setEsportsDialogVisible(true);
                  } else if (selectedGameCategory === "Card games") {
                    setCardGamesDialogVisible(true);
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button mode="contained" onPress={handleCreateGame}>
                Create
              </Button>
            )}
          </Dialog.Actions>
        </Dialog>
      )}

      {/* Sports Dialog */}
      {sportDialogVisible && (
        <Dialog
          visible
          onDismiss={() => {
            setSportDialogVisible(false);
            setMatchDialogVisible(true);
          }}
        >
          <Dialog.Title>Sports</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Choose Sport:</Paragraph>
            {sports.map((sport) => (
              <TouchableRipple key={sport.label} onPress={() => setSelectedSportType(sport.label)}>
                <View style={styles.itemRow}>
                  <MaterialCommunityIcons name={sport.icon} size={24} style={{ marginRight: 8 }} />
                  <Paragraph style={{ flex: 1 }}>{sport.label}</Paragraph>
                  <RadioButton
                    value={sport.label}
                    status={selectedSportType === sport.label ? "checked" : "unchecked"}
                    onPress={() => setSelectedSportType(sport.label)}
                  />
                </View>
              </TouchableRipple>
            ))}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setSportDialogVisible(false);
                setMatchDialogVisible(true);
              }}
            >
              Back
            </Button>
            <Button mode="contained" onPress={() => {
                setSportDialogVisible(false);
                handleCreateGame();
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}

      {/* Board Games Dialog */}
      {boardGameDialogVisible && (
        <Dialog
          visible
          onDismiss={() => {
            setBoardGameDialogVisible(false);
            setMatchDialogVisible(true);
          }}
        >
          <Dialog.Title>Board Games</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Choose Board Game:</Paragraph>
            {boardGames.map((game) => (
              <TouchableRipple key={game.label} onPress={() => setSelectedBoardGame(game.label)}>
                <View style={styles.itemRow}>
                  <MaterialCommunityIcons name={game.icon} size={24} style={{ marginRight: 8 }} />
                  <Paragraph style={{ flex: 1 }}>{game.label}</Paragraph>
                  <RadioButton
                    value={game.label}
                    status={selectedBoardGame === game.label ? "checked" : "unchecked"}
                    onPress={() => setSelectedBoardGame(game.label)}
                  />
                </View>
              </TouchableRipple>
            ))}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setBoardGameDialogVisible(false);
                setMatchDialogVisible(true);
              }}
            >
              Back
            </Button>
            <Button mode="contained" onPress={() => {
                setBoardGameDialogVisible(false);
                handleCreateGame();
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}

      {/* Esports Dialog */}
      {esportsDialogVisible && (
        <Dialog
          visible
          onDismiss={() => {
            setEsportsDialogVisible(false);
            setMatchDialogVisible(true);
          }}
        >
          <Dialog.Title>Esports</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Choose Esports Game:</Paragraph>
            {esports.map((game) => (
              <TouchableRipple key={game.label} onPress={() => setSelectedEsports(game.label)}>
                <View style={styles.itemRow}>
                  <MaterialCommunityIcons name={game.icon} size={24} style={{ marginRight: 8 }} />
                  <Paragraph style={{ flex: 1 }}>{game.label}</Paragraph>
                  <RadioButton
                    value={game.label}
                    status={selectedEsports === game.label ? "checked" : "unchecked"}
                    onPress={() => setSelectedEsports(game.label)}
                  />
                </View>
              </TouchableRipple>
            ))}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setEsportsDialogVisible(false);
                setMatchDialogVisible(true);
              }}
            >
              Back
            </Button>
            <Button mode="contained" onPress={() => {
                setEsportsDialogVisible(false);
                handleCreateGame();
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}

      {/* Card Games Dialog */}
      {cardGamesDialogVisible && (
        <Dialog
          visible
          onDismiss={() => {
            setCardGamesDialogVisible(false);
            setMatchDialogVisible(true);
          }}
        >
          <Dialog.Title>Card Games</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Choose Card Game:</Paragraph>
            {cardGamesType.map((game) => (
              <TouchableRipple key={game.label} onPress={() => setSelectedCardGameType(game.label)}>
                <View style={styles.itemRow}>
                  <MaterialCommunityIcons name={game.icon} size={24} style={{ marginRight: 8 }} />
                  <Paragraph style={{ flex: 1 }}>{game.label}</Paragraph>
                  <RadioButton
                    value={game.label}
                    status={selectedCardGameType === game.label ? "checked" : "unchecked"}
                    onPress={() => setSelectedCardGameType(game.label)}
                  />
                </View>
              </TouchableRipple>
            ))}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setCardGamesDialogVisible(false);
                setMatchDialogVisible(true);
              }}
            >
              Back
            </Button>
            <Button mode="contained" onPress={() => {
                setCardGamesDialogVisible(false);
                handleCreateGame();
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Portal>
  );
}

const styles = StyleSheet.create({
  input: { marginBottom: 4 },
  paragraph: { marginTop: 8 },
  itemRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
  },
});
