// MyGameSettingsDialog.js
import React, { useState, useEffect } from "react";
import { StyleSheet, View, Switch, Image, Alert, Platform, ScrollView } from "react-native";
import {
  Portal,
  Dialog,
  Paragraph,
  TextInput,
  HelperText,
  Button,
  ActivityIndicator,
} from "react-native-paper";
import { doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import * as FileSystem from "expo-file-system";
import { database } from "../../componenets/firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function getImageDimensions(uri) {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => resolve({ width, height }),
      (error) => {
        console.error("Error getting image dimensions:", error);
        reject(error);
      }
    );
  });
}

async function cropImageTo1MB(uri) {
  try {
    if (!uri) return null;
    if (Platform.OS === "web") {
      console.log("Skipping image manipulation for web");
      return uri;
    }
    if (Platform.OS === "ios" && uri.startsWith("ph://")) {
      const fileName = uri.split("/").pop();
      const newPath = FileSystem.cacheDirectory + fileName;
      await FileSystem.copyAsync({ from: uri, to: newPath });
      uri = newPath;
    }
    let compressQuality = 0.9;
    let resizeFactor = 0.9;
    let manipulatedImage = uri;

    while (true) {
      const { width, height } = await getImageDimensions(manipulatedImage);
      const resized = await ImageManipulator.manipulateAsync(
        manipulatedImage,
        [{ resize: { width: width * resizeFactor, height: height * resizeFactor } }],
        { compress: compressQuality, format: ImageManipulator.SaveFormat.JPEG }
      );
      manipulatedImage = resized.uri;
      const fileInfo = await FileSystem.getInfoAsync(manipulatedImage);
      const fileSizeInMB = fileInfo.size / (1024 * 1024);
      if (fileSizeInMB <= 1 || compressQuality < 0.3) break;
      compressQuality -= 0.1;
      resizeFactor -= 0.1;
    }
    return manipulatedImage;
  } catch (error) {
    console.error("Error manipulating image:", error);
    return null;
  }
}

export default function MyGameSettingsDialog({ visible, onDismiss, gameItem, onSaved }) {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [tempName, setTempName] = useState("");
  const [tempIsPublic, setTempIsPublic] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [imageUri, setImageUri] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (gameItem) {
      setTempName(gameItem.name || "");
      setTempIsPublic(!!gameItem.isPublic);
      setImageUri(gameItem.imageUrl || null);
      setNameError(false);
      console.log("Loaded game item:", gameItem);
    }
  }, [gameItem]);

  const pickImage = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!permissionResult.granted) {
      Alert.alert("Permission required", "Permission to access media library is required.");
      return;
    }
    const mediaType =
      ImagePicker.MediaType && ImagePicker.MediaType.Images
        ? ImagePicker.MediaType.Images
        : ImagePicker.MediaTypeOptions.Images;

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: mediaType,
      quality: 0.8,
      allowsEditing: true,
    });

    if (!result.canceled && result.assets && result.assets.length > 0) {
      const manipulatedUri = await cropImageTo1MB(result.assets[0].uri);
      if (manipulatedUri) {
        setImageUri(manipulatedUri);
      }
    }
  };

  const uploadToFirebase = async (uri, docId) => {
    try {
      setUploading(true);
      setUploadProgress(0);

      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = getStorage();
      const storageRef = ref(storage, `games/${docId}/cardImage.jpg`);

      const uploadTask = uploadBytesResumable(storageRef, blob);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload failed:", error);
            Alert.alert("Upload Error", "Failed to upload image. Please try again.");
            setUploadProgress(0);
            setUploading(false);
            reject(error);
          },
          async () => {
            try {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              Alert.alert("Upload Success", "Image uploaded successfully!");
              setUploadProgress(0);
              setUploading(false);
              resolve(downloadUrl);
            } catch (error) {
              console.error("Error getting download URL:", error);
              Alert.alert("Upload Error", "Failed to retrieve image URL.");
              setUploading(false);
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      Alert.alert("Upload Error", "An error occurred while uploading the image.");
      setUploading(false);
      throw error;
    }
  };

  async function handleSave() {
    if (!currentUser) {
      onDismiss();
      return;
    }
    if (!gameItem?.id) {
      onDismiss();
      return;
    }
    if (!tempName.trim()) {
      setNameError(true);
      return;
    }
    try {
      let imageUrl = gameItem.imageUrl || null;
      if (imageUri && imageUri !== gameItem.imageUrl) {
        // Upload new image if changed
        imageUrl = await uploadToFirebase(imageUri, gameItem.id);
      }
      const docRef = doc(database, "MainGame", gameItem.id);
      console.log("Saving game settings:", { tempName, tempIsPublic, imageUrl });
      await updateDoc(docRef, {
        name: tempName,
        isPublic: tempIsPublic,
        imageUrl,
      });
      console.log("Updated doc:", gameItem.id, "with isPublic:", tempIsPublic);
      onSaved && onSaved();
      onDismiss();
    } catch (err) {
      console.error("Error updating doc:", err);
    }
  }

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>Game Settings</Dialog.Title>
        <ScrollView>
          <Dialog.Content>
            <Paragraph style={styles.label}>Name:</Paragraph>
            <TextInput
              label="Game Name"
              value={tempName}
              onChangeText={(text) => {
                setTempName(text);
                if (text.trim()) setNameError(false);
              }}
              error={nameError}
              style={styles.input}
            />
            <HelperText type="error" visible={nameError}>
              Game Name is required
            </HelperText>

            <Paragraph style={styles.label}>Public?</Paragraph>
            <View style={styles.switchRow}>
              <Switch
                value={tempIsPublic}
                onValueChange={(val) => {
                  console.log("Switch toggled. New value:", val);
                  setTempIsPublic(val);
                }}
              />
              <Paragraph style={styles.switchLabel}>
                {tempIsPublic ? "Yes" : "No"}
              </Paragraph>
            </View>

            <Paragraph style={styles.label}>Game Image:</Paragraph>
            {imageUri ? (
              <Image source={{ uri: imageUri }} style={styles.imagePreview} />
            ) : (
              <Paragraph>No image selected.</Paragraph>
            )}
            <Button mode="outlined" onPress={pickImage} style={{ marginTop: 8 }}>
              Change Image
            </Button>

            {uploading && (
              <View style={styles.uploadContainer}>
                <ActivityIndicator size="small" />
                <Paragraph style={styles.uploadText}>
                  Uploading... {Math.round(uploadProgress)}%
                </Paragraph>
              </View>
            )}
          </Dialog.Content>
        </ScrollView>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Cancel</Button>
          <Button mode="contained" onPress={handleSave}>
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

const styles = StyleSheet.create({
  dialog: {
    maxHeight: "80%",
    width: "80%",
    alignSelf: "center",
  },
  label: {
    marginTop: 12,
  },
  input: {
    marginTop: 4,
    marginBottom: 12,
  },
  switchRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  switchLabel: {
    marginLeft: 8,
  },
  imagePreview: {
    width: 200,
    height: 200,
    marginVertical: 8,
    alignSelf: "center",
  },
  uploadContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  uploadText: {
    marginLeft: 8,
  },
});
