// MyGamesTopBar.js
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { SegmentedButtons, Button } from 'react-native-paper';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Initialize the auth instance
const auth = getAuth();

export default function MyGamesTopBar({
  filter,
  setFilter,
  onCreateGame,
  onStructure,
}) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Set up the listener for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === 'dondanni@hotmail.com') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, []);

  // Define the base buttons for all users
  const buttons = [
    { value: 'all', label: 'All', style: { minWidth: 60 } },
    { value: 'private', label: 'Private', style: { minWidth: 80 } },
    { value: 'public', label: 'Public', style: { minWidth: 80 } },
  ];

  // Conditionally add the Admin button if the user is authorized
  if (isAdmin) {
    buttons.push({ value: 'admin', label: 'Admin', style: { minWidth: 80 } });
  }

  return (
    <View style={styles.container}>
      <View style={styles.topButtonsContainer}>
        <Button mode="contained" onPress={onCreateGame} style={styles.topButton}>
          Create Game
        </Button>
        <Button mode="contained" onPress={onStructure} style={styles.topButton}>
          Structure Game
        </Button>
      </View>
      <SegmentedButtons
        value={filter}
        onValueChange={setFilter}
        style={styles.segmentedButtons}
        buttons={buttons}
      />
    </View>
  );
}

// 1) A helper that checks if a doc is accessible for non-admin users
function shouldIncludeItem(item, allItems, currentUser) {
  if (!currentUser) return false;
  // Always show if the user is the owner
  if (item.userId === currentUser.uid) return true;

  // If it's a match
  if (item.type !== 'Folder') {
    // If user is in memberOfGame
    if (
      Array.isArray(item.memberOfGame) &&
      item.memberOfGame.some((member) => member.uid === currentUser.uid)
    ) {
      return true;
    }
    // Or if it's public
    return item.isPublic === true;
  }

  // For folders: if public, show; if private, only show if it has an accessible child
  if (item.isPublic === true) return true;
  return hasAccessibleChild(item, allItems, currentUser);
}

// 2) Check if a folder has any child the user can see
function hasAccessibleChild(folder, allItems, currentUser) {
  const children = allItems.filter((child) => child.parentId === folder.id);
  return children.some((child) => shouldIncludeItem(child, allItems, currentUser));
}

// 3) A helper that enforces topBarFilter for non-admin
// New helper: determine if an item passes the top bar filter
function passesTopBarFilter(item, currentUser, topBarFilter) {
  // "all" => no further filtering
  if (topBarFilter === "all") {
    return true;
  }
  // "admin" => see everything
  if (topBarFilter === "admin") {
    return true;
  }

  // For "private", strictly show isPublic === false
  if (topBarFilter === "private") {
    return item.isPublic === false;
  }

  // For "public", strictly show isPublic === true
  if (topBarFilter === "public") {
    return item.isPublic === true;
  }

  // Fallback
  return false;
}


/**
 * 4) filterGames
 *
 * The main function that MyGames.js can call to produce the final filtered list.
 * This applies folder logic, admin logic, membership logic, topBarFilter, search, etc.
 *
 * @param {object[]} allGames - all docs from the MainGame collection
 * @param {string} folderId - optional folderId route param
 * @param {string} topBarFilter - "all", "private", "public", or "admin"
 * @param {string} searchText - user’s search input
 * @param {object} currentUser - the Firebase user
 * @returns {object[]} - the final filtered list of docs
 */
export function filterGames(allGames, folderId, topBarFilter, searchText, currentUser) {
  let items = [];

  // Admin sees all items, filtered only by folder
  if (topBarFilter === 'admin') {
    if (folderId !== null) {
      items = allGames.filter((item) => (item.parentId ?? null) === folderId);
    } else {
      items = allGames.filter((item) => !item.parentId);
    }
  } else {
    // For non-admin, first filter by folder
    if (folderId !== null) {
      items = allGames.filter((item) => (item.parentId ?? null) === folderId);
    } else {
      items = allGames.filter((item) => !item.parentId);
    }
    // Apply membership/ownership rules
    items = items.filter((item) => shouldIncludeItem(item, allGames, currentUser));
    // Then apply topBarFilter
    items = items.filter((item) => passesTopBarFilter(item, currentUser, topBarFilter));
  }

  // If user typed something in search
  if (searchText.trim().length > 0) {
    const lowerSearch = searchText.toLowerCase();
    items = items.filter((item) => item.name?.toLowerCase().includes(lowerSearch));
  }

  // Sort: folders first, then by creation date desc
  items.sort((a, b) => {
    if (a.type === 'Folder' && b.type !== 'Folder') return -1;
    if (a.type !== 'Folder' && b.type === 'Folder') return 1;
    const dateA = a.created?.toDate ? a.created.toDate() : new Date(0);
    const dateB = b.created?.toDate ? b.created.toDate() : new Date(0);
    return dateB - dateA;
  });

  return items;
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: '#fff',
  },
  topButtonsContainer: {
    flexDirection: 'row',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  topButton: {
    flex: 1,
    marginRight: 8,
  },
  segmentedButtons: {
    marginRight: 8,
  },
});
