// tournament/TournamentSetup.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getAuth } from 'firebase/auth';

export default function TournamentSetup() {
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params || {};
  const auth = getAuth();
  const currentUser = auth.currentUser;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Tournament Setup</Text>
      <Text style={styles.subtitle}>Choose a tournament format:</Text>

      {/* Display all parameters received */}
      <View style={styles.paramsContainer}>
        <Text style={styles.paramsTitle}>Received Parameters:</Text>
        {Object.keys(params).length > 0 ? (
          Object.keys(params).map((key) => (
            <Text key={key} style={styles.paramItem}>
              {key}: {String(params[key])}
            </Text>
          ))
        ) : (
          <Text style={styles.paramItem}>No parameters</Text>
        )}
      </View>

      {/* Option 1: Dart Tournament */}
      <View style={styles.optionContainer}>
        <Text style={styles.optionTitle}>Dart Tournament</Text>
        <Text style={styles.optionDescription}>
          A tournament format with a group stage followed by knockout rounds.
          Ideal for competitive darts.
        </Text>
        <Button
          mode="contained"
          onPress={() =>
            navigation.navigate("DartTournament", {
              MainGameId: params.MainGameId || "defaultId",
              gameName: params.gameName || "Default Game",
              roomId: params.roomId || "defaultRoom",
              gameCategory: params.gameCategory || "Unknown",
              gameTitle: params.gameTitle || "Unknown",
              mode: params.mode || "default",
              created: params.created || null,
              isPublic: params.isPublic || false,
              userId: params.userId || null,
              user: currentUser,
              ...params,
            })
          }
          style={styles.optionButton}
        >
          Select Dart Tournament
        </Button>
      </View>

      {/* Option 2: Football Tournament (Dummy) */}
      <View style={styles.optionContainer}>
        <Text style={styles.optionTitle}>Football Tournament</Text>
        <Text style={styles.optionDescription}>
          A placeholder format for Football tournaments. Customize your rules as needed.
        </Text>
        <Button
          mode="contained"
          onPress={() =>
            navigation.navigate("FootballTournament", {
              MainGameId: params.MainGameId || "defaultId",
              gameName: params.gameName || "Default Game",
              roomId: params.roomId || "defaultRoom",
              user: currentUser,
              ...params,
            })
          }
          style={styles.optionButton}
        >
          Select Football Tournament
        </Button>
      </View>

      {/* Option 3: Chess Tournament (Dummy) */}
      <View style={styles.optionContainer}>
        <Text style={styles.optionTitle}>Chess Tournament</Text>
        <Text style={styles.optionDescription}>
          A placeholder format for Chess tournaments using round-robin rules. Customize as needed.
        </Text>
        <Button
          mode="contained"
          onPress={() =>
            navigation.navigate("ChessTournament", {
              MainGameId: params.MainGameId || "defaultId",
              gameName: params.gameName || "Default Game",
              roomId: params.roomId || "defaultRoom",
              user: currentUser,
              ...params,
            })
          }
          style={styles.optionButton}
        >
          Select Chess Tournament
        </Button>
      </View>

      <Button mode="outlined" onPress={() => navigation.goBack()} style={styles.backButton}>
        Back
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#fff",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#004080",
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    marginBottom: 30,
  },
  paramsContainer: {
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    marginBottom: 30,
  },
  paramsTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#004080",
    marginBottom: 10,
  },
  paramItem: {
    fontSize: 14,
    color: "#333",
  },
  optionContainer: {
    marginBottom: 30,
    padding: 15,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 8,
  },
  optionTitle: {
    fontSize: 22,
    fontWeight: "600",
    color: "#004080",
    marginBottom: 10,
  },
  optionDescription: {
    fontSize: 16,
    color: "#555",
    marginBottom: 20,
  },
  optionButton: {
    alignSelf: "center",
    width: "80%",
  },
  backButton: {
    alignSelf: "center",
    width: "80%",
  },
});
