// DartTournament.js
import React, { useState, useEffect, useRef } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  TextInput as RNTextInput,
  TouchableOpacity,
  ScrollView,
  Platform,
  StyleSheet,
  Pressable
} from 'react-native';
import {
  Provider as PaperProvider,
  Dialog,
  Portal,
  Button,
  RadioButton,
  TextInput
} from 'react-native-paper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  setDoc
} from "firebase/firestore";
import { MaterialIcons } from "@expo/vector-icons";

// ---------------- HELPER IMPORTS ----------------
import {
  areTournamentResultsEqual,
  areKnockoutRoundsEqual,
  fixSubGamesIfMissing,
  generateAllPairs,
  computeFinalResult,
  computeGroupStats,
  resolveKnockoutName,
  randomizeMatches,
  generateKnockoutRounds,
  calculateKnockoutFromMatches,
  generateFirstKnockoutFromQualifiedWrapper,
  computeQualifiedPlayersListOdd,
} from './helper';

// ---------------- DND BACKEND ----------------
function createNoopBackend(manager) {
  return {
    setup: () => {},
    teardown: () => {},
    connectDragSource: () => () => {},
    connectDragPreview: () => () => {},
    connectDropTarget: () => () => {},
  };
}
const backendFactory = Platform.OS === 'web' ? HTML5Backend : createNoopBackend;
const ItemType = 'TEAM';

// ---------------- DRAGGABLE ITEM COMPONENT ----------------
const DraggableItem = ({ item, currentGroup, moveItem, openChangeGroup }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemType,
      item: { id: item.id, currentGroup, team: item },
      collect: (monitor) => ({ isDragging: monitor.isDragging() })
    }),
    [item, currentGroup]
  );

  const iconName = Platform.OS === "web" ? "more-horiz" : "more-vert";

  return (
    <View ref={drag} style={[styles.itemBox, isDragging && styles.dragging]}>
      <View style={styles.itemContent}>
        <Text style={styles.itemText}>{item.text}</Text>
        <TouchableOpacity onPress={() => openChangeGroup(item, currentGroup)}>
          <MaterialIcons name={iconName} size={24} color="#333" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

// ---------------- GROUP BOX COMPONENT ----------------
const Box = ({ groupName, items, moveItem, openChangeGroup }) => {
  const [, drop] = useDrop(
    () => ({
      accept: ItemType,
      drop: (draggedItem) => {
        moveItem(draggedItem.team, draggedItem.currentGroup, groupName);
      },
    }),
    [groupName, moveItem]
  );

  return (
    <View ref={drop} style={styles.box}>
      <Text style={styles.boxTitle}>{groupName}</Text>
      {items.length === 0 ? (
        <Text style={styles.emptyText}>Drop team here</Text>
      ) : (
        items.map((item) => (
          <DraggableItem
            key={`${groupName}-${item.id}`}
            item={item}
            currentGroup={groupName}
            moveItem={moveItem}
            openChangeGroup={openChangeGroup}
          />
        ))
      )}
    </View>
  );
};

// ---------------- MAIN TOURNAMENT COMPONENT ----------------
function DartTournament() {
  const navigation = useNavigation();
  const route = useRoute();
  const docId = route.params?.MainGameId;

  // Firebase references
  const auth = getAuth();
  const userUid = auth.currentUser?.uid || "no-uid-found";
  const db = getFirestore();
  const docRef = doc(db, "MainGame", docId);

  // Local state
  const [tournamentData, setTournamentData] = useState({
    players: [],
    numberOfGroups: 4,
    groupAssignments: {},
    finalized: false,
    allMatches: [],
    knockoutRounds: [],
    qualifiedPlayersList: []
  });
  const [userName, setUserName] = useState("");
  const [playerList, setPlayerList] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState("");
  const [playerDialogVisible, setPlayerDialogVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [changeGroupDialogVisible, setChangeGroupDialogVisible] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedPlayerCurrentGroup, setSelectedPlayerCurrentGroup] = useState("");
  const isRemoteUpdateRef = useRef(false);
  const previousDataRef = useRef({});
  const previousGamePlanDataRef = useRef(null);
  const previousKnockoutDataRef = useRef(null);
  const scrollViewRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // ---------------- FIRESTORE LISTENERS ----------------
  useEffect(() => {
    async function fetchUserName() {
      if (userUid === "no-uid-found") return;
      try {
        const docRefUser = doc(db, "users", userUid);
        const snap = await getDoc(docRefUser);
        if (snap.exists()) {
          const data = snap.data();
          if (data && data.name) {
            setUserName(data.name);
          }
        }
      } catch (err) {
        console.error("Error fetching user name:", err);
      }
    }
    fetchUserName();
  }, [db, userUid]);

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      if (snap.exists() && snap.data().Results) {
        const results = snap.data().Results;
        const newTournamentData = {
          players: results.players || [],
          numberOfGroups: results.numberOfGroups ?? 4,
          groupAssignments: results.groupAssignments || {},
          finalized: results.finalized ?? false,
          allMatches: results.allMatches ? fixSubGamesIfMissing(results.allMatches) : [],
          knockoutRounds: snap.data().knockout || [],
          qualifiedPlayersList: results.qualifiedPlayersList || []
        };

        // Compare new data vs old to avoid overwriting local changes
        if (
          !areTournamentResultsEqual(
            { Results: newTournamentData },
            { Results: previousDataRef.current }
          )
        ) {
          isRemoteUpdateRef.current = true;
          setTournamentData(newTournamentData);
          previousDataRef.current = newTournamentData;
        }
      }
      setIsInitialized(true);
    });
    return () => unsubscribe();
  }, [docRef]);

  useEffect(() => {
    if (tournamentData.finalized && scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  }, [tournamentData.finalized]);

  // ---------------- FIRESTORE SAVE HELPERS ----------------
  function saveGamePlanToFirestore(resultsData) {
    setDoc(docRef, { Results: resultsData }, { merge: true }).catch((err) =>
      console.error("Error saving game plan to Firestore:", err)
    );
  }
  function saveKnockoutToFirestore(knockoutData) {
    setDoc(docRef, { knockout: knockoutData }, { merge: true }).catch((err) =>
      console.error("Error saving knockout stage to Firestore:", err)
    );
  }

  // ---------------- SYNC GROUP DATA ----------------
  useEffect(() => {
    if (!isInitialized) return;
    if (isRemoteUpdateRef.current) {
      isRemoteUpdateRef.current = false;
      return;
    }
    const resultsData = {
      players: tournamentData.players,
      numberOfGroups: tournamentData.numberOfGroups,
      groupAssignments: tournamentData.groupAssignments,
      finalized: tournamentData.finalized,
      allMatches: tournamentData.allMatches,
      qualifiedPlayersList: tournamentData.qualifiedPlayersList
    };

    if (
      previousGamePlanDataRef.current &&
      areTournamentResultsEqual({ Results: resultsData }, { Results: previousGamePlanDataRef.current })
    ) {
      return;
    }
    previousGamePlanDataRef.current = resultsData;

    const timeoutId = setTimeout(() => {
      saveGamePlanToFirestore(resultsData);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [
    tournamentData.players,
    tournamentData.numberOfGroups,
    tournamentData.groupAssignments,
    tournamentData.finalized,
    tournamentData.allMatches,
    tournamentData.qualifiedPlayersList,
    isInitialized
  ]);

  // ---------------- SYNC KNOCKOUT DATA ----------------
  useEffect(() => {
    if (!isInitialized || !tournamentData.knockoutRounds) return;
    if (
      previousKnockoutDataRef.current &&
      areKnockoutRoundsEqual(tournamentData.knockoutRounds, previousKnockoutDataRef.current)
    ) {
      return;
    }
    previousKnockoutDataRef.current = tournamentData.knockoutRounds;
    const timeoutId = setTimeout(() => {
      saveKnockoutToFirestore(tournamentData.knockoutRounds);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [tournamentData.knockoutRounds, isInitialized]);

  // ---------------- CLEAR / GO BACK ----------------
  const handleClearTournament = () => {
    setTournamentData({
      ...tournamentData,
      groupAssignments: {},
      numberOfGroups: 4,
      allMatches: [],
      finalized: false,
      knockoutRounds: [],
      qualifiedPlayersList: []
    });
  };
  const handleGoBack = () => navigation.goBack();
  function handleClearKnockout() {
    setTournamentData(prev => ({ ...prev, knockoutRounds: [] }));
    setDoc(docRef, { knockout: [] }, { merge: true }).catch((err) =>
      console.error("Error clearing knockout in Firestore:", err)
    );
  }

  // ---------------- RANDOMIZE RESULTS (GROUP STAGE ONLY) ----------------
  function handleRandomizeAllResults() {
    // Randomize group matches
    const updatedGroupMatches = randomizeMatches(tournamentData.allMatches);

    if (tournamentData.numberOfGroups % 2 === 0) {
      // Even groups
      const sortedGroupNames = Object.keys(tournamentData.groupAssignments).sort();
      const newQualifiedPlayersList = [];
      sortedGroupNames.forEach(groupName => {
        const matchesInGroup = updatedGroupMatches.filter(m => m.groupName === groupName);
        const standings = computeGroupStats(matchesInGroup);
        if (standings[0]) newQualifiedPlayersList.push(standings[0].name);
        if (standings[1]) newQualifiedPlayersList.push(standings[1].name);
      });
      newQualifiedPlayersList.sort((a, b) => a.localeCompare(b));
      const newKnockoutRounds = generateFirstKnockoutFromQualifiedWrapper(
        newQualifiedPlayersList,
        tournamentData.numberOfGroups
      );

      setTournamentData(prev => ({
        ...prev,
        allMatches: updatedGroupMatches,
        qualifiedPlayersList: newQualifiedPlayersList,
        knockoutRounds: newKnockoutRounds
      }));
      saveGamePlanToFirestore({
        ...tournamentData,
        allMatches: updatedGroupMatches,
        qualifiedPlayersList: newQualifiedPlayersList,
      });
      saveKnockoutToFirestore(newKnockoutRounds);
    } else {
      // Odd groups
      const newQualifiedPlayersList = computeQualifiedPlayersListOdd(
        updatedGroupMatches,
        tournamentData.groupAssignments
      );
      newQualifiedPlayersList.sort((a, b) => a.localeCompare(b));
      const newKnockoutRounds = generateFirstKnockoutFromQualifiedWrapper(
        newQualifiedPlayersList,
        tournamentData.numberOfGroups
      );
      setTournamentData(prev => ({
        ...prev,
        allMatches: updatedGroupMatches,
        qualifiedPlayersList: newQualifiedPlayersList,
        knockoutRounds: newKnockoutRounds
      }));
      saveGamePlanToFirestore({
        ...tournamentData,
        allMatches: updatedGroupMatches,
        qualifiedPlayersList: newQualifiedPlayersList,
      });
      saveKnockoutToFirestore(newKnockoutRounds);
    }
  }

  // ---------------- PLAYER MANAGEMENT ----------------
  const openPlayerDialog = () => {
    setPlayerList(tournamentData.players);
    setPlayerDialogVisible(true);
  };
  const handleAddPlayer = () => {
    if (newPlayerName.trim() !== "") {
      setPlayerList([...playerList, newPlayerName.trim()]);
      setNewPlayerName("");
    }
  };
  const handleDeletePlayer = (index) => {
    const updatedList = [...playerList];
    updatedList.splice(index, 1);
    setPlayerList(updatedList);
    if (editIndex === index) {
      setEditIndex(null);
      setEditValue("");
    }
  };
  const handleStartEdit = (index) => {
    setEditIndex(index);
    setEditValue(playerList[index]);
  };
  const handleSaveEdit = (index) => {
    const updatedList = [...playerList];
    updatedList[index] = editValue;
    setPlayerList(updatedList);
    setEditIndex(null);
    setEditValue("");
  };
  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditValue("");
  };
  const handleConfirmPlayers = () => {
    setTournamentData({ ...tournamentData, players: playerList });
    setPlayerDialogVisible(false);
  };

  // ---------------- GROUP ASSIGNMENT ----------------
  const handleGenerateGroups = () => {
    if (tournamentData.finalized) return;
    const teams = tournamentData.players.map((name, i) => ({
      id: `${i + 1}`,
      text: name,
    }));
    const numGroups = Math.min(tournamentData.numberOfGroups, teams.length);
    const newAssignments = {};
    for (let i = 0; i < numGroups; i++) {
      newAssignments[`Group ${i + 1}`] = [];
    }
    teams.forEach((team, index) => {
      const groupIndex = index % numGroups;
      newAssignments[`Group ${groupIndex + 1}`].push(team);
    });
    setTournamentData({ ...tournamentData, groupAssignments: newAssignments });
  };
  const openChangeGroupDialog = (player, currentGroup) => {
    setSelectedPlayer(player);
    setSelectedPlayerCurrentGroup(currentGroup);
    setChangeGroupDialogVisible(true);
  };
  const moveTeam = (team, fromGroup, toGroup) => {
    if (!fromGroup || !toGroup || fromGroup === toGroup) return;
    const updated = { ...tournamentData.groupAssignments };
    updated[fromGroup] = updated[fromGroup].filter(t => t.id !== team.id);
    updated[toGroup] = [...(updated[toGroup] || []), team];
    setTournamentData({ ...tournamentData, groupAssignments: updated });
  };

  // ---------------- FINALIZE TOURNAMENT ----------------
  function handleFinalizeTournament() {
    if (tournamentData.finalized) return;

    let matchId = 1;
    const sortedGroupNames = Object.keys(tournamentData.groupAssignments).sort();
    const groupMatches = [];

    // Build round-robin matches
    sortedGroupNames.forEach((groupName) => {
      const teams = tournamentData.groupAssignments[groupName].map(t => t.text);
      const pairs = generateAllPairs(teams);
      pairs.forEach(pair => {
        groupMatches.push({
          id: matchId++,
          groupName,
          pairing: `${pair[0]} - ${pair[1]}`,
          player1: pair[0],
          player2: pair[1],
          result: '',
          time: '',
          field: '',
          subGames: [
            { winner: '', enterName: '' },
            { winner: '', enterName: '' },
            { winner: '', enterName: '' }
          ],
          isKnockout: false
        });
      });
    });

    // Determine qualified players
    let qualifiedPlayersList = [];
    if (tournamentData.numberOfGroups % 2 === 0) {
      // Even: take top 2 from each group
      sortedGroupNames.forEach(groupName => {
        const matchesInGroup = groupMatches.filter(m => m.groupName === groupName);
        const standings = computeGroupStats(matchesInGroup);
        if (standings[0]) qualifiedPlayersList.push(standings[0].name);
        if (standings[1]) qualifiedPlayersList.push(standings[1].name);
      });
    } else {
      // Odd: custom logic to reach 8
      qualifiedPlayersList = computeQualifiedPlayersListOdd(groupMatches, tournamentData.groupAssignments);
    }
    qualifiedPlayersList.sort((a, b) => a.localeCompare(b));

    // Build knockout bracket
    const newKnockoutRounds = generateFirstKnockoutFromQualifiedWrapper(
      qualifiedPlayersList,
      tournamentData.numberOfGroups
    );

    const updatedTournament = {
      ...tournamentData,
      allMatches: groupMatches,
      knockoutRounds: newKnockoutRounds,
      finalized: true,
      qualifiedPlayersList
    };

    setTournamentData(updatedTournament);
    setDoc(docRef, {
      Results: {
        players: updatedTournament.players,
        numberOfGroups: updatedTournament.numberOfGroups,
        groupAssignments: updatedTournament.groupAssignments,
        finalized: true,
        allMatches: updatedTournament.allMatches,
        qualifiedPlayersList
      },
      knockout: newKnockoutRounds
    }, { merge: true });
  }

  // ---------------- ENTERING MATCH RESULTS ----------------
  const handleRowPress = (match) => {
    const safeMatch = { ...match };
    if (!safeMatch.subGames || safeMatch.subGames.length < 3) {
      safeMatch.subGames = [
        { winner: "", enterName: "" },
        { winner: "", enterName: "" },
        { winner: "", enterName: "" }
      ];
    }
    setSelectedMatch(safeMatch);
    setDialogVisible(true);
  };
  const handleDialogDismiss = () => {
    setDialogVisible(false);
    setSelectedMatch(null);
  };
  function handleConfirmDialog() {
    if (!selectedMatch) return;
    const updatedMatch = { ...selectedMatch };
    updatedMatch.subGames = updatedMatch.subGames.map(subGame =>
      subGame.winner && !subGame.enteredBy ? { ...subGame, enteredBy: userName } : subGame
    );
    updatedMatch.result = computeFinalResult(updatedMatch.subGames);
    if (updatedMatch.result) {
      const [p1Score, p2Score] = updatedMatch.result.split("-").map(Number);
      updatedMatch.winnerName = p1Score > p2Score ? updatedMatch.player1 : updatedMatch.player2;
    } else {
      updatedMatch.winnerName = "";
    }

    if (!updatedMatch.isKnockout) {
      // Group match
      const updatedMatches = tournamentData.allMatches.map(m =>
        m.id === updatedMatch.id ? updatedMatch : m
      );
      const { knockoutRounds, qualifiedPlayersList } = calculateKnockoutFromMatches(
        updatedMatches,
        tournamentData.groupAssignments,
        tournamentData.numberOfGroups
      );
      setTournamentData({
        ...tournamentData,
        allMatches: updatedMatches,
        knockoutRounds,
        qualifiedPlayersList,
      });
      saveGamePlanToFirestore({
        players: tournamentData.players,
        numberOfGroups: tournamentData.numberOfGroups,
        groupAssignments: tournamentData.groupAssignments,
        finalized: tournamentData.finalized,
        allMatches: updatedMatches,
        qualifiedPlayersList,
      });
      saveKnockoutToFirestore(knockoutRounds);
    } else {
      // Knockout match
      const updatedRounds = tournamentData.knockoutRounds.map(round => ({
        ...round,
        matches: round.matches.map(m => (m.id === updatedMatch.id ? updatedMatch : m))
      }));
      setTournamentData({ ...tournamentData, knockoutRounds: updatedRounds });
      saveKnockoutToFirestore(updatedRounds);
    }
    setDialogVisible(false);
    setSelectedMatch(null);
  }
  const handleSubGameWinner = (index, winner) => {
    if (!selectedMatch) return;
    const newSubGames = [...selectedMatch.subGames];
    const currentWinner = newSubGames[index].winner;
    const finalWinner = currentWinner === winner ? "" : winner;

    // For labeling who actually won (display name)
    const groupMatchesLocal = {};
    tournamentData.allMatches.forEach(m => {
      if (!groupMatchesLocal[m.groupName]) groupMatchesLocal[m.groupName] = [];
      groupMatchesLocal[m.groupName].push(m);
    });
    const displayP1 = resolveKnockoutName(selectedMatch.player1, groupMatchesLocal, tournamentData.knockoutRounds);
    const displayP2 = resolveKnockoutName(selectedMatch.player2, groupMatchesLocal, tournamentData.knockoutRounds);

    let actualName = "";
    if (finalWinner === "p1") actualName = displayP1;
    if (finalWinner === "p2") actualName = displayP2;

    newSubGames[index] = {
      ...newSubGames[index],
      winner: finalWinner,
      enterName: finalWinner ? actualName : "",
      enteredBy: finalWinner ? userName : ""
    };
    setSelectedMatch({ ...selectedMatch, subGames: newSubGames });
  };
  const renderGameRow = (index) => {
    const game = selectedMatch.subGames[index];
    const groupMatchesLocal = {};
    tournamentData.allMatches.forEach(m => {
      if (!groupMatchesLocal[m.groupName]) groupMatchesLocal[m.groupName] = [];
      groupMatchesLocal[m.groupName].push(m);
    });
    const displayP1 = resolveKnockoutName(selectedMatch.player1, groupMatchesLocal, tournamentData.knockoutRounds);
    const displayP2 = resolveKnockoutName(selectedMatch.player2, groupMatchesLocal, tournamentData.knockoutRounds);

    return (
      <View key={index} style={styles.gameRow}>
        <Text style={styles.gameLabel}>Game {index + 1}:</Text>
        <View style={styles.radioRow}>
          <RadioButton
            status={game.winner === "p1" ? "checked" : "unchecked"}
            onPress={() => handleSubGameWinner(index, "p1")}
          />
          <Text onPress={() => handleSubGameWinner(index, "p1")}>{displayP1}</Text>
        </View>
        <View style={styles.radioRow}>
          <RadioButton
            status={game.winner === "p2" ? "checked" : "unchecked"}
            onPress={() => handleSubGameWinner(index, "p2")}
          />
          <Text onPress={() => handleSubGameWinner(index, "p2")}>{displayP2}</Text>
        </View>
      </View>
    );
  };

  // ---------------- RENDER GROUPS & STANDINGS ----------------
  const groupMatchesDisplay = {};
  tournamentData.allMatches.forEach(match => {
    if (!groupMatchesDisplay[match.groupName]) {
      groupMatchesDisplay[match.groupName] = [];
    }
    groupMatchesDisplay[match.groupName].push(match);
  });
  const qualifiedTop8 = tournamentData.qualifiedPlayersList || [];
  const qualifiedPlayers = Object.keys(groupMatchesDisplay)
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    .map(groupName => {
      const standings = computeGroupStats(groupMatchesDisplay[groupName]);
      const winner = standings[0]
        ? `${standings[0].name} (W=${standings[0].w},L=${standings[0].l})`
        : `Winner of ${groupName}`;
      let runnerUp = "";
      let runnerIsQualified = false;
      if (standings[1]) {
        runnerIsQualified = tournamentData.numberOfGroups % 2 === 0
          ? true // for even groups, all runner-ups are in
          : qualifiedTop8.includes(standings[1].name);
        runnerUp = `${standings[1].name} (W=${standings[1].w},L=${standings[1].l})`;
      }
      return {
        group: groupName,
        winner,
        runnerUp,
        runnerUpDidQualify: runnerIsQualified
      };
    });

  // ---------------- RENDER KNOCKOUT BRACKET (IMPORTANT PART) ----------------
  function renderKnockoutBracket() {
    if (!tournamentData.finalized) return null;
    const safeKnockoutRounds = (tournamentData.knockoutRounds || []).map(round => ({
      ...round,
      matches: fixSubGamesIfMissing(round.matches),
    }));
    if (safeKnockoutRounds.length === 0) {
      return (
        <View style={{ marginTop: 30 }}>
          <Text style={styles.header}>Knockout Stage</Text>
          <Text>No knockout rounds generated.</Text>
        </View>
      );
    }

    return (
      <View style={{ marginTop: 30 }}>
        <Text style={styles.header}>Knockout Stage</Text>
        <View style={styles.bracketContainer}>
          {safeKnockoutRounds.map((round, roundIndex) => (
            <View style={styles.roundColumn} key={roundIndex}>
              <Text style={styles.roundTitle}>{round.roundName || "Round"}</Text>
              {round.matches.map(match => {
                // Resolve each player
                const groupMatchesLocal = {};
                tournamentData.allMatches.forEach(m => {
                  if (!groupMatchesLocal[m.groupName]) {
                    groupMatchesLocal[m.groupName] = [];
                  }
                  groupMatchesLocal[m.groupName].push(m);
                });

                const resolvedP1 = resolveKnockoutName(
                  match.player1,
                  groupMatchesLocal,
                  safeKnockoutRounds
                ) || "TBD";
                const resolvedP2 = resolveKnockoutName(
                  match.player2,
                  groupMatchesLocal,
                  safeKnockoutRounds
                ) || "TBD";
                const result = match.result || "TBD";

                // If this match is decided, let's resolve the actual winner name
                // so it doesn't show "Winner of QF-1", etc.
                const resolvedWinner = match.winnerName
                  ? resolveKnockoutName(match.winnerName, groupMatchesLocal, safeKnockoutRounds)
                  : "TBD";

                return (
                  <Pressable
                    key={match.id}
                    style={styles.bracketMatch}
                    onPress={() => handleRowPress(match)}
                  >
                    <Text style={styles.bracketMatchText}>
                      {resolvedP1} vs {resolvedP2}
                    </Text>
                    <Text style={styles.bracketResultText}>Results: {result}</Text>
                    <Text style={styles.bracketWinnerText}>Winner: {resolvedWinner}</Text>
                  </Pressable>
                );
              })}
            </View>
          ))}
        </View>
      </View>
    );
  }

  // ---------------- RENDER ----------------
  return (
    <DndProvider backend={backendFactory}>
      <ScrollView ref={scrollViewRef} style={styles.container}>
        {/* Top Buttons */}
        <View style={styles.topButtonsContainer}>
          <Button mode="outlined" onPress={handleGoBack}>Go Back</Button>
          <Button mode="outlined" onPress={handleClearTournament}>Clear Tournament</Button>
          <Button mode="outlined" onPress={handleClearKnockout}>Clear Knockout</Button>
        </View>

        <Text style={styles.header}>Dart Tournament Setup</Text>

        <TouchableOpacity style={styles.button} onPress={openPlayerDialog}>
          <Text style={styles.buttonText}>Manage Player Names</Text>
        </TouchableOpacity>

        <View style={styles.inputGroup}>
          <Text style={styles.label}>Number of Groups</Text>
          <TextInput
            style={styles.input}
            keyboardType="number-pad"
            value={tournamentData.numberOfGroups.toString()}
            onChangeText={text => setTournamentData({
              ...tournamentData,
              numberOfGroups: Number(text)
            })}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={handleGenerateGroups}>
          <Text style={styles.buttonText}>Generate Groups</Text>
        </TouchableOpacity>

        <Text style={styles.sectionHeader}>Groups</Text>
        <View style={styles.groupsContainer}>
          {Object.entries(tournamentData.groupAssignments)
            .sort(([a], [b]) => a.localeCompare(b, undefined, { numeric: true }))
            .map(([groupName, teams]) => (
              <View key={groupName} style={styles.groupWrapper}>
                <Box
                  groupName={groupName}
                  items={teams}
                  moveItem={moveTeam}
                  openChangeGroup={openChangeGroupDialog}
                />
              </View>
            ))}
        </View>

        <TouchableOpacity style={styles.button} onPress={handleFinalizeTournament}>
          <Text style={styles.buttonText}>Finalize Tournament Info</Text>
        </TouchableOpacity>

        {/* GROUP STAGE DISPLAY */}
        {tournamentData.finalized && (
          <View style={{ marginTop: 30 }}>
          {Object.keys(groupMatchesDisplay)
  .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
  .map((gName) => {
    const matches = groupMatchesDisplay[gName];
    const groupStats = computeGroupStats(matches);

    return (
      <View key={gName} style={styles.groupSection}>
  <Text style={styles.groupTitle}>{gName}</Text>
  {Platform.OS === 'web' ? (
    // For Web: side-by-side layout using a row container
    <View style={styles.groupContent}>
      {/* Game Plan Column */}
      <View style={styles.gamePlanContainer}>
        <Text style={styles.subHeaderLeft}>Game Plan</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          contentContainerStyle={{ flexGrow: 1, minWidth: 600 }}
        >
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={[styles.tableCell, styles.headerCell, styles.tinyCol]}>
                No.
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.timeFieldCol]}>
                Time
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.timeFieldCol]}>
                Field
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.matchCol]}>
                Match Pairing
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.resultCol]}>
                Result
              </Text>
            </View>
            {matches.map(match => (
              <Pressable
                key={match.id}
                style={styles.tableRow}
                onPress={() => handleRowPress(match)}
              >
                <Text style={[styles.tableCell, styles.tinyCol]}>
                  {match.id}
                </Text>
                <RNTextInput
                  style={[styles.tableCell, styles.timeFieldCol, styles.inputCell]}
                  placeholder="9:00"
                  value={match.time}
                  onChangeText={value => {
                    const updated = tournamentData.allMatches.map(m =>
                      m.id === match.id ? { ...m, time: value } : m
                    );
                    setTournamentData({ ...tournamentData, allMatches: updated });
                  }}
                />
                <RNTextInput
                  style={[styles.tableCell, styles.timeFieldCol, styles.inputCell]}
                  placeholder="1"
                  value={match.field}
                  onChangeText={value => {
                    const updated = tournamentData.allMatches.map(m =>
                      m.id === match.id ? { ...m, field: value } : m
                    );
                    setTournamentData({ ...tournamentData, allMatches: updated });
                  }}
                />
                <Text style={[styles.tableCell, styles.matchCol]}>
                  {match.pairing}
                </Text>
                <Text style={[styles.tableCell, styles.resultCol]}>
                  {match.result || "0-0"}
                </Text>
              </Pressable>
            ))}
          </View>
        </ScrollView>
      </View>

      {/* Standing Column */}
      <View style={styles.standingContainer}>
        <Text style={styles.subHeaderLeft}>Standing</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          contentContainerStyle={{ flexGrow: 1, minWidth: 600 }}
        >
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={[styles.tableCell, styles.headerCell, styles.nameCol]}>
                Player
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                Pld
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                W
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                L
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                %Ave
              </Text>
            </View>
            {groupStats.map((playerStat, idx) => (
              <View key={idx} style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.nameCol]}>
                  {playerStat.name}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.pld}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.w}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.l}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.d}
                </Text>
              </View>
            ))}
          </View>
        </ScrollView>
      </View>
    </View>
  ) : (
    // For iOS: vertical layout (Standing below Game Plan)
    <>
      <View style={styles.gamePlanContainer}>
        <Text style={styles.subHeaderLeft}>Game Plan</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          contentContainerStyle={{ flexGrow: 1, minWidth: 600 }}
        >
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={[styles.tableCell, styles.headerCell, styles.tinyCol]}>
                No.
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.timeFieldCol]}>
                Time
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.timeFieldCol]}>
                Field
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.matchCol]}>
                Match Pairing
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.resultCol]}>
                Result
              </Text>
            </View>
            {matches.map(match => (
              <Pressable
                key={match.id}
                style={styles.tableRow}
                onPress={() => handleRowPress(match)}
              >
                <Text style={[styles.tableCell, styles.tinyCol]}>
                  {match.id}
                </Text>
                <RNTextInput
                  style={[styles.tableCell, styles.timeFieldCol, styles.inputCell]}
                  placeholder="9:00"
                  value={match.time}
                  onChangeText={value => {
                    const updated = tournamentData.allMatches.map(m =>
                      m.id === match.id ? { ...m, time: value } : m
                    );
                    setTournamentData({ ...tournamentData, allMatches: updated });
                  }}
                />
                <RNTextInput
                  style={[styles.tableCell, styles.timeFieldCol, styles.inputCell]}
                  placeholder="1"
                  value={match.field}
                  onChangeText={value => {
                    const updated = tournamentData.allMatches.map(m =>
                      m.id === match.id ? { ...m, field: value } : m
                    );
                    setTournamentData({ ...tournamentData, allMatches: updated });
                  }}
                />
                <Text style={[styles.tableCell, styles.matchCol]}>
                  {match.pairing}
                </Text>
                <Text style={[styles.tableCell, styles.resultCol]}>
                  {match.result || "0-0"}
                </Text>
              </Pressable>
            ))}
          </View>
        </ScrollView>
      </View>
      <View style={styles.standingContainer}>
        <Text style={styles.subHeaderLeft}>Standing</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          contentContainerStyle={{ flexGrow: 1, minWidth: 600 }}
        >
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={[styles.tableCell, styles.headerCell, styles.nameCol]}>
                Player
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                Pld
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                W
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                L
              </Text>
              <Text style={[styles.tableCell, styles.headerCell, styles.smallCol]}>
                %Ave
              </Text>
            </View>
            {groupStats.map((playerStat, idx) => (
              <View key={idx} style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.nameCol]}>
                  {playerStat.name}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.pld}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.w}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.l}
                </Text>
                <Text style={[styles.tableCell, styles.smallCol]}>
                  {playerStat.d}
                </Text>
              </View>
            ))}
          </View>
        </ScrollView>
      </View>
    </>
  )}
</View>

    );
  })}

          </View>
        )}

        {/* RANDOMIZE RESULTS BUTTON (AFTER FINALIZED) */}
        {tournamentData.finalized && (
          <View style={{ flexDirection: "row", justifyContent: "center", marginBottom: 10 }}>
            <Button mode="outlined" onPress={handleRandomizeAllResults}>
              Randomize Results
            </Button>
          </View>
        )}

        {/* QUALIFIED PLAYERS */}
        {tournamentData.finalized && (
          <View style={styles.qualifiedContainer}>
            <Text style={styles.header}>Qualified Players</Text>
            <View style={[styles.qualifiedCard, styles.qualifiedHeaderRow]}>
              <Text style={[styles.qualifiedGroup, styles.headerCell]}>Group</Text>
              <Text style={[styles.qualifiedText, styles.headerCell]}>Winner</Text>
              <Text style={[styles.qualifiedText, styles.headerCell]}>Runner-up</Text>
              <Text style={[styles.qualifiedText, styles.headerCell]}>Qualified?</Text>
            </View>
            {qualifiedPlayers.map(({ group, winner, runnerUp, runnerUpDidQualify }) => (
              <View key={group} style={styles.qualifiedCard}>
                <Text style={styles.qualifiedGroup}>{group}</Text>
                <Text style={styles.qualifiedText}>{winner}</Text>
                <Text style={styles.qualifiedText}>{runnerUp}</Text>
                <Text style={styles.qualifiedText}>
                  {runnerUp ? (runnerUpDidQualify ? "Yes" : "No") : ""}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Knockout Bracket */}
        {renderKnockoutBracket()}

        {/* Dialogs */}
        <Portal>
          <Dialog visible={dialogVisible} onDismiss={handleDialogDismiss}>
            <Dialog.Title>Enter Results</Dialog.Title>
            <Dialog.Content>
              {selectedMatch && (
                <View>
                  <Text style={styles.dialogText}>
                    {`Match #${selectedMatch.id}: ${selectedMatch.player1} vs ${selectedMatch.player2}`}
                  </Text>
                  <Text style={[styles.dialogText, { fontWeight: "bold" }]}>
                    Pick winner for the games:
                  </Text>
                  {renderGameRow(0)}
                  {renderGameRow(1)}
                  {/*
                    Only show the 3rd game if the first two subgames differ
                    (i.e., each side has won 1 game).
                  */}
                  {selectedMatch.subGames[0].winner &&
                   selectedMatch.subGames[1].winner &&
                   selectedMatch.subGames[0].winner !== selectedMatch.subGames[1].winner &&
                   renderGameRow(2)}
                </View>
              )}
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={handleDialogDismiss}>Cancel</Button>
              <Button mode="contained" onPress={handleConfirmDialog}>
                Confirm
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <Portal>
          <Dialog
            visible={playerDialogVisible}
            onDismiss={() => setPlayerDialogVisible(false)}
            style={{ minHeight: 400, maxHeight: 600 }}
          >
            <Dialog.Title>Manage Players</Dialog.Title>
            <Dialog.Content>
              <TextInput
                label="Player Name"
                mode="outlined"
                value={newPlayerName}
                onChangeText={setNewPlayerName}
                style={{ marginBottom: 10 }}
              />
              <Button
                mode="contained"
                onPress={handleAddPlayer}
                style={{ marginBottom: 20 }}
              >
                Add Player
              </Button>
              <Text>Total Players: {playerList.length}</Text>
              <ScrollView style={{ maxHeight: 300, marginTop: 10 }}>
                {playerList.length > 0 ? (
                  playerList.map((player, index) => (
                    <View key={index} style={styles.playerRow}>
                      <Text style={styles.playerNumber}>{index + 1}.</Text>
                      {editIndex === index ? (
                        <>
                          <TextInput
                            mode="outlined"
                            value={editValue}
                            onChangeText={setEditValue}
                            style={{ flex: 1, marginRight: 10 }}
                          />
                          <Button
                            onPress={() => handleSaveEdit(index)}
                            mode="contained"
                            style={{ marginRight: 5 }}
                          >
                            Save
                          </Button>
                          <Button
                            onPress={handleCancelEdit}
                            mode="text"
                            color="#666"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Text style={[styles.playerText, { flex: 1 }]}>
                            {player}
                          </Text>
                          <Button
                            onPress={() => handleStartEdit(index)}
                            mode="text"
                            color="#1976d2"
                            style={{ marginRight: 5 }}
                          >
                            Edit
                          </Button>
                          <Button
                            onPress={() => handleDeletePlayer(index)}
                            mode="text"
                            color="#d32f2f"
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </View>
                  ))
                ) : (
                  <Text>No players added yet.</Text>
                )}
              </ScrollView>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setPlayerDialogVisible(false)}>Cancel</Button>
              <Button mode="contained" onPress={handleConfirmPlayers}>Confirm</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <Portal>
          <Dialog
            visible={changeGroupDialogVisible}
            onDismiss={() => setChangeGroupDialogVisible(false)}
          >
            <Dialog.Title>Change Group</Dialog.Title>
            <Dialog.Content>
              {Object.keys(tournamentData.groupAssignments)
                .filter(g => g !== selectedPlayerCurrentGroup)
                .map(group => (
                  <TouchableOpacity
                    key={group}
                    onPress={() => {
                      moveTeam(selectedPlayer, selectedPlayerCurrentGroup, group);
                      setChangeGroupDialogVisible(false);
                    }}
                    style={styles.changeGroupOption}
                  >
                    <Text style={styles.changeGroupOptionText}>{group}</Text>
                  </TouchableOpacity>
                ))}
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setChangeGroupDialogVisible(false)}>Cancel</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

      </ScrollView>
    </DndProvider>
  );
}

// ---------------- MAIN APP EXPORT ----------------
export default function App() {
  return (
    <PaperProvider>
      <SafeAreaView style={{ flex: 1, backgroundColor: "#f5f7fb" }}>
        <DartTournament />
      </SafeAreaView>
    </PaperProvider>
  );
}

// ---------------- STYLES ----------------
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#f5f7fb",
  },
  topButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  header: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#004080",
    marginBottom: 20,
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: 15,
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    color: "#004080",
    marginBottom: 5,
  },
  input: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ccc",
    fontSize: 16,
    color: "#333",
  },
  button: {
    backgroundColor: "#004080",
    padding: 15,
    borderRadius: 8,
    alignItems: "center",
    marginVertical: 10,
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "600",
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#003366",
    marginVertical: 15,
    textAlign: "center",
  },
  groupsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  groupWrapper: {
    margin: 10,
    alignItems: "center",
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 10,
    width: 150,
    marginRight: 10,
    marginBottom: 10,
    elevation: 3,
  },
  boxTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#004080",
    marginBottom: 10,
    textAlign: "center",
  },
  emptyText: {
    fontStyle: "italic",
    color: "#888",
  },
  groupSection: {
    marginBottom: 30,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 10,
    elevation: 2,
  },
  groupTitle: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#004080",
    textAlign: "center",
    marginBottom: 10,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#003366",  
    marginBottom: 5,
    textAlign: 'left',
    marginLeft: 150,
  


  },
  table: {
    backgroundColor: "#fff",
    marginVertical: 5,
    elevation: 2,
    borderRadius: 8,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  tableHeaderRow: {
    backgroundColor: "#0066cc",
    borderRadius: 8,
  },
  tableCell: {
    paddingVertical: 10,
    paddingHorizontal: 8,
    textAlign: "center",
  },
  headerCell: {
    color: "#fff",
    fontWeight: "bold",
  },
  tinyCol: {
    width: 40,
  },
  timeFieldCol: {
    width: 60,
  },
  matchCol: {
    width: 200,
  },
  resultCol: {
    width: 70,
  },
  inputCell: {
    textAlign: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: 4,
    paddingHorizontal: 6,
    paddingVertical: 4,
  },

  smallCol: {
    width: 60,
  },
  nameCol: {
    width: 120,
  },
  gameRow: {
    marginVertical: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
  },
  gameLabel: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  radioRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  dialogText: {
    marginBottom: 10,
    fontSize: 16,
  },
  qualifiedContainer: {
    marginTop: 30,
    padding: 15,
    backgroundColor: "#fff",
    borderRadius: 8,
    elevation: 2,
    marginBottom: 20,
  },
  qualifiedCard: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  qualifiedHeaderRow: {
    backgroundColor: '#0066cc',
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 8,
    marginHorizontal: -15,
  },
  qualifiedGroup: {
    width: 80,
    fontWeight: "bold",
    color: "#004080",
    textAlign: "left",
  },
  qualifiedText: {
    flex: 1,
    textAlign: "center",
    color: "#333",
  },
  bracketContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  roundColumn: {
    width: 160,
    alignItems: 'center',
    marginHorizontal: 10,
  },
  roundTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#004080',
    textAlign: 'center',
  },
  bracketMatch: {
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
    padding: 10,
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',
  },
  bracketMatchText: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 4,
    color: '#333',
    textAlign: 'center',
  },
  bracketResultText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 2,
  },
  bracketWinnerText: {
    fontSize: 14,
    color: '#009900',
    fontWeight: 'bold',
  },
  itemBox: {
    backgroundColor: "#e6e6e6",
    borderRadius: 5,
    padding: 8,
    marginVertical: 5,
  },
  itemContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemText: {
    fontSize: 16,
    color: "#333",
  },
  dragging: {
    opacity: 0.5,
  },
  changeGroupOption: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  changeGroupOptionText: {
    fontSize: 16,
    color: "#004080",
  },
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    borderBottomWidth: 2,
    borderBottomColor: "#ccc",
  },
  playerNumber: {
    width: 30,
    fontSize: 16,
    color: "#333",
    marginRight: 5,
  },
  playerText: {
    fontSize: 16,
    color: "#333",
  },

  groupContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: 40,  // Extra space below each group row
  },
  gamePlanContainer: {
    marginRight: 50,   // Spacing between Game Plan and Standing (web only)
  },
  standingContainer: {
    // You can set a width or leave it flexible based on your design
  },
  // A new style for left-aligned subheaders:
  subHeaderLeft: {
    fontSize: 18,
    fontWeight: '600',
    color: '#003366',
    textAlign: 'left',
    marginLeft: 150,
    marginBottom: 5,
  },


});



