// navigation updated for demo mode.

import Profilex from "../screens/Profilex";
import { UserProvider } from "./UserContext";
import { MaterialIcons } from "@expo/vector-icons";

import { View, Text, TouchableOpacity, SafeAreaView, Platform } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { NavigationContainer, DrawerActions, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Avatar } from "react-native-elements";
import {
  doc,
  setDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import { database, auth } from "../componenets/firebase";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import CustomNavigationDrawer from "../componenets/CustomNavigationDrawer";
import Ionicons from "react-native-vector-icons/Ionicons";
import { AppContext } from "../screens/AppContext";

// Screens imports:
import MyGames from "../screens/MyGames";
import TheGame from "../screens/TheGame";
import Players from "../screens/Players";
import Results from "../screens/Results";
import MakeGame from "../screens/MakeGame";
import MakeDates from "../screens/MakeDates";
import ScoreCard from "../screens/ScoreCard";
import LeaderBoard from "../screens/LeaderBoard";
import AddPlayerScreen from "../screens/AddPlayerScreen";
import GameSettings from "../screens/GameSettings";
import About from "../screens/About";
import GameDashboard from "../screens/GameDashboard";
import BeerCountLeaderboard from "../screens/BeerCountLeaderboard";
import LeagueTable from "../screens/LeagueTable";
import BeerCounter from "../screens/BeerCounter";
import Tournament from "../screens/Tournament";
import Pvp from "../screens/Pvp";
import PvpLeaderBoard from "../screens/PvpLeaderBoard";
import PvpLeaderScore from "../screens/PvpLeaderScore";
import KnockOut from "../screens/KnockOut";
import KnockOutResults from "../screens/KnockOutResults";
import Chat from "../screens/Chat";
import Bug from "../screens/Bug";
import Users from "../screens/UserList";
import GameList from "../screens/GameList";
import AccountSettings from "../screens/AccountSettings";
import Reminder from "../screens/Reminder";
import Notification from "../screens/Notification";
import NotificationSettings from "../screens/NotificationSettings";
import MyGames2 from "../screens/MyGames2";
import MyGameStructure from "../screens/utils/MyGameStructure";
import MyMatch from "../screens/utils/myMatch";
import MembersScreen from "../screens/utils/MembersScreen";
import MyGameDate from "../screens/utils/MyGameDate";
import MyPlayers from "../screens/utils/MyPlayers";
import GameModeScreen from "../screens/utils/MyGameModeScreen";
import MyGameCreate from "../screens/utils/MyGameCreate"; // <-- import
import DartTournament from "../screens/subScreens/tournament/groupKnockout/DartTournament";
import TournamentSetup from "../screens/subScreens/tournament/TournamentSetup";
import StartTournament from "../screens/subScreens/tournament/StartTournament";
//import HelloScreen from "../screens/utils/HelloScreen";
//import GameModeScreen from "../screens/utils/GameModeScreen";


import { useUser } from "./UserContext";

// token stuff
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import { getAuth, onAuthStateChanged } from "firebase/auth";

/**
 * =========================
 * 1) Main Stack Navigation
 * =========================
 */
const Stack = createNativeStackNavigator();

const HeaderRight = ({ navigation }) => (
  <View style={{ marginLeft: 10 }}>
    <Avatar
      rounded
      size="medium"
      source={require("../assets/Profile2.png")}
      onPress={() => navigation.openDrawer()}
      activeOpacity={0.7}
    />
    <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
      Menu
    </Text>
  </View>
);

const StackNavigation = () => (
  <Stack.Navigator
    screenOptions={({ navigation }) => ({
      headerStyle: { backgroundColor: "#4361ee" },
      headerTintColor: "white",
      headerTitleStyle: { fontWeight: "bold" },
      headerTitleAlign: "center",
      headerBackTitleVisible: true,
      headerRight: () => <HeaderRight navigation={navigation} />,
    })}
  >
    <Stack.Screen
      name="My Games"
      component={MyGames}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: true,
      }}
    />
    <Stack.Screen
      name="Game Date"
      component={TheGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="MakeDates"
      component={MakeDates}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Make Game"
      component={MakeGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="BeerCounter"
      component={BeerCounter}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Tournament"
      component={Tournament}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Pvp"
      component={Pvp}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="PvpLeaderBoard"
      component={PvpLeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="PvpLeaderScore"
      component={PvpLeaderScore}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="KnockOut"
      component={KnockOut}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="KnockOutResults"
      component={KnockOutResults}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Chat"
      component={Chat}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="AddPlayerScreen"
      component={AddPlayerScreen}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="GameSettings"
      component={GameSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Players"
      component={Players}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Results"
      component={Results}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="LeaderBoard"
      component={LeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="GameDashboard"
      component={GameDashboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="BeerCountLeaderboard"
      component={BeerCountLeaderboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="LeagueTable"
      component={LeagueTable}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="ScoreCard"
      component={ScoreCard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="AccountSettings"
      component={AccountSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="NotificationSettings"
      component={NotificationSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Reminder"
      component={Reminder}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen name="MyGames" component={MyGames} />
    <Stack.Screen name="MyGameStructure" component={MyGameStructure} />
    <Stack.Screen name="GameModeScreen" component={GameModeScreen} />

    <Stack.Screen
      name="MyGameCreate"
      component={MyGameCreate}
      options={{
        headerShown: false, // We hide the header so your Dialog approach is full-screen
      }}
    />

    <Stack.Screen
      name="DartTournament"
      component={DartTournament}
      options={{
        headerShown: false, // We hide the header so your Dialog approach is full-screen
      }}
    />

     {/* New Tournament routes */}
     <Stack.Screen
          name="TournamentSetup"
          component={TournamentSetup}
          options={{
            headerShown: true,
            headerTitle: "Tournament Setup",
          }}
        />

        <Stack.Screen
          name="StartTournament"
          component={StartTournament}
          options={{
            headerShown: true,
            headerTitle: "Tournament Setup",
          }}
        />



  </Stack.Navigator>
);

/**
 * =========================
 * 2) A new stack for "SandBox" flows
 * =========================
 */

// New HeaderRightNew for Sandbox
const HeaderRightNew = ({ navigation }) => {
  const openDrawerHandler = () => {
    const parent = navigation.getParent();
    if (parent) {
      parent.dispatch(DrawerActions.openDrawer());
    }
  };

  return (
    <View style={{ marginLeft: 10 }}>
      <Avatar
        rounded
        size="medium"
        source={require("../assets/Profile2.png")}
        onPress={openDrawerHandler}
        activeOpacity={0.7}
      />
      <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
        Menu
      </Text>
    </View>
  );
};

const SandBoxStack = createNativeStackNavigator();

function SandBoxStackNavigator() {
  return (
    <SandBoxStack.Navigator
      screenOptions={({ navigation }) => ({
        headerStyle: { backgroundColor: "#4361ee" },
        headerTintColor: "white",
        headerTitleAlign: "center",
        headerRight: () => <HeaderRightNew navigation={navigation} />,
      })}
    >
      <SandBoxStack.Screen
        name="MyGames2"
        component={MyGames2}
        options={{ title: "MyGames1" }}
      />
      <SandBoxStack.Screen
        name="MyGameStructure"
        component={MyGameStructure}
        options={{ headerShown: true, title: "Structure Game" }}
      />
      <SandBoxStack.Screen
        name="MyMatch"
        component={MyMatch}
        options={{ headerShown: true, title: "Go back" }}
      />
      <SandBoxStack.Screen
        name="MembersScreen"
        component={MembersScreen}
      />
      <SandBoxStack.Screen
        name="MyGameDate"
        component={MyGameDate}
      />
      <Stack.Screen name="MyPlayers" component={MyPlayers} />




    </SandBoxStack.Navigator>
  );
}

// Wrap SandboxStackNavigator in its own Drawer so that OPEN_DRAWER is handled
const SandboxDrawer = createDrawerNavigator();

function SandboxDrawerNavigator() {
  return (
    <SandboxDrawer.Navigator
      screenOptions={{ headerShown: false }}
      drawerContent={(props) => <CustomNavigationDrawer {...props} />}
    >
      <SandboxDrawer.Screen
        name="MyGames"
        component={SandBoxStackNavigator}
        options={{
          drawerItemStyle: { backgroundColor: "4361ee" },
          drawerLabelStyle: { color: "white" },
        }}
      />
      <SandboxDrawer.Screen name="Profile" component={Profilex} />
      
      
    </SandboxDrawer.Navigator>
    
  );
}



/**
 * Some example screens for demonstration
 */
function AnotherScreen1() {
  const navigation = useNavigation();
  return (
    <View>
      <Text>Another Navigation! </Text>
      <TouchableOpacity onPress={() => navigation.navigate("Profile")}>
        <Text>Drawer - Go to Profile </Text>
      </TouchableOpacity>
    </View>
  );
}

function DrawerProfile() {
  const navigation = useNavigation();
  return (
    <SafeAreaView>
      <View>
        <Text>Profile screen ! </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Another")}>
          <Text>Drawer - Go to Another Screen </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

/**
 * ==========================
 * 3) Drawer with StackNavigation as a screen
 * ==========================
 */
const Drawer = createDrawerNavigator();

const DrawerNavigation = ({ userIdTop }) => (
  <Drawer.Navigator
    screenOptions={{ headerShown: false }}
    drawerContent={(navProps) => <CustomNavigationDrawer {...navProps} />}
  >
    <Drawer.Screen
      name="DrawerNavigation"
      options={{ drawerItemStyle: { display: "none" } }}
    >
      {(props) => <StackNavigation {...props} userIdTop={userIdTop} />}
    </Drawer.Screen>
    <Drawer.Screen name="Profile" component={Profilex} />
    {/* ... other Drawer.Screen components */}
  </Drawer.Navigator>
);

/**
 * ==========================
 * 4) Tab Navigator
 * ==========================
 */
const Tab = createBottomTabNavigator();

const TabNavigator = ({ userIdTop }) => {
  const { isDemo } = useContext(AppContext);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserEmail(user ? user.email : null);
    });
    return unsubscribe;
  }, []);

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          switch (route.name) {
            case "My Games":
              iconName = "fort";
              break;
            case "Profile":
              iconName = "person";
              break;
            case "About":
              iconName = "info";
              break;
            case "Bug":
              iconName = "bug-report";
              break;
            case "Users":
              iconName = "group";
              break;
            case "GameList":
              iconName = "games";
              break;
            case "Notifications":
              iconName = "info";
              break;
            case "SandBox":
              iconName = "extension";
              break;
            default:
              iconName = "circle";
              break;
          }
          return <MaterialIcons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: "dodgerblue",
        tabBarInactiveTintColor: "gray",
      })}
    >
      {/* The main "My Games" tab uses the DrawerNavigation */}
      <Tab.Screen name="My Games">
        {(props) => <DrawerNavigation {...props} userIdTop={userIdTop} />}
      </Tab.Screen>
      {!isDemo && <Tab.Screen name="Profile" component={Profilex} />}
      <Tab.Screen name="About" component={About} />
      {!isDemo && <Tab.Screen name="Bug" component={Bug} />}
      {/*
        5) Replace MyGames2 with the SandboxDrawerNavigator
           so we can navigate to MyGameStructure inside that stack.
      */}
      {!isDemo &&
        (userEmail === "dondanni@hotmail.com" ||
          userEmail === "dannipals79@gmail.com") && (
          <Tab.Screen name="SandBox" component={SandboxDrawerNavigator} />
        )}
      {!isDemo && userEmail === "dondanni@hotmail.com" && (
        <Tab.Screen name="Notification" component={Notification} />
      )}
      {!isDemo &&
        (userEmail === "dondanni@hotmail.com" ||
          userEmail === "dannipals79@gmail.com") && (
          <Tab.Screen name="Users" component={Users} />
        )}
      {!isDemo && userEmail === "dondanni@hotmail.com" && (
        <Tab.Screen name="GameList" component={GameList} />
      )}
    </Tab.Navigator>
  );
};

/**
 * ==========================
 * 6) Main export: The Navigation container
 * ==========================
 */
export default function Navigation({ navigationRef, routeNameRef, onStateChange }) {
  const { isDemo } = useContext(AppContext);
  const [userIdTop, setUserIdTop] = useState(null);

  const updateUserTokenAndLoginCount = async (currentUser) => {
    if (currentUser) {
      const userId = currentUser.uid;
      console.log("Current User ID:", userId);
      const userRef = doc(database, "users", userId);
      try {
        if (Platform.OS !== "web") {
          const token2 = (await Notifications.getExpoPushTokenAsync()).data;
          console.log("Expo Push Token:", token2);
          await setDoc(
            userRef,
            { expoPushToken: token2, lastLogin: serverTimestamp() },
            { merge: true }
          );
          console.log("Token and last login saved to Firestore.");
          await updateDoc(userRef, { loginCount: increment(1) });
          console.log("Login count incremented.");
        } else {
          console.log("Push notifications are skipped on web.");
        }
      } catch (error) {
        console.error("Error updating token and login count:", error);
      }
    } else {
      console.log("No user is signed in");
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userId = currentUser.uid;
      setUserIdTop(userId);
      console.log("Current User ID:", userId);
      updateUserTokenAndLoginCount(currentUser);
    } else {
      console.log("No user is signed in");
    }
  }, []);

  if (!userIdTop && !isDemo) {
    return null;
  }

  return (
    <UserProvider>
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        onStateChange={onStateChange}
      >
        <TabNavigator userIdTop={userIdTop} />
      </NavigationContainer>
    </UserProvider>
  );
}
