import React, { useState } from "react";
import { StyleSheet, Alert } from "react-native";
import {
  Portal,
  Dialog,
  Paragraph,
  RadioButton,
  Button,
} from "react-native-paper";
import {
  doc,
  setDoc,
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { database } from "../../componenets/firebase";

// Helpers (same as before)
function generateDocIdWithDatePrefix(type) {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");
  const datePrefix = `${yyyy}-${mm}-${dd}`;
  const randomNumber = Math.floor(Math.random() * 9000000 + 1000000);
  return `${datePrefix}-${type}-${randomNumber}`;
}

function generateRoomId() {
  let roomId = "";
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 8; i++) {
    roomId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return roomId;
}

async function roomIdExists(roomId) {
  const roomCollectionRef = collection(database, "MainGame");
  const q = query(roomCollectionRef, where("roomId", "==", roomId));
  const snapshot = await getDocs(q);
  return !snapshot.empty;
}

async function fetchUserData(uid) {
  const usersRef = collection(database, "users");
  const q = query(usersRef, where("userId", "==", uid));
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    return snapshot.docs[0].data();
  }
  return null;
}

export default function GameModeScreen({ route, navigation }) {
  // Received parameters from the first screen.
  const { gameName, description, selectedType, selectedVisibility, parentId } = route.params;
  const [selectedGameMode, setSelectedGameMode] = useState("team");

  const auth = getAuth();
  const currentUser = auth.currentUser;

  async function handleCreateMatchGame() {
    if (!currentUser) {
      Alert.alert("Error", "No user is logged in.");
      navigation.goBack();
      return;
    }
    const docId = generateDocIdWithDatePrefix(selectedType);

    // Generate a unique room ID for the match
    let generatedRoomId = null;
    do {
      generatedRoomId = generateRoomId();
    } while (await roomIdExists(generatedRoomId));

    let userName = "";
    try {
      const userData = await fetchUserData(currentUser.uid);
      userName =
        userData && userData.name
          ? userData.name
          : currentUser.email.split("@")[0];
    } catch (error) {
      console.error("Error fetching user data:", error);
      userName = currentUser.email.split("@")[0];
    }

    // Build gameData object for Match type
    const gameData = {
      docId,
      userId: currentUser.uid,
      isPublic: selectedVisibility === "Public",
      type: selectedType,
      mode: selectedGameMode,
      parentId,
      created: Timestamp.now(),
      name: gameName,
      description,
      scoringMethod: "points",
      view: selectedVisibility,
      members: 1,
      imageUrl: `https://picsum.photos/seed/${docId}/200/200`,
      roomId: generatedRoomId,
      winPoints: 1,
      losePoints: 0,
      drawPoints: 0,
      attendancePoints: 0,
      absentPoints: 0,
      TeenGoalPoints: 0,
      pointsEnabled: true,
      memberOfGame: [
        {
          email: currentUser.email,
          name: userName,
          uid: currentUser.uid,
          isAdmin: true,
          isOwner: true,
          role: "verified",
        },
      ],
    };

    try {
      const gameDocRef = doc(collection(database, "MainGame"), docId);
      await setDoc(gameDocRef, gameData);
      console.log(`Match game created with id: ${docId}`);
      // Navigate to the next screen (e.g., "Game Date") after creation
      navigation.navigate("Game Date", {
        MainGameId: docId,
        gameName,
        roomId: generatedRoomId,
        gameMode: selectedGameMode,
      });
    } catch (err) {
      console.error("Error creating game:", err);
      Alert.alert("Error", "Failed to create game.");
    }
  }

  // onCancel will dismiss the dialog (or you could discard all changes)
  function onCancel() {
    navigation.goBack();
  }

  return (
    <Portal>
      <Dialog visible onDismiss={onCancel}>
        <Dialog.Title>Select Game Mode</Dialog.Title>
        <Dialog.Content>
          <Paragraph style={styles.paragraph}>Choose Mode:</Paragraph>
          <RadioButton.Group onValueChange={setSelectedGameMode} value={selectedGameMode}>
            <RadioButton.Item label="Team" value="team" />
            <RadioButton.Item label="Individual" value="Individual" />
            <RadioButton.Item label="Tournament" value="Tournament" />
          </RadioButton.Group>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => navigation.goBack()}>Back</Button>
          <Button onPress={onCancel}>Cancel</Button>
          <Button mode="contained" onPress={handleCreateMatchGame}>
            Create
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

const styles = StyleSheet.create({
  paragraph: {
    marginTop: 8,
  },
});
