import React, { useState, useCallback, useEffect } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Pressable,
  Text,
  View,
  Alert,
} from "react-native";
import { TextInput, Provider as PaperProvider, DefaultTheme } from "react-native-paper";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { database } from "../../componenets/firebase";
import moment from "moment";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "#007bff",
    accent: "#0056b3",
  },
  icons: {
    back: <MaterialCommunityIcons name="arrow-left" size={24} color="#007bff" />,
  },
};

export default function MyGameDate({ navigation, route }) {
  // If editing => gameData is an existing doc
  const { editing, gameData, matchId } = route.params || {};

  const [date, setDate] = useState(
    editing && gameData?.date ? new Date(gameData.date) : new Date()
  );
  const [time, setTime] = useState(
    editing && gameData?.time
      ? gameData.time
      : { hours: 0, minutes: 0 }
  );
  const [location, setLocation] = useState(editing ? gameData?.location || "" : "");
  const [gameTitle, setGameTitle] = useState(editing ? gameData?.gameTitle || "" : "");

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [timePickerVisible, setTimePickerVisible] = useState(false);

  // Date picker callbacks
  const onDismissDatePicker = useCallback(() => setOpenDatePicker(false), []);
  const onConfirmDate = useCallback((params) => {
    setOpenDatePicker(false);
    setDate(new Date(params.date));
  }, []);

  // Time picker callbacks
  const onDismissTimePicker = useCallback(() => setTimePickerVisible(false), []);
  const onConfirmTime = useCallback(({ hours, minutes }) => {
    setTimePickerVisible(false);
    setTime({ hours, minutes });
  }, []);

  const SaveDate = async () => {
    if (!matchId) {
      Alert.alert("Error", "No matchId provided. Cannot save date/time.");
      return;
    }

    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const formattedTime = `${time.hours}:${time.minutes < 10 ? "0" : ""}${time.minutes}`;

      const datesRef = collection(database, "games", matchId, "dates");

      if (editing && gameData?.id) {
        // Update existing doc
        const docRef = doc(database, "games", matchId, "dates", gameData.id);
        await updateDoc(docRef, {
          gameTitle,
          location,
          date: formattedDate,
          time: formattedTime,
        });
        console.log("Updated existing date doc:", gameData.id);
      } else {
        // Add new doc => check for same-day docs => index logic
        const snapshot = await getDocs(datesRef);
        let maxIndex = 0;
        let isSameDay = false;

        snapshot.forEach((docSnap) => {
          const existing = docSnap.data();
          if (existing.date === formattedDate) {
            isSameDay = true;
            maxIndex = Math.max(maxIndex, existing.index || 0);
          }
        });

        const newIndex = isSameDay ? maxIndex + 1 : 1;

        await addDoc(datesRef, {
          gameTitle,
          location,
          date: formattedDate,
          time: formattedTime,
          created: Timestamp.now(),
          index: newIndex,
        });
        console.log("Added new date doc to match:", matchId);
      }

      // After saving, go back
      navigation.goBack();
    } catch (error) {
      console.error("Error saving date/time:", error);
      Alert.alert("Error", "Failed to save date/time.");
    }
  };

  return (
    <PaperProvider theme={customTheme}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={styles.container}>
          {editing ? (
            <Text style={styles.editModeText}>Editing Date/Time</Text>
          ) : (
            <Text style={styles.editModeText}>Add Date/Time</Text>
          )}

          <Pressable style={styles.button} onPress={() => setOpenDatePicker(true)}>
            <Text style={styles.buttonText}>Pick Date</Text>
          </Pressable>
          <DatePickerModal
            mode="single"
            visible={openDatePicker}
            onDismiss={onDismissDatePicker}
            date={date}
            onConfirm={onConfirmDate}
          />

          <Pressable style={styles.button} onPress={() => setTimePickerVisible(true)}>
            <Text style={styles.buttonText}>Pick Time</Text>
          </Pressable>
          <TimePickerModal
            visible={timePickerVisible}
            onDismiss={onDismissTimePicker}
            onConfirm={onConfirmTime}
            hours={time.hours}
            minutes={time.minutes}
            label="Select time"
          />

          {/* Show chosen date/time */}
          <Text style={styles.text}>
            Selected date: {moment(date).format("YYYY-MM-DD")}
          </Text>
          <Text style={styles.text}>
            Selected time: {time.hours}:{time.minutes < 10 ? "0" : ""}
            {time.minutes}
          </Text>

          <TextInput
            label="Location"
            value={location}
            onChangeText={setLocation}
            mode="outlined"
            style={{ width: "90%", marginBottom: 20, height: 60 }}
          />

          <TextInput
            label="Game Title (optional)"
            value={gameTitle}
            onChangeText={setGameTitle}
            mode="outlined"
            style={{ width: "90%", marginBottom: 20, height: 60 }}
          />

          <Pressable style={styles.button} onPress={SaveDate}>
            <Text style={styles.buttonText}>Save Game Date and Time</Text>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  editModeText: {
    fontSize: 22,
    color: "#0056b3",
    marginBottom: 20,
  },
  button: {
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#007bff",
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
  },
  text: {
    margin: 10,
    color: "#0056b3",
  },
});
