import React, { useState, useEffect } from "react";
import { View, StyleSheet, ScrollView, Alert } from "react-native";
import {
  Provider as PaperProvider,
  DataTable,
  ActivityIndicator,
  Text,
  Button,
  Card,
  TextInput
} from "react-native-paper";
import { getAuth } from "firebase/auth";
import {
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  writeBatch,
  setDoc
} from "firebase/firestore";
import dayjs from "dayjs";
import { database } from "../../componenets/firebase";

function MembersScreen({ route, navigation }) {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const { matchId } = route.params || {}; // matchId in "games" collection
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);

  // Add Member form state
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!currentUser || !matchId) {
      setLoading(false);
      return;
    }
    const gameDocRef = doc(database, "games", matchId);
    const unsub = onSnapshot(gameDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        const arr = data.memberList || [];
        setMemberList(arr);
        const sumPoints = arr.reduce(
          (total, m) => total + (typeof m.points === "number" ? m.points : 0),
          0
        );
        setTotalPoints(sumPoints);
      } else {
        console.log("Document not found (in snapshot):", matchId);
      }
      setLoading(false);
    });
    return () => unsub();
  }, [matchId, currentUser]);

  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A";
    return dayjs(dateStr).format("YYYY-MM-DD");
  };

  // Updated handleAddMember function (unchanged from previous version)
  const handleAddMember = async () => {
    if (!newName.trim()) {
      Alert.alert("Input Error", "Please provide a name");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (newEmail.trim() && !emailRegex.test(newEmail)) {
      Alert.alert("Input Error", "Please enter a valid email address or leave it blank.");
      return;
    }
    setErrorMessage("");

    // Check the "users" collection for verification status if an email is provided.
    let verifiedStatus = "Not Verified";
    let fetchedName = null;
    let userIdFromUsers = null;
    if (newEmail.trim()) {
      try {
        const userCollectionRef = collection(database, "users");
        const querySnapshot = await getDocs(
          query(userCollectionRef, where("email", "==", newEmail.trim()))
        );
        if (!querySnapshot.empty) {
          // Email exists – use the fetched userId and name and mark as verified.
          userIdFromUsers = querySnapshot.docs[0].id;
          fetchedName = querySnapshot.docs[0].data().name;
          verifiedStatus = "verified";
        }
      } catch (error) {
        console.error("Error checking email in the users collection:", error);
      }
    }

    const memberUid = userIdFromUsers || Date.now().toString();

    const newMember = {
      uid: memberUid,
      name: fetchedName || newName,
      email: newEmail.trim(),
      date: new Date().toISOString(),
      points: 0,
      rank: "",
      role: verifiedStatus,
    };

    try {
      // Update the main "games" document with the new member list
      const gameDocRef = doc(database, "games", matchId);
      const snap = await getDoc(gameDocRef);
      if (!snap.exists()) {
        Alert.alert("Error", "Match document not found");
        return;
      }
      const data = snap.data();
      const oldArr = data.memberList || [];
      const newArr = [...oldArr, newMember];
      await updateDoc(gameDocRef, {
        memberList: newArr,
        members: newArr.length,
      });
      console.log("Added member:", newMember);

      // Add (or update) the member in the "users2" collection
      const userDocRef = doc(database, "users2", memberUid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          name: newMember.name,
          email: newMember.email,
          createdAt: new Date().toISOString(),
        });
        console.log("Added new user to users2:", memberUid);
      } else {
        await updateDoc(userDocRef, {
          name: newMember.name,
          email: newMember.email,
        });
        console.log("Updated user in users2:", memberUid);
      }
      setNewName("");
      setNewEmail("");
    } catch (error) {
      console.error("Error adding/updating member:", error);
      Alert.alert("Error", "Failed to add member");
    }
  };

  // Updated delete logic: remove member from main document and from subcollections (if any)
  async function handleDeleteMember(memberObj) {
    try {
      // First, update the main "games" document to remove the member from memberList.
      const gameDocRef = doc(database, "games", matchId);
      const snap = await getDoc(gameDocRef);
      if (!snap.exists()) {
        Alert.alert("Error", "Match document not found");
        return;
      }
      const data = snap.data();
      const newArr = data.memberList.filter(
        (m) =>
          !(
            m.name === memberObj.name &&
            m.email === memberObj.email &&
            m.date === memberObj.date &&
            m.points === memberObj.points &&
            m.rank === memberObj.rank
          )
      );
      await updateDoc(gameDocRef, {
        memberList: newArr,
        members: newArr.length,
      });
      console.log("Deleted member from main document:", memberObj.name);

      // Next, remove the member from the "Players" subcollection in each "TheGame" document.
      // (Assuming your structure: games/{matchId}/TheGame/{gameDoc}/Players)
      const theGameCollectionRef = collection(database, "games", matchId, "TheGame");
      const gameDocsSnapshot = await getDocs(theGameCollectionRef);
      for (const gameDoc of gameDocsSnapshot.docs) {
        const playersSubcollectionRef = collection(
          database,
          "games",
          matchId,
          "TheGame",
          gameDoc.id,
          "Players"
        );
        const playerDocsSnapshot = await getDocs(playersSubcollectionRef);
        for (const playerDoc of playerDocsSnapshot.docs) {
          const playerData = playerDoc.data();
          if (playerData.userId === memberObj.uid) {
            await deleteDoc(doc(database, "games", matchId, "TheGame", gameDoc.id, "Players", playerDoc.id));
            console.log(`Deleted member ${memberObj.name} from Players subcollection in game ${gameDoc.id}`);
          }
        }
      }
    } catch (err) {
      console.error("Error deleting member:", err);
      Alert.alert("Error", "Failed to delete member");
    }
  }

  function renderMemberRow(m, idx) {
    return (
      <DataTable.Row key={idx}>
        <DataTable.Cell style={styles.nameCell}>{m.name}</DataTable.Cell>
        <DataTable.Cell style={styles.emailCell}>{m.email || "-"}</DataTable.Cell>
        <DataTable.Cell style={styles.dateCell}>{formatDate(m.date)}</DataTable.Cell>
        <DataTable.Cell style={styles.pointsCell} numeric>
          {typeof m.points === "number" ? m.points : 0}
        </DataTable.Cell>
        <DataTable.Cell style={styles.rankCell}>{m.rank || "-"}</DataTable.Cell>
        <DataTable.Cell style={styles.actionCell}>
          <Button mode="text" textColor="red" onPress={() => handleDeleteMember(m)}>
            Delete
          </Button>
        </DataTable.Cell>
      </DataTable.Row>
    );
  }

  if (loading) {
    return (
      <PaperProvider>
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" />
          <Text>Loading...</Text>
        </View>
      </PaperProvider>
    );
  }

  return (
    <PaperProvider>
      <ScrollView style={styles.container}>
        <Card style={styles.summaryCard}>
          <Card.Title title="Members Overview" />
          <Card.Content>
            <Text>Total Members: {memberList.length}</Text>
            <Text>Total Points: {totalPoints}</Text>
          </Card.Content>
        </Card>
        <Card style={styles.addCard}>
          <Card.Title title="Add Member" />
          <Card.Content>
            <TextInput
              label="Name"
              value={newName}
              onChangeText={setNewName}
              style={{ marginBottom: 12 }}
            />
            <TextInput
              label="Email (optional)"
              value={newEmail}
              onChangeText={setNewEmail}
              style={{ marginBottom: 12 }}
            />
            <Button mode="contained" onPress={handleAddMember}>
              Add Member
            </Button>
          </Card.Content>
        </Card>
        <DataTable style={styles.table}>
          <DataTable.Header>
            <DataTable.Title style={styles.nameCell}>Name</DataTable.Title>
            <DataTable.Title style={styles.emailCell}>Email</DataTable.Title>
            <DataTable.Title style={styles.dateCell}>Date</DataTable.Title>
            <DataTable.Title style={styles.pointsCell} numeric>
              Points
            </DataTable.Title>
            <DataTable.Title style={styles.rankCell}>Rank</DataTable.Title>
            <DataTable.Title style={styles.actionCell}>Action</DataTable.Title>
          </DataTable.Header>
          {memberList.map((m, idx) => renderMemberRow(m, idx))}
        </DataTable>
        <Button mode="contained" onPress={() => navigation.goBack()} style={{ margin: 16 }}>
          Go Back
        </Button>
      </ScrollView>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: { backgroundColor: "#f5f5f5" },
  loadingContainer: { flex: 1, alignItems: "center", justifyContent: "center" },
  summaryCard: { margin: 12, borderRadius: 8 },
  addCard: { marginHorizontal: 12, marginBottom: 12, borderRadius: 8 },
  table: {
    marginHorizontal: 12,
    marginTop: 8,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  nameCell: { flex: 1.2 },
  emailCell: { flex: 1.4 },
  dateCell: { flex: 1 },
  pointsCell: { flex: 1, justifyContent: "flex-end" },
  rankCell: { flex: 0.8 },
  actionCell: { flex: 1, justifyContent: "center" },
});

export default MembersScreen;
