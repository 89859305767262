import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Alert,
  ScrollView,
} from "react-native";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { database } from "../../componenets/firebase";

const statuses = ["Going", "Waiting", "Not going"];

export default function MyPlayers({ route }) {
  const { matchId, dateId } = route.params || {};

  const [allPlayers, setAllPlayers] = useState([]);
  const [statusMap, setStatusMap] = useState({});

  useEffect(() => {
    if (!matchId) return;
    const fetchMemberList = async () => {
      try {
        const matchRef = doc(database, "games", matchId);
        const snap = await getDoc(matchRef);
        if (!snap.exists()) {
          Alert.alert("Error", "Match doc not found.");
          return;
        }
        setAllPlayers(snap.data().memberList || []);
      } catch (error) {
        Alert.alert("Error", "Failed to fetch memberList.");
      }
    };
    fetchMemberList();
  }, [matchId]);

  useEffect(() => {
    if (!matchId || !dateId) return;
    const subcolRef = collection(database, "games", matchId, "dates", dateId, "players");
    const unsub = onSnapshot(subcolRef, (snapshot) => {
      const newMap = {};
      snapshot.forEach((docSnap) => {
        const d = docSnap.data();
        const theUid = d.uid && d.uid.trim() !== "" ? d.uid : docSnap.id;
        newMap[theUid] = d.status || "Not going";
      });
      setStatusMap(newMap);
    });
    return () => unsub();
  }, [matchId, dateId]);

  const handleStatusPress = async (player, chosenStatus) => {
    if (!matchId || !dateId) return;
    try {
      const subcolRef = collection(database, "games", matchId, "dates", dateId, "players");
      const docId = player.uid && player.uid.trim() !== "" ? player.uid : undefined;
      if (chosenStatus === "Not going") {
        if (docId) {
          const docRef = doc(subcolRef, docId);
          await deleteDoc(docRef);
          console.log("Removed doc at path:", docRef.path);
        }
      } else {
        const finalDocRef = docId ? doc(subcolRef, docId) : doc(subcolRef);
        await setDoc(finalDocRef, {
          uid: docId || "",
          name: player.name || "",
          email: player.email || "",
          status: chosenStatus,
        });
        console.log("Storing doc at path:", finalDocRef.path);
      }
    } catch (error) {
      Alert.alert("Error", "Failed to update status.");
    }
  };

  const renderMemberRow = ({ item }) => {
    const { uid, name, email } = item;
    const currentStatus = statusMap[uid] || "Not going";
    return (
      <View style={styles.memberRow}>
        <View style={styles.memberInfo}>
          <Text style={styles.memberName}>{name || "Unnamed"}</Text>
          {email ? <Text style={styles.memberSubInfo}>Email: {email}</Text> : null}
        </View>
        <View style={styles.statusRow}>
          {statuses.map((st) => {
            const isSelected = st === currentStatus;
            return (
              <TouchableOpacity
                key={st}
                style={[styles.statusButton, isSelected && styles.statusButtonSelected]}
                onPress={() => handleStatusPress(item, st)}
              >
                <Text style={[styles.statusButtonText, isSelected && styles.statusButtonTextSelected]}>
                  {st}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <Text style={styles.title}>Match {matchId}, Date {dateId}</Text>
      {allPlayers.length === 0 ? (
        <Text style={styles.noMembersText}>No members found.</Text>
      ) : (
        <ScrollView>
          <FlatList
            data={allPlayers}
            keyExtractor={(_, idx) => String(idx)}
            renderItem={renderMemberRow}
          />
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#f9f9f9", padding: 16 },
  title: { fontSize: 18, fontWeight: "600", marginBottom: 12, textAlign: "center" },
  noMembersText: { textAlign: "center", marginTop: 20, fontStyle: "italic", color: "#666" },
  memberRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 8,
    borderBottomWidth: 1,
    borderColor: "#ddd",
    backgroundColor: "#fff",
    marginBottom: 6,
    borderRadius: 6,
  },
  memberInfo: { flexDirection: "column", flex: 1 },
  memberName: { fontSize: 16, fontWeight: "600", color: "#333" },
  memberSubInfo: { fontSize: 14, color: "#666" },
  statusRow: { flexDirection: "row", alignItems: "center" },
  statusButton: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginHorizontal: 2,
    backgroundColor: "#ccc",
  },
  statusButtonSelected: { backgroundColor: "#4361ee", borderColor: "#4361ee" },
  statusButtonText: { fontSize: 12, color: "#333" },
  statusButtonTextSelected: { color: "#fff", fontWeight: "600" },
});
