// AddPlayerScreen.js
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Alert, Platform } from 'react-native';
import {
  Provider as PaperProvider,
  DataTable,
  ActivityIndicator,
  Text,
  Button,
  Card,
  TextInput
} from 'react-native-paper';
import {
  getDoc,
  doc,
  onSnapshot,
  updateDoc,
  getFirestore,
  deleteDoc,
  collection,
  getDocs,
  writeBatch,
  query,
  where,
  setDoc
} from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const AddPlayerScreen = ({ route, navigation }) => {
  const [playerName, setPlayerName] = useState('');
  const [email, setEmail] = useState('');
  const [players, setPlayers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPlayerId, setEditingPlayerId] = useState(null);
  const database = getFirestore();
  const currentMainGameId = route.params.mainGameId.toString();
  const [errorMessage, setErrorMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const auth = getAuth();

  // Check if current user is admin/owner
  useEffect(() => {
    const currentUserId = auth.currentUser ? auth.currentUser.uid : "";
    const docRef = doc(database, "MainGame", currentMainGameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const gameData = docSnap.data();
        const currentPlayer = gameData.memberOfGame.find(
          (player) => player.uid === currentUserId
        );
        if (currentPlayer) {
          setIsAdmin(!!currentPlayer.isAdmin);
          setVerified(currentPlayer.role === 'verified');
          setIsOwner(!!currentPlayer.isOwner);
        } else {
          setIsAdmin(false);
          setVerified(false);
          setIsOwner(false);
        }
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId]);

  // Listen to memberOfGame array
  useEffect(() => {
    const docRef = doc(database, 'MainGame', currentMainGameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setPlayers(docSnap.data().memberOfGame || []);
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId]);

  // Invite player (send email)
  const handleToggleInvite = async (playerUid) => {
    const player = players.find((p) => p.uid === playerUid);
    try {
      const response = await fetch(
        "https://us-central1-quizapp-784c4.cloudfunctions.net/sendEmailAPI",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: player.email,
            name: player.name,
          }),
        }
      );
      const data = await response.json();
      if (data && data.success) {
        // Mark invited in DB
        const docRef = doc(database, "MainGame", currentMainGameId);
        const gameSnap = await getDoc(docRef);
        if (gameSnap.exists()) {
          let updatedMembers = gameSnap.data().memberOfGame.map((p) => {
            if (p.uid === player.uid) {
              return { ...p, isInvited: true, role: "invited" };
            }
            return p;
          });
          await updateDoc(docRef, { memberOfGame: updatedMembers });
          Alert.alert("Success", "Email sent and player updated!");
        }
      } else {
        Alert.alert("Error", "Failed to send the email.");
      }
    } catch (error) {
      console.error("Error sending email or updating DB:", error);
      Alert.alert("Error", "There was an error sending the email or updating the database.");
    }
  };

  // Remove player
  const immediateRemovePlayer = async (playerUid) => {
    const removePlayer = async () => {
      // Filter out locally
      const updatedPlayers = players.filter((player) => player.uid !== playerUid);
      setPlayers(updatedPlayers);
      try {
        // Update main doc with new array AND update "members" count
        const docRef = doc(database, "MainGame", currentMainGameId);
        await updateDoc(docRef, {
          memberOfGame: updatedPlayers,
          members: updatedPlayers.length,
        });
        // Remove from subcollections
        const theGameCollectionRef = collection(
          database, "MainGame", currentMainGameId, "TheGame"
        );
        const gameDocsSnapshot = await getDocs(theGameCollectionRef);
        gameDocsSnapshot.forEach(async (gameDoc) => {
          const playersSubcollectionRef = collection(
            database, "MainGame", currentMainGameId, "TheGame", gameDoc.id, "Players"
          );
          const playerDocsSnapshot = await getDocs(playersSubcollectionRef);
          playerDocsSnapshot.forEach(async (pDoc) => {
            if (pDoc.data().userId === playerUid) {
              await deleteDoc(doc(
                database, "MainGame", currentMainGameId, "TheGame",
                gameDoc.id, "Players", pDoc.id
              ));
            }
          });
        });
      } catch (error) {
        console.error("Error removing player:", error);
      }
    };
    // Confirm removal
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      Alert.alert(
        "Remove Player",
        "Are you sure you want to remove this player?",
        [
          { text: "Cancel", style: "cancel" },
          { text: "OK", onPress: removePlayer },
        ],
        { cancelable: true }
      );
    } else if (Platform.OS === 'web') {
      if (window.confirm("Are you sure you want to remove this player?")) {
        removePlayer();
      }
    } else {
      removePlayer();
    }
  };

  // Delete entire game doc + subcollections
  const handleDeleteConfirmation1 = async () => {
    const executeDeletion = async () => {
      try {
        const batch = writeBatch(database);
        const theGameCollectionRef = collection(
          database, "MainGame", currentMainGameId, "TheGame"
        );
        const theGameSnapshot = await getDocs(theGameCollectionRef);
        theGameSnapshot.docs.forEach((docSnapshot) => {
          batch.delete(docSnapshot.ref);
        });
        await batch.commit();
        const docRef = doc(database, "MainGame", currentMainGameId);
        await deleteDoc(docRef);
      } catch (error) {
        console.error("Error deleting document/subcollections:", error);
      }
    };
    if (Platform.OS === "web") {
      if (window.confirm("Are you sure you want to delete this game?")) {
        executeDeletion();
      }
    } else {
      Alert.alert(
        "Confirm Deletion",
        "Are you sure you want to delete this game? This cannot be undone.",
        [
          { text: "Cancel", style: "cancel" },
          { text: "Delete", onPress: () => executeDeletion(), style: "destructive" }
        ],
        { cancelable: false }
      );
    }
  };

  // Add or edit player
  const handleAddOrEditPlayer = async () => {
    if (!playerName.trim()) {
      setErrorMessage('Player name cannot be blank.');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() && !emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address or leave it blank.');
      return;
    }
    if (email.trim()) {
      const emailInUse = players.some(
        (player) => player.email === email && player.uid !== editingPlayerId
      );
      if (emailInUse) {
        setErrorMessage('This email is already in use by another player in this game.');
        return;
      }
    }
    const nameInUseByUnverified = players.some(
      (player) =>
        player.name === playerName &&
        player.role !== 'verified' &&
        player.uid !== editingPlayerId
    );
    if (nameInUseByUnverified) {
      setErrorMessage('This name is already in use by an unverified player in this game.');
      return;
    }
    setErrorMessage('');
    let userId = null;
    let fetchedName = null;
    let verifiedStatus = 'Not Verified';
    if (email.trim()) {
      try {
        const userCollectionRef = collection(database, 'users');
        const querySnapshot = await getDocs(
          query(userCollectionRef, where('email', '==', email))
        );
        if (!querySnapshot.empty) {
          userId = querySnapshot.docs[0].id;
          fetchedName = querySnapshot.docs[0].data().name;
          verifiedStatus = 'verified';
        }
      } catch (error) {
        console.error('Error checking email in user collection:', error);
        setErrorMessage('Error checking email in the user collection.');
        return;
      }
    }
    let updatedPlayers = [...players];
    let playerToUpdate = null;
    if (isEditing) {
      updatedPlayers = updatedPlayers.map((player) => {
        if (player.uid === editingPlayerId) {
          playerToUpdate = {
            ...player,
            name: fetchedName || playerName,
            email: email,
            uid: userId || player.uid,
            role: verifiedStatus
          };
          return playerToUpdate;
        }
        return player;
      });
    } else {
      playerToUpdate = {
        uid: userId || Date.now().toString(),
        name: fetchedName || playerName,
        email: email,
        isInvited: false,
        isAdmin: false,
        role: verifiedStatus
      };
      updatedPlayers.push(playerToUpdate);
    }
    const docRef = doc(database, 'MainGame', currentMainGameId);
    await updateDoc(docRef, {
      memberOfGame: updatedPlayers,
      members: updatedPlayers.length,
    });
    if (userId) {
      const userDocRef = doc(database, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists() && !userDocSnap.data().oldUid) {
        await updateDoc(userDocRef, {
          oldUid: editingPlayerId
        }).then(() => {
          console.log(`Added oldUid ${editingPlayerId} to users/${userId}`);
        }).catch((error) => {
          console.error(`Failed to update users collection with oldUid: ${error}`);
        });
      }
    }
    if (playerToUpdate) {
      const theGameRef = collection(database, "MainGame", currentMainGameId, "TheGame");
      const theGameSnapshot = await getDocs(theGameRef);
      for (const theGameDoc of theGameSnapshot.docs) {
        const playersRef = collection(
          database, "MainGame", currentMainGameId, "TheGame", theGameDoc.id, "Players"
        );
        const playersSnapshot = await getDocs(playersRef);
        for (const pDoc of playersSnapshot.docs) {
          const playerData = pDoc.data();
          if (playerData.userId === (editingPlayerId || playerToUpdate.uid)) {
            const playerDocRef = doc(
              database, "MainGame", currentMainGameId, "TheGame",
              theGameDoc.id, "Players", pDoc.id
            );
            const oldUid = playerData.userId;
            await updateDoc(playerDocRef, {
              userId: playerToUpdate.uid,
              Name: playerToUpdate.name,
              email: playerToUpdate.email,
              oldUid: oldUid,
            }).then(() => {
              console.log(
                `Updated player ${playerToUpdate.name}, oldUid ${oldUid}`
              );
            }).catch((error) => {
              console.error(`Failed to update player document: ${error}`);
            });
          }
        }
      }
    }
    resetForm();
  };

  const selectForEdit = (player) => {
    setPlayerName(player.name);
    setEmail(player.email);
    setIsEditing(true);
    setEditingPlayerId(player.uid);
  };

  const resetForm = () => {
    setPlayerName('');
    setEmail('');
    setIsEditing(false);
    setEditingPlayerId(null);
  };

  // Toggle player role
  const togglePlayerRole = async (uid) => {
    const updatedPlayers = players.map((player) =>
      player.uid === uid ? { ...player, isAdmin: !player.isAdmin } : player
    );
    setPlayers(updatedPlayers);
    const docRef = doc(database, 'MainGame', currentMainGameId);
    await updateDoc(docRef, { memberOfGame: updatedPlayers });
  };

  // Render each player row using DataTable row
  const renderPlayerRow = (player, index) => (
    <DataTable.Row key={index}>
      <DataTable.Cell style={styles.numberCell}>{index + 1}</DataTable.Cell>
      <DataTable.Cell style={styles.nameCell}>{player.name}</DataTable.Cell>
      <DataTable.Cell style={styles.emailCell}>{player.email || "-"}</DataTable.Cell>
      <DataTable.Cell style={styles.roleCell}>
        {player.isAdmin ? 'Admin' : 'Player'}
      </DataTable.Cell>
      <DataTable.Cell style={styles.verifiedCell}>
        {player.role === 'verified' ? 'Verified' : 'Not Verified'}
      </DataTable.Cell>
      <DataTable.Cell style={styles.actionCell}>
        <Button mode="text" onPress={() => selectForEdit(player)}>Edit</Button>
      </DataTable.Cell>
      <DataTable.Cell style={styles.actionCell}>
        <Button mode="text" onPress={() => handleToggleInvite(player.uid)}>Invite</Button>
      </DataTable.Cell>
      {isAdmin && (
        <DataTable.Cell style={styles.actionCell}>
          <Button mode="text" textColor="red" onPress={() => immediateRemovePlayer(player.uid)}>
            Remove
          </Button>
        </DataTable.Cell>
      )}
    </DataTable.Row>
  );

  if (!players && players.length === 0 && errorMessage) {
    return (
      <PaperProvider>
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" />
          <Text>Loading...</Text>
        </View>
      </PaperProvider>
    );
  }

  return (
    <PaperProvider>
      <ScrollView style={styles.container}>
        {isOwner && (
          <Card style={styles.deleteCard}>
            <Card.Content>
              <Button mode="contained" onPress={handleDeleteConfirmation1}>
                Delete Game
              </Button>
            </Card.Content>
          </Card>
        )}
        <Card style={styles.addCard}>
          <Card.Title title={isEditing ? "Edit Player" : "Add Player"} />
          <Card.Content>
            <TextInput
              label="Player Name"
              value={playerName}
              onChangeText={setPlayerName}
              style={styles.input}
              onFocus={() => setIsNameFocused(true)}
              onBlur={() => setIsNameFocused(false)}
            />
            <TextInput
              label="Email - Optional"
              value={email}
              onChangeText={setEmail}
              style={styles.input}
            />
            {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
            <Button mode="contained" onPress={handleAddOrEditPlayer} style={styles.addButton}>
              {isEditing ? "Save Player" : "Add Player"}
            </Button>
          </Card.Content>
        </Card>
        <DataTable style={styles.table}>
          <DataTable.Header>
            <DataTable.Title style={styles.numberCell}>
              <Text style={styles.headerTitle}>#</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.nameCell}>
              <Text style={styles.headerTitle}>Name</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.emailCell}>
              <Text style={styles.headerTitle}>Email</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.roleCell}>
              <Text style={styles.headerTitle}>Role</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.verifiedCell}>
              <Text style={styles.headerTitle}>Verified</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.actionCell}>
              <Text style={styles.headerTitle}>Edit</Text>
            </DataTable.Title>
            <DataTable.Title style={styles.actionCell}>
              <Text style={styles.headerTitle}>Invite</Text>
            </DataTable.Title>
            {isAdmin && (
              <DataTable.Title style={styles.actionCell}>
                <Text style={styles.headerTitle}>Remove</Text>
              </DataTable.Title>
            )}
          </DataTable.Header>
          {players.map((player, index) => renderPlayerRow(player, index))}
        </DataTable>
        <Button mode="contained" onPress={() => navigation.goBack()} style={styles.goBackButton}>
          Go Back
        </Button>
      </ScrollView>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#f5f5f5",
    padding: 12,
  },
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  deleteCard: {
    marginBottom: 12,
    borderRadius: 8,
  },
  addCard: {
    marginBottom: 12,
    borderRadius: 8,
  },
  input: {
    marginBottom: 12,
    backgroundColor: "#fff"
  },
  addButton: {
    marginTop: 8,
  },
  errorText: {
    color: "#f44336",
    marginBottom: 8,
    textAlign: "center",
  },
  table: {
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  numberCell: {
    flex: 0.4,
    justifyContent: "center",
    alignItems: "center",
  },
  nameCell: {
    flex: 1.4,
  },
  emailCell: {
    flex: 1.6,
  },
  roleCell: {
    flex: 1,
    justifyContent: "center",
  },
  verifiedCell: {
    flex: 1.2,
    justifyContent: "center",
  },
  actionCell: {
    flex: 1,
    justifyContent: "center",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  goBackButton: {
    margin: 16,
  },
});

export default AddPlayerScreen;
