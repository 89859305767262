import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Alert } from "react-native";
import { Button } from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { database } from "../../componenets/firebase";

export default function MyMatch() {
  const navigation = useNavigation();
  const route = useRoute();
  // Retrieve matchId from previous screen's route parameters.
  const { matchId } = route.params || {};

  // Local state for match name and settings
  const [matchName, setMatchName] = useState("");
  const [gameType, setGameType] = useState("");
  const [mode, setMode] = useState("");
  const [scoringMethod, setScoringMethod] = useState("");

  // This state holds the list of dates from Firestore
  const [datesList, setDatesList] = useState([]);

  // Hardcoded options for each field
  const gameTypeOptions = ["Sports", "Board Games", "Esports"];
  const modeOptions = ["Team-based", "Individual (PvP)", "Tournament", "Voting Game"];
  const scoringMethodOptions = ["Points-based", "Win/Loss", "Time-based"];

  // Fetch current match settings from Firebase
  useEffect(() => {
    if (!matchId) return;
    const gameDocRef = doc(database, "games", matchId);
    getDoc(gameDocRef)
      .then((snap) => {
        if (snap.exists()) {
          const data = snap.data();
          setMatchName(data.name || "Unnamed Match");
          setGameType(data.gameType || "");
          setMode(data.mode || "");
          setScoringMethod(data.scoringMethod || "");
        } else {
          Alert.alert("Error", "Match document not found.");
        }
      })
      .catch((err) => {
        console.error("Error fetching match settings:", err);
        Alert.alert("Error", "Failed to fetch match settings.");
      });
  }, [matchId]);

  // Fetch the "dates" subcollection and store it in datesList
  useEffect(() => {
    if (!matchId) return;

    const fetchDates = async () => {
      try {
        const datesRef = collection(database, "games", matchId, "dates");
        const snapshot = await getDocs(datesRef);
        const loadedDates = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setDatesList(loadedDates);
      } catch (error) {
        console.error("Error fetching dates:", error);
        Alert.alert("Error", "Failed to fetch dates.");
      }
    };

    fetchDates();
  }, [matchId]);

  // Helper function to update a setting in Firestore immediately
  const updateSetting = async (field, value) => {
    if (!matchId) return;
    try {
      const gameDocRef = doc(database, "games", matchId);
      await updateDoc(gameDocRef, { [field]: value });
      console.log(`Updated ${field} to ${value} in match ${matchId}`);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      Alert.alert("Error", `Failed to update ${field}`);
    }
  };

  // Navigate to MembersScreen
  const goToMembers = async () => {
    if (!matchId) {
      Alert.alert("Error", "Match document not found. Please create a match first.");
      return;
    }
    try {
      const gameDocRef = doc(database, "games", matchId);
      await updateDoc(gameDocRef, {
        name: matchName,
        gameType,
        mode,
        scoringMethod,
      });
      console.log("Updated match document:", matchId, { matchName, gameType, mode, scoringMethod });
      navigation.navigate("MembersScreen", {
        matchId,
        gameType,
        mode,
        scoringMethod,
        name: matchName,
      });
    } catch (error) {
      console.error("Error updating match document:", error);
      Alert.alert("Error", "Failed to update match settings.");
    }
  };

  // Navigate to the "MyGameDate" screen
  const handleAddDates = () => {
    if (!matchId) {
      Alert.alert("Error", "Match document not found. Please create a match first.");
      return;
    }
    navigation.navigate("MyGameDate", { matchId });
  };

  // Navigate to MyPlayers screen with date info
  const handleDatePress = (dateObj) => {
    // e.g. navigate to "MyPlayers" with matchId and dateId
    navigation.navigate("MyPlayers", {
      matchId,
      dateId: dateObj.id,
      // pass along other date fields if needed
    });
  };

  // Render a row of option buttons for a specific category
  const renderOptionsRow = (title, options, selectedValue, setValueFn, fieldKey) => {
    return (
      <View style={styles.categoryCard}>
        <Text style={styles.categoryTitle}>{title}</Text>
        <View style={styles.optionsRow}>
          {options.map((option) => {
            const isSelected = selectedValue === option;
            return (
              <TouchableOpacity
                key={option}
                style={[styles.optionButton, isSelected && styles.optionButtonSelected]}
                onPress={() => {
                  setValueFn(option);
                  updateSetting(fieldKey, option);
                }}
              >
                <Text style={[styles.optionText, isSelected && styles.optionTextSelected]}>
                  {option}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  };

  // Renders each date from Firestore, making it pressable
  const renderDateItem = (dateObj) => {
    const { id, date, time, location, index } = dateObj;
    return (
      <TouchableOpacity
        key={id}
        style={styles.dateItem}
        onPress={() => handleDatePress(dateObj)}
      >
        <Text style={styles.dateText}>Index: {index || 1}</Text>
        <Text style={styles.dateText}>Date: {date || "-"}</Text>
        <Text style={styles.dateText}>Time: {time || "-"}</Text>
        <Text style={styles.dateText}>Location: {location || "-"}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>Match {matchName}</Text>

      {/* Category: Game Type */}
      {renderOptionsRow("Game Type", gameTypeOptions, gameType, setGameType, "gameType")}

      {/* Category: Mode */}
      {renderOptionsRow("Mode", modeOptions, mode, setMode, "mode")}

      {/* Category: Scoring Method */}
      {renderOptionsRow("Scoring Method", scoringMethodOptions, scoringMethod, setScoringMethod, "scoringMethod")}

      {/* Bottom buttons */}
      <Button mode="contained" onPress={goToMembers} style={styles.button}>
        Go to Member Screen
      </Button>
      <Button mode="outlined" onPress={handleAddDates} style={styles.addDatesButton}>
        Add Dates
      </Button>

      {/* Display list of existing dates (with index logic) */}
      <Text style={styles.datesHeader}>Existing Dates:</Text>
      {datesList.length === 0 ? (
        <Text style={styles.noDatesText}>No dates found.</Text>
      ) : (
        datesList.map(renderDateItem)
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#e9edf1",
    padding: 20,
    flexGrow: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    color: "#333",
    marginBottom: 8,
    marginTop: 8,
  },
  categoryCard: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    elevation: 2,
  },
  categoryTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#444",
    marginBottom: 12,
  },
  optionsRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  optionButton: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 8,
    width: "30%",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
  },
  optionButtonSelected: {
    backgroundColor: "#4361ee",
    borderColor: "#4361ee",
  },
  optionText: {
    fontSize: 15,
    color: "#333",
    textAlign: "center",
  },
  optionTextSelected: {
    color: "#fff",
    fontWeight: "700",
  },
  button: {
    marginTop: 24,
    width: "100%",
    padding: 14,
    borderRadius: 8,
    backgroundColor: "#4361ee",
  },
  addDatesButton: {
    marginTop: 16,
    width: "100%",
    padding: 14,
    borderRadius: 8,
    borderColor: "#4361ee",
  },
  datesHeader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#333",
    marginTop: 24,
    marginBottom: 8,
    alignSelf: "flex-start",
  },
  noDatesText: {
    color: "#666",
    fontStyle: "italic",
    marginBottom: 12,
    alignSelf: "flex-start",
  },
  dateItem: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 12,
    marginBottom: 10,
    shadowColor: "#000",
    shadowOpacity: 0.04,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
    elevation: 1,
  },
  dateText: {
    fontSize: 14,
    color: "#333",
    marginBottom: 4,
  },
});
