// GameItemCard.js
import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Card, Paragraph, Button, Text } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import moment from "moment";

// Import your partial membership logic for folders:
import { countTotalMembers } from "./utils/getTotalMembersForFolder";

// Import your Firestore and Auth from firebase.js
import { database, auth } from "../componenets/firebase";

// Simple cache for user names:
const userCache = {};

/**
 * Hook that listens to a match doc's numeric `members` field in real time.
 * If your match docs are in "MainGame", we read from doc(database, "MainGame", matchId).
 */
function useLiveMatchMemberCount(matchId) {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!matchId) return;
    const docRef = doc(database, "MainGame", matchId);
    const unsub = onSnapshot(docRef, (snap) => {
      if (snap.exists()) {
        const data = snap.data();
        // If the doc has a numeric "members" field, use it
        setCount(typeof data.members === "number" ? data.members : 0);
      }
    });
    return () => unsub();
  }, [matchId]);

  return count;
}

export default function GameItemCard({ item, onDelete, onSettings }) {
  const navigation = useNavigation();
  const [creatorName, setCreatorName] = useState(null);

  // 1) Get current user’s UID from auth
  const currentUserId = auth.currentUser?.uid;

  // 2) If it's a folder => partial membership sum, else read match doc's numeric field
  const liveMembers =
    item.type === "Folder"
      ? countTotalMembers(item.id, currentUserId)
      : useLiveMatchMemberCount(item.id);

  // Fetch the creator’s display name from "users" (cached)
  useEffect(() => {
    let isMounted = true;
    async function fetchCreatorName() {
      if (item.userId) {
        if (userCache[item.userId]) {
          if (isMounted) setCreatorName(userCache[item.userId]);
        } else {
          try {
            const userDocRef = doc(database, "users", item.userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const name = userDoc.data().name;
              userCache[item.userId] = name;
              if (isMounted) setCreatorName(name);
            } else {
              if (isMounted) setCreatorName("Unknown");
            }
          } catch (error) {
            console.error("Error fetching creator name:", error);
            if (isMounted) setCreatorName("Error");
          }
        }
      }
    }
    fetchCreatorName();
    return () => {
      isMounted = false;
    };
  }, [item.userId]);

  // Navigate logic
  const handleEnterPress = () => {
    if (!item.id) {
      alert("Document ID is missing. Please create the document first.");
      return;
    }
    if (item.type === "Folder") {
      navigation.push("MyGames", { folderId: item.id });
    } else {
      // If it's a Dart tournament, navigate to DartTournament screen
      if (
        item.gameCategory === "Sports" &&
        item.gameTitle === "Dart" &&
        item.mode === "Tournament"
      ) {
        navigation.push("DartTournament", { MainGameId: item.id });
      } 
      // For testing: if the gameCategory is "Card Games" and the gameTitle is "Spades" and mode is "Tournament"
      else if (
       // item.gameCategory === "Card games" &&
       // item.gameTitle === "Spades" &&
        item.mode === "Tournament"
      ) {
        console.log("Card Games Spades detected. Navigating to TournamentSetup.");
        navigation.push("StartTournament", {
          MainGameId: item.id,
          gameName: item.name,
          roomId: item.roomId || null,
          gameCategory: item.gameCategory,
          gameTitle: item.gameTitle,
          mode: item.mode,
          created: item.created,
          isPublic: item.isPublic,
          userId: item.userId,
          // You can add more parameters here if needed
        });


      } else {
        // Otherwise, go to the default Game Date screen
        navigation.push("Game Date", { MainGameId: item.id });
      }
    }
  };
  
  
  
  

  // Basic background color
  const cardBackgroundColor = item.type === "Folder" ? "#E0F7FA" : "#FFF8E1";

  return (
    <Card style={[styles.card, { backgroundColor: cardBackgroundColor }]}>
      {item.imageUrl && <Card.Cover source={{ uri: item.imageUrl }} />}
      <Card.Title
        title={item.name}
       />
      <Card.Content>
        <Paragraph>
          Created:{" "}
          {item.created?.toDate
            ? moment(item.created.toDate()).format("D.MMM'YY").toLowerCase()
            : "N/A"}
        </Paragraph>
        <Paragraph>View: {item.isPublic ? "Public" : "Private"}</Paragraph>
        <Paragraph>Type: {item.type}</Paragraph>
        <Paragraph>Mode: {item.mode}</Paragraph>
        <Paragraph>Category: {item.gameCategory}</Paragraph>
        <Paragraph>Title: {item.gameTitle}</Paragraph>

        
        
        {auth.currentUser?.email === "dondanni@hotmail.com" && (
        <Paragraph style={styles.docIdStyle}>Doc ID: {item.id}</Paragraph>)}
        {item.userId && (
          <Paragraph style={styles.creatorInfo}>
            Creator: {creatorName || "Loading..."}
          </Paragraph>
        )}

        {/* Show live membership count */}
        <Paragraph>Members: {liveMembers}</Paragraph>
      </Card.Content>

      <Card.Actions>
        <Button onPress={handleEnterPress}>Enter</Button>
        <View style={styles.iconsRow}>
          {/* Only show delete button if the current user is the owner */}
          {onDelete && item.userId === currentUserId && (
            <TouchableOpacity onPress={() => onDelete(item)}>
              <MaterialIcons
                name="delete"
                size={20}
                color="red"
                style={styles.icon}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={() => onSettings(item)}>
            <MaterialIcons
              name="settings"
              size={20}
              color="#333"
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
      </Card.Actions>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    margin: 8,
  },
  docIdStyle: {
    fontSize: 10,
    color: "#666",
    marginBottom: 4,
  },
  creatorInfo: {
    fontSize: 12,
    color: "#555",
  },
  iconsRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  icon: {
    marginHorizontal: 4,
  },
});
