 // helper.js


// helper.js

//
// 1) ADVANCED EQUALITY + COMPARISON HELPERS
//
export function arePlayersEqual(arr1, arr2) {
    if (arr1 === arr2) return true;
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].id !== arr2[i].id || arr1[i].text !== arr2[i].text) {
        return false;
      }
    }
    return true;
  }
  
  export function areGroupAssignmentsEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (!obj1 || !obj2) return false;
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key)) return false;
      if (!arePlayersEqual(obj1[key], obj2[key])) return false;
    }
    return true;
  }
  
  export function areSubGamesEqual(arr1, arr2) {
    if (arr1 === arr2) return true;
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].winner !== arr2[i].winner || arr1[i].enterName !== arr2[i].enterName) {
        return false;
      }
    }
    return true;
  }
  
  export function areMatchObjectsEqual(m1, m2) {
    if (m1 === m2) return true;
    if (!m1 || !m2) return false;
    if (
      m1.id !== m2.id ||
      m1.groupName !== m2.groupName ||
      m1.time !== m2.time ||
      m1.field !== m2.field ||
      m1.pairing !== m2.pairing ||
      m1.result !== m2.result ||
      m1.player1 !== m2.player1 ||
      m1.player2 !== m2.player2 ||
      m1.isKnockout !== m2.isKnockout ||
      m1.winnerName !== m2.winnerName
    ) {
      return false;
    }
    return areSubGamesEqual(m1.subGames, m2.subGames);
  }
  
  export function areMatchesEqual(arr1, arr2) {
    if (arr1 === arr2) return true;
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (!areMatchObjectsEqual(arr1[i], arr2[i])) return false;
    }
    return true;
  }
  
  export function areKnockoutRoundsEqual(arr1, arr2) {
    if (arr1 === arr2) return true;
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (
        arr1[i].roundName !== arr2[i].roundName ||
        !areMatchesEqual(arr1[i].matches, arr2[i].matches)
      ) {
        return false;
      }
    }
    return true;
  }
  
  /**
   * Advanced areTournamentResultsEqual:
   * Checks group assignments, players, matches, knockout, etc.
   */
  export function areTournamentResultsEqual(newResults, oldResults) {
    if (newResults === oldResults) return true;
    if (!newResults || !oldResults) return false;
    const r1 = newResults.Results;
    const r2 = oldResults.Results;
    if (r1 === r2) return true;
    if (!r1 || !r2) return false;
    if (r1.numberOfGroups !== r2.numberOfGroups || r1.finalized !== r2.finalized) return false;
    if (!arePlayersEqual(r1.players, r2.players)) return false;
    if (!areGroupAssignmentsEqual(r1.groupAssignments, r2.groupAssignments)) return false;
    if (!areMatchesEqual(r1.allMatches, r2.allMatches)) return false;
    if (!areKnockoutRoundsEqual(r1.knockoutRounds, r2.knockoutRounds)) return false;
    return true;
  }
  
  //
  // 2) Fix subGames if missing
  //
  export function fixSubGamesIfMissing(matches) {
    return matches.map((m) => {
      if (!m.subGames || m.subGames.length < 3) {
        m.subGames = [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
        ];
      }
      return m;
    });
  }
  
  //
  // 3) Generate all pairs in a group
  //
  export function generateAllPairs(teams) {
    const pairs = [];
    for (let i = 0; i < teams.length; i++) {
      for (let j = i + 1; j < teams.length; j++) {
        pairs.push([teams[i], teams[j]]);
      }
    }
    return pairs;
  }
  
  //
  // 4) Compute final result from subGames (best of 3)
  //
  export function computeFinalResult(subGames) {
    let p1Wins = 0;
    let p2Wins = 0;
    subGames.forEach(g => {
      if (g.winner === "p1") p1Wins++;
      if (g.winner === "p2") p2Wins++;
    });
    return `${p1Wins}-${p2Wins}`;
  }
  
  //
  // 5) Compute group stats (minimal example)
  //
  export function computeGroupStats(matches) {
    const map = {};
    matches.forEach(m => {
      const { player1, player2, result } = m;
      if (!map[player1]) {
        map[player1] = { name: player1, w: 0, l: 0, pld: 0, d: 0 };
      }
      if (!map[player2]) {
        map[player2] = { name: player2, w: 0, l: 0, pld: 0, d: 0 };
      }
      if (result && result !== "0-0") {
        const [p1Wins, p2Wins] = result.split("-").map(x => parseInt(x, 10) || 0);
        map[player1].pld++;
        map[player2].pld++;
        if (p1Wins > p2Wins) {
          map[player1].w++;
          map[player2].l++;
        } else if (p2Wins > p1Wins) {
          map[player2].w++;
          map[player1].l++;
        }
      }
    });
    const arr = Object.values(map);
    arr.sort((a, b) => b.w - a.w || a.name.localeCompare(b.name));
    return arr;
  }
  
  //
  // 6) Check if a group is complete
  //
  export function isGroupComplete(matches) {
    return matches.every(m => m.result && m.result !== "0-0");
  }
  
  //
  // 7) Build a knockout bracket: generateKnockoutRounds
  //
  export function generateKnockoutRounds(initialMatches) {
    const rounds = [];
    let currentMatches = initialMatches;
    let roundIndex = 1;
    while (currentMatches.length >= 1) {
      const roundName = getRoundName(currentMatches.length);
      rounds.push({
        roundName,
        matches: currentMatches,
      });
      const newMatches = [];
      for (let i = 0; i < currentMatches.length; i += 2) {
        const match1 = currentMatches[i];
        const match2 = currentMatches[i + 1];
        if (match2) {
          const id = `R${roundIndex}-M${(i / 2) + 1}`;
          newMatches.push({
            id,
            player1: `Winner of ${match1.id}`,
            player2: `Winner of ${match2.id}`,
            result: '',
            subGames: [
              { winner: '', enterName: '' },
              { winner: '', enterName: '' },
              { winner: '', enterName: '' },
            ],
            isKnockout: true,
          });
        }
      }
      if (newMatches.length === 0) break;
      currentMatches = newMatches;
      roundIndex++;
    }
    return rounds;
  }
  
  function getRoundName(numMatches) {
    if (numMatches === 4) return 'Quarterfinals';
    if (numMatches === 2) return 'Semifinals';
    if (numMatches === 1) return 'Final';
    return `Round of ${numMatches * 2}`;
  }
  
  //
  // 8) Resolve knockout name to actual player name based on group matches and bracket results
  //
  export function resolveKnockoutName(name, groupMatches, knockoutRounds) {
    if (name.startsWith("Winner of Group") || name.startsWith("Preliminary Runner-up of Group")) {
      const isWinner = name.startsWith("Winner of Group");
      const groupName = isWinner
        ? name.replace("Winner of ", "")
        : name.replace("Preliminary Runner-up of ", "");
      const matchesInGroup = groupMatches[groupName] || [];
      if (!isGroupComplete(matchesInGroup)) {
        return name;
      }
      const standings = computeGroupStats(matchesInGroup);
      return isWinner
        ? (standings[0] ? standings[0].name : name)
        : (standings[1] ? standings[1].name : name);
    }
    if (name.startsWith("Winner of ")) {
      const matchId = name.replace("Winner of ", "");
      for (let round of knockoutRounds) {
        for (let m of round.matches) {
          if (m.id === matchId) {
            if (!m.result) return "TBD";
            const [p1ScoreStr, p2ScoreStr] = m.result.split("-");
            const p1Score = parseInt(p1ScoreStr, 10) || 0;
            const p2Score = parseInt(p2ScoreStr, 10) || 0;
            if (p1Score > p2Score) {
              return resolveKnockoutName(m.player1, groupMatches, knockoutRounds);
            } else {
              return resolveKnockoutName(m.player2, groupMatches, knockoutRounds);
            }
          }
        }
      }
      return "TBD";
    }
    return name;
  }
  
  //
  // 9) Randomize matches (simulate results)
  //
  export function randomizeMatches(matches) {
    return matches.map(m => {
      if (!m.player1 || !m.player2) return m;
      const newSubGames = [0, 1, 2].map(() => {
        const winner = Math.random() < 0.5 ? "p1" : "p2";
        return {
          winner,
          enterName: winner === "p1" ? m.player1 : m.player2,
          enteredBy: "RandomBot"
        };
      });
      const p1Wins = newSubGames.filter(sg => sg.winner === "p1").length;
      const p2Wins = newSubGames.filter(sg => sg.winner === "p2").length;
      const finalResult = `${p1Wins}-${p2Wins}`;
      let winnerName = "";
      if (p1Wins > p2Wins) {
        winnerName = m.player1;
      } else if (p2Wins > p1Wins) {
        winnerName = m.player2;
      }
      return {
        ...m,
        subGames: newSubGames,
        result: finalResult,
        winnerName
      };
    });
  }
  
  //
  // 10) Calculate Knockout From Group Matches (even/odd logic)
  //
  export function calculateKnockoutFromMatches(allMatches, groupAssignments, numberOfGroups) {
    // 1. Group all matches by groupName
    const groupMatchesObj = {};
    allMatches.forEach(match => {
      if (!groupMatchesObj[match.groupName]) {
        groupMatchesObj[match.groupName] = [];
      }
      groupMatchesObj[match.groupName].push(match);
    });
  
    // 2. Compute winners and runner-ups
    const groupWinners = [];
    const groupRunnerUps = [];
    const sortedGroupNames = Object.keys(groupAssignments).sort();
    sortedGroupNames.forEach(groupName => {
      const standings = computeGroupStats(groupMatchesObj[groupName] || []);
      if (standings[0]) groupWinners.push({ group: groupName, name: standings[0].name });
      if (standings[1]) groupRunnerUps.push({ group: groupName, name: standings[1].name });
    });
  
    // If even number of groups => new logic for pairing all winners + runner-ups
    if (numberOfGroups % 2 === 0) {
      // Example: For 4 groups => 4 winners + 4 runner-ups = 8 teams
      // We'll pair them in a bracket:
      // QF-1: Winner of Group 1 vs Runner-up of Group 2
      // QF-2: Winner of Group 2 vs Runner-up of Group 1
      // QF-3: Winner of Group 3 vs Runner-up of Group 4
      // QF-4: Winner of Group 4 vs Runner-up of Group 3
      
      // Sort the winners and runnerUps by group name so they're in G1, G2, G3, G4 order
      const sortedWinners = groupWinners.sort((a, b) => a.group.localeCompare(b.group));
      const sortedRunners = groupRunnerUps.sort((a, b) => a.group.localeCompare(b.group));
  
      // Build the quarterfinal matches:
      const qfMatches = [];
      if (sortedWinners.length === sortedRunners.length) {
        // e.g. 4 winners + 4 runner-ups
        // QF-1
        if (sortedWinners[0] && sortedRunners[1]) {
          qfMatches.push({
            id: "QF-1",
            player1: sortedWinners[0].name,
            player2: sortedRunners[1].name,
            result: "",
            subGames: [
              { winner: "", enterName: "" },
              { winner: "", enterName: "" },
              { winner: "", enterName: "" }
            ],
            isKnockout: true
          });
        }
        // QF-2
        if (sortedWinners[1] && sortedRunners[0]) {
          qfMatches.push({
            id: "QF-2",
            player1: sortedWinners[1].name,
            player2: sortedRunners[0].name,
            result: "",
            subGames: [
              { winner: "", enterName: "" },
              { winner: "", enterName: "" },
              { winner: "", enterName: "" }
            ],
            isKnockout: true
          });
        }
        // QF-3
        if (sortedWinners[2] && sortedRunners[3]) {
          qfMatches.push({
            id: "QF-3",
            player1: sortedWinners[2].name,
            player2: sortedRunners[3].name,
            result: "",
            subGames: [
              { winner: "", enterName: "" },
              { winner: "", enterName: "" },
              { winner: "", enterName: "" }
            ],
            isKnockout: true
          });
        }
        // QF-4
        if (sortedWinners[3] && sortedRunners[2]) {
          qfMatches.push({
            id: "QF-4",
            player1: sortedWinners[3].name,
            player2: sortedRunners[2].name,
            result: "",
            subGames: [
              { winner: "", enterName: "" },
              { winner: "", enterName: "" },
              { winner: "", enterName: "" }
            ],
            isKnockout: true
          });
        }
      }
  
      // If you have more than 4 groups, you can adapt the pairing logic above.
      const knockoutRounds = generateKnockoutRounds(qfMatches);
  
      // Everyone qualifies => 8 total (for 4 groups)
      const qualifiedPlayersList = [...groupWinners, ...groupRunnerUps].map(p => p.name);
  
      return {
        knockoutRounds,
        qualifiedPlayersList
      };
  
    } else {
      // ODD GROUPS => keep your existing logic
      // (only as many runner-ups as needed to reach 8 total)
      const sortedRunners = [...groupRunnerUps].sort((a, b) =>
        b.w - a.w || a.l - b.l || a.name.localeCompare(b.name)
      );
      const maxRunnersNeeded = 8 - groupWinners.length;
      const topRunners = sortedRunners.slice(0, maxRunnersNeeded);
      const allQualified = [...groupWinners, ...topRunners];
  
      // Build QFs the old way
      const matches = [];
      const used = new Set();
  
      // Pair each winner with a runner-up from a different group if possible
      for (let i = 0; i < groupWinners.length && matches.length < 4; i++) {
        const winner = groupWinners[i];
        const runner = topRunners.find(r => !used.has(r.name) && r.group !== winner.group);
        if (runner) {
          used.add(runner.name);
          matches.push({
            id: `QF-${matches.length + 1}`,
            player1: winner.name,
            player2: runner.name,
            result: '',
            subGames: [
              { winner: '', enterName: '' },
              { winner: '', enterName: '' },
              { winner: '', enterName: '' }
            ],
            isKnockout: true
          });
        }
      }
      // Leftover qualified
      const remainingQualified = allQualified.map(p => p.name).filter(name =>
        !matches.some(m => m.player1 === name || m.player2 === name)
      );
      while (remainingQualified.length >= 2 && matches.length < 4) {
        const player1 = remainingQualified.shift();
        const player2 = remainingQualified.shift();
        matches.push({
          id: `QF-${matches.length + 1}`,
          player1,
          player2,
          result: '',
          subGames: [
            { winner: '', enterName: '' },
            { winner: '', enterName: '' },
            { winner: '', enterName: '' }
          ],
          isKnockout: true
        });
      }
      const knockoutRounds = generateKnockoutRounds(matches);
      return {
        knockoutRounds,
        qualifiedPlayersList: allQualified.map(p => p.name)
      };
    }
  }
  
  
  //
  // 11) Generate First Knockout Stage from Qualified Players (Even logic)
  //
  export function generateFirstKnockoutFromQualifiedEven(qualifiedPlayersList) {
    if (qualifiedPlayersList.length < 8) {
      console.warn("Even Logic: Not enough teams for a full knockout. Expected 8 teams.");
      return [];
    }
    const quarterfinalMatches = [
      {
        id: "QF-1",
        player1: qualifiedPlayersList[0],
        player2: qualifiedPlayersList[7],
        result: "",
        subGames: [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" }
        ],
        isKnockout: true
      },
      {
        id: "QF-2",
        player1: qualifiedPlayersList[1],
        player2: qualifiedPlayersList[6],
        result: "",
        subGames: [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" }
        ],
        isKnockout: true
      },
      {
        id: "QF-3",
        player1: qualifiedPlayersList[2],
        player2: qualifiedPlayersList[5],
        result: "",
        subGames: [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" }
        ],
        isKnockout: true
      },
      {
        id: "QF-4",
        player1: qualifiedPlayersList[3],
        player2: qualifiedPlayersList[4],
        result: "",
        subGames: [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" }
        ],
        isKnockout: true
      }
    ];
    return generateKnockoutRounds(quarterfinalMatches);
  }
  
  //
  // 12) Generate First Knockout Stage from Qualified Players (Odd logic)
  //    For odd groups, pair teams sequentially (1 vs 2, 3 vs 4, etc.).
  //
  export function generateFirstKnockoutFromQualifiedOdd(qualifiedPlayersList) {
    if (qualifiedPlayersList.length < 8) {
      console.warn("Odd Logic: Not enough teams for a full knockout. Expected 8 teams.");
      return [];
    }
    const quarterfinalMatches = [];
    for (let i = 0; i < 8; i += 2) {
      quarterfinalMatches.push({
        id: `QF-${(i / 2) + 1}`,
        player1: qualifiedPlayersList[i],
        player2: qualifiedPlayersList[i + 1],
        result: "",
        subGames: [
          { winner: "", enterName: "" },
          { winner: "", enterName: "" },
          { winner: "", enterName: "" }
        ],
        isKnockout: true
      });
    }
    return generateKnockoutRounds(quarterfinalMatches);
  }
  
  //
  // 13) Wrapper to choose between Even and Odd Knockout Generation
  //
  export function generateFirstKnockoutFromQualifiedWrapper(qualifiedPlayersList, numberOfGroups) {
    if (numberOfGroups % 2 === 0) {
      return generateFirstKnockoutFromQualifiedEven(qualifiedPlayersList);
    } else {
      return generateFirstKnockoutFromQualifiedOdd(qualifiedPlayersList);
    }
  }
  
  //
  // 14) Compute Qualified Players List for Odd Groups
  //     - All group winners qualify automatically.
  //     - Then, among runner-ups, add only as many as needed to reach a total of 8 teams.
  //
  export function computeQualifiedPlayersListOdd(allMatches, groupAssignments) {
    const winners = [];
    const runnerUps = [];
    const sortedGroupNames = Object.keys(groupAssignments).sort();
    sortedGroupNames.forEach(groupName => {
      const matches = allMatches.filter(m => m.groupName === groupName);
      const standings = computeGroupStats(matches);
      if (standings[0]) winners.push(standings[0].name);
      if (standings[1]) runnerUps.push(standings[1].name);
    });
    // Winners have priority.
    if (winners.length >= 8) {
      winners.sort((a, b) => a.localeCompare(b));
      return winners.slice(0, 8);
    } else {
      runnerUps.sort((a, b) => a.localeCompare(b));
      const needed = 8 - winners.length;
      return winners.concat(runnerUps.slice(0, needed));
    }
  }
  
  
  
  
  
  