import React, { useState } from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { doc, deleteDoc } from "firebase/firestore";
import { database } from "../componenets/firebase";
import MyGamesTopBar from "./utils/MyGamesTopBar";
import MyGameCreate from "./utils/MyGameCreate";
import MyGameItemCard from "./utils/MyGameItemCard";
import MyGameSettingsDialog from "./utils/MyGameCogSettings";
import { useFolderStructure } from "./utils/useFolderStructure";

const minCardWidth = 200;

export default function MyGames2({ route, navigation }) {
  // If a folderId is provided via route.params, we are in a subfolder.
  // Otherwise, we're at the root level.
  const folderId = route?.params?.folderId || null;
  const { width: screenWidth } = useWindowDimensions();

  const [filter, setFilter] = useState("all");
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  // Use the consolidated hook; pass folderId (null for root level)
  const games = useFolderStructure(filter, folderId);

  // Layout calculations for card width
  const numColumns = Math.max(1, Math.floor(screenWidth / minCardWidth));
  const cardMargin = 10;
  const totalHorizontalPadding = 20;
  const totalMarginPerCard = cardMargin * 2;
  const cardWidthCalc =
    (screenWidth - totalHorizontalPadding) / numColumns - totalMarginPerCard;

  function showCreateDialog() {
    setCreateDialogVisible(true);
  }

  function hideCreateDialog() {
    setCreateDialogVisible(false);
  }

  function handleSettings(item) {
    setSelectedGame(item);
    setSettingsDialogVisible(true);
  }

  function closeSettingsDialog() {
    setSettingsDialogVisible(false);
    setSelectedGame(null);
  }

  function handleSettingsSaved() {
    console.log("Settings updated for docId:", selectedGame?.docId);
  }

  async function handleDelete(item) {
    try {
      await deleteDoc(doc(database, "games", item.docId));
      console.log("Deleted doc:", item.docId);
    } catch (err) {
      console.error("Error deleting doc:", err);
    }
  }

  // For folders, navigate deeper using push (so the user can go back)
  function handleEnter(item) {
    if (item.type === "Folder") {
      navigation.push("MyGames2", { folderId: item.docId });
    } else {
      console.log("Enter-match", item.docId);
    }
  }

  // Navigate to the structure screen with the current games data
  function goToStructureScreen() {
    navigation.navigate("MyGameStructure", { games });
  }

  return (
    <PaperProvider>
      <MyGamesTopBar
        filter={filter}
        setFilter={setFilter}
        onCreateGame={showCreateDialog}
        onStructure={goToStructureScreen}
      />

      {/* The Create dialog uses the current folderId (or null at root level) */}
      <MyGameCreate
        visible={createDialogVisible}
        onDismiss={hideCreateDialog}
        parentId={folderId}
      />

      <MyGameSettingsDialog
        visible={settingsDialogVisible}
        onDismiss={closeSettingsDialog}
        gameItem={selectedGame}
        onSaved={handleSettingsSaved}
      />

      <ScrollView contentContainerStyle={styles.container}>
        {games.map((item, index) => {
          const widthStyle = {
            width: Math.max(minCardWidth, cardWidthCalc),
            margin: cardMargin,
          };

          return (
            <View
              key={item.docId || index}
              style={[styles.cardWrapper, widthStyle]}
            >
              <MyGameItemCard
                item={item}
                onDelete={handleDelete}
                onSettings={handleSettings}
                onEnter={handleEnter}
              />
            </View>
          );
        })}
      </ScrollView>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  cardWrapper: {},
});
