// Almost - free winner from final round can't be chosen
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  Alert,
  Platform,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Card, Button } from "react-native-paper";
import {
  collection,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { database } from "../componenets/firebase";

const KnockOut = ({ route, navigation }) => {
  const [mainGameId, setmainGameId] = useState(route.params.mainGameId);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [GameDate, setGameDate] = useState(route.params.GameDate);
  const [GameIndex, setGameIndex] = useState(route.params.GameIndex);
  const [showEdit, setShowEdit] = useState(true); // This is our toggle state

  // Reference to the main game
  const mainGameRef = doc(database, "KnockOut", mainGameId);
  // Reference to the knockout game subcollection under the main game
  const knockoutGamesRef = collection(mainGameRef, "knockoutGames");
  // Reference to the rounds subcollection under the main game
  const roundsRef = collection(mainGameRef, "rounds");

  // Save main game and associated rounds to Firebase
  const saveToFirebase = async (gameData, roundsData) => {
    try {
      const gameDocRef = doc(knockoutGamesRef, TheGameId);
      // First, delete the existing document
      await deleteDoc(gameDocRef);

      // Now, set the new document
      await setDoc(gameDocRef, {
        ...gameData,
        rounds: roundsData,
      });
    } catch (e) {
      // Handle error as needed
    }
  };

  // Get data from firebase version 9
  useEffect(() => {
    console.log("isGameRunning changed to:", isGameRunning);

    const gameRef = doc(
      database,
      "KnockOut",
      mainGameId,
      "knockoutGames",
      TheGameId
    );

    // Set up a real-time listener using onSnapshot
    const unsubscribe = onSnapshot(gameRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();

        if (data && data.rounds && data.rounds.length > 0) {
          setIsGameRunning(true); // Set the game as running

          // Extract matchups and log each roundData for debugging
          const fetchedRounds = data.rounds.map((roundData) => {
            return roundData && roundData.matchups ? roundData.matchups : [];
          });
          setRounds(fetchedRounds);

          // Extract team names and set them
          const teamNamesFromDB = [];
          if (data.rounds.length > 0 && data.rounds[0].matchups) {
            const firstRoundMatchups = data.rounds[0].matchups;
            for (const matchup of firstRoundMatchups) {
              teamNamesFromDB.push(matchup.team1, matchup.team2);
            }
          }
          setTeamNames(teamNamesFromDB);
        } else {
          setIsGameRunning(false);
        }
      } else {
        setIsGameRunning(false);
      }
    });
    return () => unsubscribe();
  }, [database, mainGameId, TheGameId]);

  const TeamInput = ({ teamNames, setTeamNames }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedName, setEditedName] = useState("");
    const [inputError, setInputError] = useState(null);
    const handleEditPress = (index) => {
      setEditingIndex(index);
      setEditedName(teamNames[index] || "");
    };

    // Edit name
    const handleSavePress = async (index) => {
      if (editingIndex !== null) {
        // Check for duplicates
        if (
          teamNames.some(
            (name, idx) => name === editedName && idx !== editingIndex
          )
        ) {
          setInputError("Name already exists");
          return;
        } else {
          setInputError("");
        }

        const newTeamNames = [...teamNames];
        newTeamNames[editingIndex] = editedName;
        setTeamNames(newTeamNames);
        // Now, update Firestore
        const gameRef = doc(
          database,
          "KnockOut",
          mainGameId,
          "knockoutGames",
          TheGameId
        );
        const currentRoundData = (await getDoc(gameRef)).data().rounds;

        for (let round of currentRoundData) {
          for (let matchup of round.matchups) {
            if (matchup.team1 === teamNames[editingIndex]) {
              matchup.team1 = editedName;
            }
            if (matchup.team2 === teamNames[editingIndex]) {
              matchup.team2 = editedName;
            }
            if (matchup.winner === teamNames[editingIndex]) {
              matchup.winner = editedName;
            }
          }
        }

        await updateDoc(gameRef, { rounds: currentRoundData });
        setEditingIndex(null);
        setEditedName("");
      }
    };

    const handleGenerateNewGame = () => {
      const alertMessage = "Previous game will be deleted. Are you sure?";
      const proceedWithGameGeneration = () => {
        generateKnockoutTableWithNames();
        setShowEdit(false);
      };

      if (Platform.OS === "web") {
        if (window.confirm(alertMessage)) {
          proceedWithGameGeneration();
        }
      } else {
        Alert.alert("Warning", alertMessage, [
          { text: "Cancel", style: "cancel" },
          { text: "OK", onPress: proceedWithGameGeneration },
        ]);
      }
    };
    return (
      <>
        <Button
          mode="contained"
          onPress={() => setTeamNames([...teamNames, ""])}
          style={styles.addButton}
        >
          Add Another Team
        </Button>

        <Button
          mode="contained"
          onPress={handleGenerateNewGame}
          style={styles.addButton}
        >
          Generate New Game
        </Button>

        {teamNames.map((name, index) => (
          <View key={index} style={styles.teamContainer}>
            <Text
              style={
                editingIndex === index
                  ? styles.teamNumber
                  : styles.teamNameStatic
              }
            >
              #{index + 1}{" "}
            </Text>

            {editingIndex === index ? (
              <>
                <TextInput
                  style={styles.input}
                  placeholder={`Team ${index + 1} Name`}
                  value={editedName}
                  onChangeText={setEditedName}
                  multiline={true}
                />
                {inputError && (
                  <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{inputError}</Text>
                  </View>
                )}
                <Button
                  style={styles.saveButton}
                  mode="outlined"
                  onPress={() => handleSavePress(index)}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Text style={styles.teamNameStatic}>{name}</Text>
                <Button
                  style={styles.editButton}
                  mode="outlined"
                  onPress={() => handleEditPress(index)}
                >
                  Edit
                </Button>
              </>
            )}
          </View>
        ))}
      </>
    );
  };

  const [teamCount, setTeamCount] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [teamsWithFreePass, setTeamsWithFreePass] = useState([]);
  const [roundsLocked, setRoundsLocked] = useState(false);
  const lockButtonLabel = roundsLocked ? "Unlock Shuffle" : "Lock Teams";
  const lockRounds = () => {
    setRoundsLocked(true);
  };
  const [teamNames, setTeamNames] = useState([]);
  const [hasTableGenerated, setHasTableGenerated] = useState(false);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const shuffleFirstRound = () => {
    if (roundsLocked) {
      return;
    }

    if (rounds.length) {
      const shuffledFirstRound = generateMatchups(
        shuffleArray(
          rounds[0]
            .map((matchup) => matchup.team1)
            .concat(rounds[0].map((matchup) => matchup.team2).filter(Boolean))
        )
      );
      setRounds([shuffledFirstRound]);
    }
  };

  const toggleRoundsLock = () => {
    setRoundsLocked((prevLocked) => !prevLocked);
  };

  const getRoundName = (matchesLength, roundIndex) => {
    if (
      roundIndex === rounds.length - 1 &&
      matchesLength === 1 &&
      rounds[roundIndex][0].team2 === null
    ) {
      return "Champion";
    }
    if (matchesLength === 1) {
      return "Finals";
    }
    switch (matchesLength * 2) {
      case 16:
        return "Preliminary";
      case 8:
        return "Quarter-finals";
      case 4:
        return "Semi-finals";
      default:
        return `Round of ${matchesLength * 2}`;
    }
  };

  const generateKnockoutTableWithNames = () => {
    console.log("Generate Knockout Table triggered");

    const validTeamNames = teamNames.filter(
      (name) => name && typeof name === "string" && name.trim() !== ""
    );

    if (validTeamNames.length < 2) {
      Alert.alert("Error", "You need at least 2 teams for a match.");
    } else {
      generateKnockoutTable(shuffleArray(validTeamNames));
      setHasTableGenerated(true);
    }
  };

  const startNewGame = () => {
    setTeamCount(null);
    setRounds([]);
    setTeamsWithFreePass([]);
  };

  const generateMatchups = (teams) => {
    const matchups = [];
    teams = teams.sort((a, b) => {
      if (teamsWithFreePass.includes(a) && !teamsWithFreePass.includes(b))
        return 1;
      if (!teamsWithFreePass.includes(a) && teamsWithFreePass.includes(b))
        return -1;
      return 0;
    });
    while (teams.length) {
      let team1 = teams.pop();
      let team2 = teams.pop();

      if (!team2 && teamsWithFreePass.includes(team1)) {
        for (let i = 0; i < teams.length; i++) {
          if (!teamsWithFreePass.includes(teams[i])) {
            team2 = teams[i];
            teams.splice(i, 1);
            break;
          }
        }
      }
      if (!team2) {
        setTeamsWithFreePass([...teamsWithFreePass, team1]);
      }

      matchups.push({
        team1: team1,
        team2: team2 || null,
        winner: null,
      });
    }
    return matchups;
  };

  const generateKnockoutTable = (initialTeams) => {
    const matchups = generateMatchups(shuffleArray(initialTeams));
    setRounds([matchups]);
  };

  const handleWinner = async (roundIndex, matchupIndex, winner) => {
    console.log("Handling winner:", roundIndex, matchupIndex, winner);
    console.log("Rendering KnockoutTable with rounds:", rounds);

    const newRounds = [...rounds];
    const currentMatchup = newRounds[roundIndex][matchupIndex];

    if (currentMatchup.team2 === null) {
      currentMatchup.freePass = "Free Pass";
    } else {
      currentMatchup.winner = winner;
    }

    newRounds.splice(roundIndex + 1);

    const currentRound = newRounds[roundIndex];

    if (getRoundName(currentRound.length, roundIndex) === "Finals" && winner) {
      newRounds.push([{ team1: winner, team2: null, winner: winner }]);
    } else if (
      currentRound.every((matchup) => matchup.winner || !matchup.team2)
    ) {
      const nextRoundTeams = currentRound.map(
        (matchup) => matchup.winner || matchup.team1
      );
      const nextRound = generateMatchups(nextRoundTeams);
      newRounds.push(nextRound);
    }
    setRounds(newRounds);

    const gameRef = doc(
      database,
      "KnockOut",
      mainGameId,
      "knockoutGames",
      TheGameId
    );
    try {
      const transformedRounds = newRounds.map((round, index) => {
        round.forEach((matchup) => {
          if (!matchup.team2 && !matchup.freePass) {
            matchup.freePass = "Free Pass";
          }
        });

        return {
          roundName: getRoundName(round.length, index),
          matchups: round,
        };
      });

      await setDoc(gameRef, { rounds: transformedRounds }, { merge: true });
    } catch (error) {
      // Handle error as needed
    }
  };

  const [isGameRunning, setIsGameRunning] = useState(false);
  const [buttonText, setButtonText] = useState("Generate Random Knockout Table");

  const handleButtonPress = () => {
    if (!isGameRunning && showEdit) {
      generateKnockoutTableWithNames();
      setIsGameRunning(true);
      setShowEdit(false);
    } else {
      setShowEdit(!showEdit);
    }
  };

  useEffect(() => {
    if (isGameRunning) {
      setButtonText("Show Knockout Table");
    } else {
      setButtonText("Generate Random Knockout Table");
    }
  }, [isGameRunning]);

  const ROUND_WIDTH = 250;

  const flatListRef = useRef(null);

  useEffect(() => {
    if (flatListRef.current) {
      flatListRef.current.scrollToOffset({ offset: 100, animated: true });
    }
  }, []);

  const renderRound = ({ item: round, index: roundIndex }) => {
    const roundName = getRoundName(round.length, roundIndex);
    const isFinalOrChampion = roundName === "Finals" || roundName === "Champion";
  
    return (
      <View style={styles.round}>
        <Text style={styles.roundName}>{roundName}</Text>
        <FlatList
          data={round}
          keyExtractor={(matchup, matchupIndex) => "matchup-" + matchupIndex}
          renderItem={({ item: matchup, index: matchupIndex }) => (
            <View style={styles.cardLikeContainer}>
              <TouchableOpacity
                disabled={
                  rounds.length - 1 === roundIndex &&
                  round.length === 1 &&
                  !!matchup.winner
                }
                style={[
                  styles.teamContainer,
                  isFinalOrChampion && styles.finalBox,
                  matchup.winner === matchup.team1 && styles.selectedTeamBackground,
                  matchup.winner === matchup.team2 && styles.losingTeamBackground,
                  !matchup.team2 && styles.freePassWinnerBackground,
                ]}
                onPress={() =>
                  handleWinner(roundIndex, matchupIndex, matchup.team1)
                }
              >
                <Text
                  style={[
                    styles.team,
                    isFinalOrChampion && styles.finalText,
                    matchup.winner === matchup.team1 && styles.winningTeam,
                    matchup.winner === matchup.team2 && styles.losingTeam,
                    !matchup.team2 && styles.freePassWinnerTextColor,
                  ]}
                >
                  {matchup.team1}
                </Text>
              </TouchableOpacity>
              {matchup.team2 ? (
                <>
                  <Text style={styles.vs}>vs</Text>
                  <TouchableOpacity
                    style={[
                      styles.teamContainer,
                      isFinalOrChampion && styles.finalBox,
                      matchup.winner === matchup.team2 && styles.selectedTeamBackground,
                      matchup.winner === matchup.team1 && styles.losingTeamBackground,
                    ]}
                    onPress={() =>
                      handleWinner(roundIndex, matchupIndex, matchup.team2)
                    }
                  >
                    <Text
                      style={[
                        styles.team,
                        isFinalOrChampion && styles.finalText,
                        matchup.winner === matchup.team2 && styles.winningTeam,
                        matchup.winner === matchup.team1 && styles.losingTeam,
                      ]}
                    >
                      {matchup.team2}
                    </Text>
                  </TouchableOpacity>
                </>
              ) : rounds.length - 1 === roundIndex && round.length === 1 ? (
                <View style={styles.finalBox}>
                  <Text style={[styles.champion, isFinalOrChampion && styles.finalText]}>
                    Champion!
                  </Text>
                </View>
              ) : (
                <Text style={styles.freePass}>Free Pass</Text>
              )}
            </View>
          )}
        />
      </View>
    );
  };
  
  return (
    <>
      <Button
        mode="contained"
        onPress={() => setShowEdit(!showEdit)}
        style={[
          styles.addButton,
          isGameRunning && styles.greenButton,
        ]}
      >
        {showEdit ? buttonText : "Game Setup"}
      </Button>

      {showEdit ? (
        <ScrollView>
          <TeamInput teamNames={teamNames} setTeamNames={setTeamNames} />
        </ScrollView>
      ) : (
        <View style={styles.knockoutTable}>
          <View style={styles.buttonContainer}>
            <Button
              onClick={shuffleFirstRound}
              disabled={roundsLocked}
              labelStyle={{ color: "white" }}
              style={{
                ...styles.Shufflebutton,
                ...(roundsLocked ? styles.disabledButton : styles.activeButton),
              }}
            >
              Shuffle Teams
            </Button>

            <Button
              mode="contained"
              style={[
                styles.Shufflebutton,
                roundsLocked ? styles.lockButton : null,
              ]}
              onPress={toggleRoundsLock}
            >
              {lockButtonLabel}
            </Button>

            <Button
              mode="contained"
              style={[
                styles.Shufflebutton,
                { backgroundColor: "#A8D5BA" },
              ]}
              onPress={() => navigation.navigate("KnockOutResults")}
            >
              <Text style={styles.buttonText}>Instructions</Text>
            </Button>
          </View>

          <FlatList
            ref={flatListRef}
            data={rounds}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            keyExtractor={(round, roundIndex) => "round-" + roundIndex}
            renderItem={renderRound}
          />
        </View>
      )}
    </>
  );
};

export default KnockOut;

const styles = StyleSheet.create({
  knockoutTable: {
    flex: 1,
    backgroundColor: "#fff",
  },
  round: {
    padding: 10,
    margin: 5,
    marginRight: 50,
    borderColor: "gray",
    alignItems: "center",
  },
  roundName: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  cardLikeContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    padding: 10,
    marginHorizontal: 10, 
    borderWidth: 1,
    borderColor: "gray",
    marginBottom: 30,
    backgroundColor: "#f7f3f9",
    borderRadius: 12,
    width: "90%",
  },
  teamContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Allow text to wrap if needed
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderRadius: 5,
    backgroundColor: "#f5f5f5",
  },
  // New style to display team names when not editing
  teamNameStatic: {
    flex: 1,
    fontSize: 12,
    padding: 5,
    flexWrap: "wrap",
  },
  team: {
    fontSize: 12,
  },
  selectedTeamBackground: {
    backgroundColor: "#2196f3",
  },
  winningTeam: {
    fontWeight: "bold",
    color: "white",
    paddingHorizontal: 5,
    borderRadius: 5,
  },
  vs: {
    marginVertical: 10,
  },
  champion: {
    color: "green",
    fontSize: 12,
  },
  freePass: {
    fontStyle: "italic",
    color: "orange",
    fontSize: 12,
  },
  freePassWinnerTextColor: {
    color: "white",
    fontWeight: "bold",
  },
  freePassWinnerBackground: {
    backgroundColor: "#2196f3",
  },
  losingTeamBackground: {
    // You can add a background color if needed
  },
  losingTeam: {
    color: "#A3A3A3",
  },
  input: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 20,
    padding: 10,
    minHeight: 40, // Use minHeight so that the box grows when text wraps
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    textAlign: "center",
  },
  errorContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  errorText: {
    color: "red",
    alignSelf: "stretch",
    marginLeft: 10,
    marginTop: 5,
  },
  addButton: {
    backgroundColor: "#6750a4",
    margin: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    padding: 10,
  },
  Shufflebutton: {
    flex: 1,
    margin: 5,
  },
  activeButton: {
    backgroundColor: "#2196F3",
    color: "#FFFFFF",
    padding: 5,
    borderRadius: 25,
  },
  disabledButton: {
    backgroundColor: "#9E9E9E",
    color: "#FFFFFF",
    padding: 5,
    borderRadius: 25,
  },
  lockButton: {
    backgroundColor: "#9E9E9E",
  },
  greenButton: {
    backgroundColor: "green",
  },
});
