


// tournament/StartTournament.js
import React, { useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Platform } from 'react-native';
import { Button, Switch } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getAuth } from 'firebase/auth';
// Import the DartTournament component (using its existing code)
import DartTournament from './groupKnockout/DartTournament';
// Import the back-to-back tournament UI (aliasing TournamentSchedule as BackToBackTournament)
 import { TournamentSchedule as BackToBackTournament } from './back-to-back/back-to-back';

export default function StartTournament() {
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params || {};
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Build tournament parameters object
  const tournamentParams = {
    MainGameId: params.MainGameId || "defaultId",
    gameName: params.gameName || "Unnamed Game",
    roomId: params.roomId || null,
    gameCategory: params.gameCategory || "Unknown",
    gameTitle: params.gameTitle || "Unknown",
    mode: params.mode || "default",
    created: params.created || null,
    isPublic: params.isPublic || false,
    userId: params.userId || null,
    user: currentUser,
    ...params,
  };

  // Two toggles for inline UIs
  const [showDartTournament, setShowDartTournament] = useState(false);
  const [showBackToBackTournament, setShowBackToBackTournament] = useState(false);

  return (
    <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.container}>
      <Text style={styles.title}>Game: {tournamentParams.gameName}</Text>
      
      {/* Display tournament parameters as plain text for testing */}
      <View style={styles.paramsContainer}>
        <Text style={styles.paramsTitle}>Tournament Parameters:</Text>
        {Object.keys(tournamentParams).map((key, index) => (
          <Text key={index} style={styles.paramItem}>
            {key}: {String(tournamentParams[key])}
          </Text>
        ))}
      </View>
      
      <Button
        mode="contained"
        onPress={() => navigation.navigate("TournamentSetup", tournamentParams)}
        style={styles.button}
      >
        Continue to Tournament Setup
      </Button>
      
      {/* Toggle for DartTournament UI */}
      <View style={styles.toggleContainer}>
        <Text style={styles.toggleLabel}>Show Dart Tournament Inline:</Text>
        <Switch value={showDartTournament} onValueChange={setShowDartTournament} />
      </View>
      {showDartTournament && (
        <View style={styles.inlineContainer}>
          <DartTournament />
        </View>
      )}

      {/* Toggle for Back-to-Back Tournament UI */}
      <View style={styles.toggleContainer}>
        <Text style={styles.toggleLabel}>Show Back-to-Back Tournament Inline:</Text>
        <Switch value={showBackToBackTournament} onValueChange={setShowBackToBackTournament} />
      </View>
      {showBackToBackTournament && (
        <View style={styles.inlineContainer}>
          <BackToBackTournament />
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  container: {
    padding: 20,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#004080",
    textAlign: "center",
    marginBottom: 20,
  },
  paramsContainer: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 8,
    marginBottom: 20,
  },
  paramsTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#004080",
    marginBottom: 5,
  },
  paramItem: {
    fontSize: 14,
    color: "#333",
    fontFamily: Platform.OS === 'ios' ? 'Courier' : 'monospace',
  },
  button: {
    width: "80%",
    alignSelf: "center",
    marginBottom: 20,
  },
  toggleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  toggleLabel: {
    fontSize: 16,
    marginRight: 10,
    color: "#004080",
  },
  inlineContainer: {
    marginTop: 20,
    width: "100%",
    // Using the same background color as the container for a seamless look:
    backgroundColor: "#fff",
    padding: 10,
  },
});
