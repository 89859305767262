// getTotalMembersForFolder.js
import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { database } from "../../componenets/firebase";

/**
 * Decide if a match is accessible to this user.
 * Adjust logic to match your rules for private vs. public access.
 */
function isMatchAccessible(docData, currentUserId) {
  // If doc is public:
  if (docData.isPublic === true) return true;
  // If user is the owner:
  if (docData.userId === currentUserId) return true;
  // If user is in memberOfGame array:
  if (Array.isArray(docData.memberOfGame)) {
    const found = docData.memberOfGame.some((p) => p.uid === currentUserId);
    if (found) return true;
  }
  // Otherwise, no access
  return false;
}

/**
 * countTotalMembers
 *
 * Recursively sets up real‑time listeners on the folder hierarchy starting at folderId,
 * and sums up the numeric `members` field from all *accessible* descendant matches.
 * Folders contribute 0 themselves, but we recurse into them to find matches.
 *
 * @param {string} folderId - The docId of the top-level folder in "MainGame".
 * @param {string} currentUserId - The user requesting the count.
 * @returns {number} total - The live total of all *accessible* descendant matches' `members`.
 */
export function countTotalMembers(folderId, currentUserId) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log("countTotalMembers hook started with folderId:", folderId);
    if (!folderId) {
      setTotal(0);
      return;
    }

    // Keep unsubscribes
    const unsubscribes = [];
    // docId -> numeric membership
    const membersMap = {};

    const recalcTotal = () => {
      const newTotal = Object.values(membersMap).reduce((acc, count) => acc + count, 0);
      console.log("Recalculating total members:", newTotal, "membersMap:", membersMap);
      setTotal(newTotal);
    };

    // Subscribe to the top-level doc
    const folderRef = doc(database, "MainGame", folderId);
    const unsubFolder = onSnapshot(folderRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.type === "Match") {
          // If top-level doc is a match
          if (isMatchAccessible(data, currentUserId)) {
            const memberCount = typeof data.members === "number" ? data.members : 0;
            membersMap[folderId] = memberCount;
            console.log(`Match ${folderId} => members =`, memberCount);
          } else {
            // Not accessible => 0
            membersMap[folderId] = 0;
          }
        } else {
          // If folder or something else => 0
          membersMap[folderId] = 0;
          console.log(`Folder ${folderId} => 0 by itself`);
        }
      } else {
        console.log(`Doc ${folderId} does not exist in MainGame`);
        delete membersMap[folderId];
      }
      recalcTotal();
    });
    unsubscribes.push(unsubFolder);

    // Recursively subscribe to children
    const subscribeToFolder = (currentId) => {
      const q = query(
        collection(database, "MainGame"),
        where("parentId", "==", currentId)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const childDoc = change.doc;
          const childData = childDoc.data();
          const childId = childDoc.id;

          if (change.type === "added" || change.type === "modified") {
            if (childData.type === "Match") {
              // If it's a match, check accessibility
              if (isMatchAccessible(childData, currentUserId)) {
                const memberCount =
                  typeof childData.members === "number" ? childData.members : 0;
                membersMap[childId] = memberCount;
                console.log(
                  `Match ${childId} => members = ${memberCount} (accessible)`
                );
              } else {
                // Not accessible => 0
                membersMap[childId] = 0;
                console.log(`Match ${childId} => not accessible => 0`);
              }
            } else if (childData.type === "Folder") {
              // Folders => 0, then recurse
              membersMap[childId] = 0;
              console.log(`Folder ${childId} => 0, subscribing to children`);
              subscribeToFolder(childId);
            }
          } else if (change.type === "removed") {
            console.log(`Doc ${childId} removed`);
            delete membersMap[childId];
          }
          recalcTotal();
        });
      });
      unsubscribes.push(unsubscribe);
    };

    // Start
    subscribeToFolder(folderId);

    return () => {
      unsubscribes.forEach((unsub) => unsub());
    };
  }, [folderId, currentUserId]);

  return total;
}
