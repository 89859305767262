// hooks/useFolderStructure.js

import { useState, useEffect } from 'react';
import { 
  collection, query, where, onSnapshot, getDocs 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { database } from '../../componenets/firebase';

// Shared sorting function: folders come before matches and then sort alphabetically
export function folderMatchSorter(a, b) {
  if (a.type === 'Folder' && b.type === 'Match') return -1;
  if (a.type === 'Match' && b.type === 'Folder') return 1;
  const nameA = (a.name || a.docId || '').toLowerCase();
  const nameB = (b.name || b.docId || '').toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

// BFS to fetch all descendants of a folder
export async function fetchAllDescendants(folderId) {
  const stack = [folderId];
  const allItems = [];
  while (stack.length > 0) {
    const currentId = stack.pop();
    const q = query(
      collection(database, 'games'),
      where('parentId', '==', currentId)
    );
    const snapshot = await getDocs(q);
    snapshot.docs.forEach(docSnap => {
      const data = { docId: docSnap.id, ...docSnap.data() };
      allItems.push(data);
      if (data.type === 'Folder') {
        stack.push(data.docId);
      }
    });
  }
  return allItems;
}

// Compute folder members by summing descendant matches
export function computeFolderMembers(allItems) {
  const itemMap = new Map();
  allItems.forEach(item => {
    if (item.type === 'Folder' && typeof item.members !== 'number') {
      item.members = 0;
    }
    itemMap.set(item.docId, item);
  });
  allItems.forEach(match => {
    if (match.type === 'Match' && typeof match.members === 'number') {
      let pId = match.parentId;
      while (pId) {
        const folder = itemMap.get(pId);
        if (!folder) break;
        if (typeof folder.members !== 'number') folder.members = 0;
        folder.members += match.members;
        pId = folder.parentId;
      }
    }
  });
}

// Visibility logic: mark ancestors visible based on user ownership or public status
export function markAncestorsVisible(baseItems, currentUserUid) {
  const parentMap = new Map();
  baseItems.forEach(item => {
    parentMap.set(item.docId, item.parentId);
  });
  const visibleMap = new Map();
  baseItems.forEach(item => {
    visibleMap.set(item.docId, false);
  });
  baseItems.forEach(item => {
    if (item.type === 'Folder' && (item.userId === currentUserUid || item.isPublic)) {
      visibleMap.set(item.docId, true);
    }
  });
  baseItems.forEach(item => {
    if (item.type !== 'Folder' && item.isPublic) {
      visibleMap.set(item.docId, true);
      let pId = parentMap.get(item.docId);
      while (pId) {
        visibleMap.set(pId, true);
        pId = parentMap.get(pId);
      }
    }
  });
  return baseItems.filter(item => {
    if (item.type !== 'Folder') return true;
    return visibleMap.get(item.docId);
  });
}

// Filter out private matches not owned by the user
export function finalFilterForMatches(items, currentUserUid) {
  return items.filter(item => {
    if (item.type === 'Folder') return true;
    return item.isPublic || item.userId === currentUserUid;
  });
}

// Apply all visibility logic to the immediate items of a folder
export async function applyVisibilityLogic(immediateItems, currentUserUid, folderId) {
  const descendants = await fetchAllDescendants(folderId);
  const combined = [...immediateItems, ...descendants];
  computeFolderMembers(combined);
  const filtered = markAncestorsVisible(combined, currentUserUid);
  const filteredSet = new Set(filtered.map(i => i.docId));
  let finalItems = immediateItems.filter(i => filteredSet.has(i.docId));
  finalItems = finalFilterForMatches(finalItems, currentUserUid);
  return finalItems;
}

// Main custom hook that consolidates folder structure logic
export function useFolderStructure(filter, parentId) {
  const [games, setGames] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!currentUser) {
      setGames([]);
      return;
    }

    let unsub, unsubPublic, unsubPrivate;
    let publicItems = [];
    let privateItems = [];

    function updateAll() {
      const combined = [...publicItems, ...privateItems];
      combined.sort(folderMatchSorter);
      setGames(combined);
    }

    if (filter === 'all') {
      if (parentId === null) {
        // Root-level: combine public and private queries
        const qPublic = query(
          collection(database, 'games'),
          where('isPublic', '==', true),
          where('parentId', '==', null)
        );
        unsubPublic = onSnapshot(qPublic, snapshot => {
          publicItems = snapshot.docs.map(docSnap => ({
            docId: docSnap.id,
            ...docSnap.data(),
          }));
          updateAll();
        });

        const qPrivate = query(
          collection(database, 'games'),
          where('userId', '==', currentUser.uid),
          where('isPublic', '==', false),
          where('parentId', '==', null)
        );
        unsubPrivate = onSnapshot(qPrivate, snapshot => {
          privateItems = snapshot.docs.map(docSnap => ({
            docId: docSnap.id,
            ...docSnap.data(),
          }));
          updateAll();
        });
      } else {
        // Subfolder level: apply additional visibility logic
        const q = query(
          collection(database, 'games'),
          where('parentId', '==', parentId)
        );
        unsub = onSnapshot(q, async snapshot => {
          const immediateItems = snapshot.docs.map(docSnap => ({
            docId: docSnap.id,
            ...docSnap.data(),
          }));
          try {
            const finalItems = await applyVisibilityLogic(immediateItems, currentUser.uid, parentId);
            finalItems.sort(folderMatchSorter);
            setGames(finalItems);
          } catch (err) {
            console.error('Error applying visibility logic:', err);
            immediateItems.sort(folderMatchSorter);
            setGames(immediateItems);
          }
        });
      }
    } else if (filter === 'private') {
      const q = query(
        collection(database, 'games'),
        where('userId', '==', currentUser.uid),
        where('isPublic', '==', false),
        where('parentId', '==', parentId)
      );
      unsub = onSnapshot(q, snapshot => {
        const items = snapshot.docs.map(docSnap => ({
          docId: docSnap.id,
          ...docSnap.data(),
        }));
        items.sort(folderMatchSorter);
        setGames(items);
      });
    } else if (filter === 'public') {
      const q = query(
        collection(database, 'games'),
        where('isPublic', '==', true),
        where('parentId', '==', parentId)
      );
      unsub = onSnapshot(q, snapshot => {
        const items = snapshot.docs.map(docSnap => ({
          docId: docSnap.id,
          ...docSnap.data(),
        }));
        items.sort(folderMatchSorter);
        setGames(items);
      });
    }

    return () => {
      if (unsub) unsub();
      if (unsubPublic) unsubPublic();
      if (unsubPrivate) unsubPrivate();
    };
  }, [filter, parentId, currentUser]);

  return games;
}
