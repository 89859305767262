import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Platform,
  Image,
  Pressable,
  Linking,
  ActivityIndicator,
} from "react-native";
import {
  Provider as PaperProvider,
  Portal,
  Dialog,
  Button,
} from "react-native-paper";
import { getAuth } from "firebase/auth";
import { collection, onSnapshot, doc, deleteDoc } from "firebase/firestore";

import { AppContext } from "../screens/AppContext";
import { database } from "../componenets/firebase";

import MyGamesTopBar from "./utils/MyGamesTopBar";
import MyGameCreate from "./utils/MyGameCreate";
import MyGameSettingsDialog from "./utils/MyGameCogSettings";
import GameItemCard from "./GameItemCard";

import { MaterialIcons } from "@expo/vector-icons";
import yourYoutubeImage from "../assets/YoutubeImg.jpg";

const minCardWidth = 200;

// Helper: recursively determine if a folder has any accessible child.
function hasAccessibleChild(folder, allItems, currentUser) {
  const children = allItems.filter((child) => child.parentId === folder.id);
  return children.some((child) => shouldIncludeItem(child, allItems, currentUser));
}

// Helper: determine if an item should be visible for non-admin users.
function shouldIncludeItem(item, allItems, currentUser) {
  if (item.userId === currentUser.uid) return true;

  if (item.type !== "Folder") {
    if (
      Array.isArray(item.memberOfGame) &&
      item.memberOfGame.some((member) => member.uid === currentUser.uid)
    ) {
      return true;
    }
    return item.isPublic === true;
  }

  if (item.isPublic === true) return true;
  return hasAccessibleChild(item, allItems, currentUser);
}

// Helper: top bar filter
function passesTopBarFilter(item, currentUser, topBarFilter) {
  if (topBarFilter === "all") {
    return true;
  }
  if (topBarFilter === "admin") {
    return true;
  }
  if (topBarFilter === "private") {
    return item.isPublic === false;
  }
  if (topBarFilter === "public") {
    return item.isPublic === true;
  }
  return false;
}

// Recursive helper to check if a folder (or any child within it) has any children.
function hasChildRecursively(folder, allItems) {
  const children = allItems.filter((item) => item.parentId === folder.id);
  if (children.length > 0) {
    return true;
  }
  for (const child of children) {
    if (child.type === "Folder" && hasChildRecursively(child, allItems)) {
      return true;
    }
  }
  return false;
}

export default function MyGames({ navigation, route }) {
  // Get folderId from route (if any)
  const folderId = route?.params?.folderId ?? null;

  const { isDemo } = useContext(AppContext);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // State for storing all documents from MainGame collection
  const [allGames, setAllGames] = useState([]);
  const [loading, setLoading] = useState(true);

  // Top bar filter: "all", "private", "public", or "admin"
  const [topBarFilter, setTopBarFilter] = useState("all");

  // For search
  const [searchText, setSearchText] = useState("");

  // For create dialog
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  // For settings (cog) dialog
  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  // For alert dialog
  const [alert, setAlert] = useState({ visible: false, message: "" });

  // For delete confirmation dialog
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Show/hide create dialog
  const showCreateDialog = () => setCreateDialogVisible(true);
  const hideCreateDialog = () => setCreateDialogVisible(false);

  // Show/hide settings dialog
  const openSettingsDialog = (item) => {
    setSelectedGame(item);
    setSettingsDialogVisible(true);
  };
  const closeSettingsDialog = () => {
    setSelectedGame(null);
    setSettingsDialogVisible(false);
  };

  // Delete logic for a game item with recursive dependency check.
  const handleDelete = async (item) => {
    if (item.type === "Folder" && hasChildRecursively(item, allGames)) {
      setAlert({
        visible: true,
        message: "Cannot delete: Subfolders or matches present.",
      });
      return;
    }
    try {
      await deleteDoc(doc(database, "MainGame", item.id));
      console.log("Deleted doc:", item.id);
    } catch (err) {
      console.error("Error deleting doc:", err);
    }
  };

  // onDelete press to show confirmation dialog using Paper
  const onDeletePress = (item) => {
    if (item.type === "Folder" && hasChildRecursively(item, allGames)) {
      setAlert({
        visible: true,
        message: "Cannot delete: Subfolders or matches present.",
      });
      return;
    }
    setItemToDelete(item);
    setDeleteDialogVisible(true);
  };

  // Listen to MainGame collection
  useEffect(() => {
    if (!currentUser) {
      console.log("No user => showing demo or empty.");
      setAllGames([]);
      setLoading(false);
      return;
    }
    const unsub = onSnapshot(collection(database, "MainGame"), (snapshot) => {
      const allDocs = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setAllGames(allDocs);
      setLoading(false);
    });
    return () => unsub();
  }, [currentUser]);

  // Filter based on topBarFilter, folder, search, etc.
  const filteredGames = useMemo(() => {
    let items = [];
    if (topBarFilter === "admin") {
      if (folderId !== null) {
        items = allGames.filter((item) => (item.parentId ?? null) === folderId);
      } else {
        items = allGames.filter((item) => !item.parentId);
      }
    } else {
      if (folderId !== null) {
        items = allGames.filter((item) => (item.parentId ?? null) === folderId);
      } else {
        items = allGames.filter((item) => !item.parentId);
      }
      items = items.filter((item) => shouldIncludeItem(item, allGames, currentUser));
      items = items.filter((item) => passesTopBarFilter(item, currentUser, topBarFilter));
    }

    if (searchText.trim().length > 0) {
      const lowerSearch = searchText.toLowerCase();
      items = items.filter((item) => item.name?.toLowerCase().includes(lowerSearch));
    }

    items.sort((a, b) => {
      if (a.type === "Folder" && b.type !== "Folder") return -1;
      if (a.type !== "Folder" && b.type === "Folder") return 1;
      const dateA = a.created?.toDate ? a.created.toDate() : new Date(0);
      const dateB = b.created?.toDate ? b.created.toDate() : new Date(0);
      return dateB - dateA;
    });

    return items;
  }, [allGames, folderId, topBarFilter, searchText, currentUser]);

  return (
    <PaperProvider>
      <SafeAreaView style={styles.container}>
        {/* Header */}
        <View style={styles.headerContent}>
          <View style={styles.textSection}>
            <Text style={styles.headerText}>Welcome to TeamPlay</Text>
            <Text style={{ marginLeft: 5, fontStyle: "italic", color: "blue", fontSize: 13 }}>
              Only host can invite to current game.
            </Text>
          </View>
          <Pressable onPress={() => Linking.openURL("https://youtu.be/_tVMabkKJXg")}>
            <View style={styles.tutorialContainer}>
              <Image source={yourYoutubeImage} style={styles.youtubeImage} />
              <Text style={styles.tutorialText}>Tutorial</Text>
            </View>
          </Pressable>
        </View>

        {/* Search + Top Bar */}
        <View>
          <TouchableOpacity style={styles.searchContainer} onPress={() => {}}>
            <MaterialIcons name="search" size={24} color="#aaa" />
            <TextInput
              style={styles.searchInput}
              placeholder="Search my games"
              value={searchText}
              onChangeText={setSearchText}
            />
          </TouchableOpacity>

          <MyGamesTopBar
            filter={topBarFilter}
            setFilter={setTopBarFilter}
            onCreateGame={() => setCreateDialogVisible(true)}
            onStructure={() => navigation.navigate("MyGameStructure")}
          />

          <Text style={styles.myGamesTitle}>My Games</Text>
        </View>

        {/* Main List */}
        {loading ? (
          <ActivityIndicator size={50} color="#0000ff" />
        ) : (
          <ScrollView contentContainerStyle={styles.wrapContainer}>
            {filteredGames.map((item) => (
              <View key={item.id} style={styles.cardWrapper}>
                <GameItemCard
                  item={item}
                  onDelete={onDeletePress}
                  onSettings={openSettingsDialog}
                />
              </View>
            ))}
          </ScrollView>
        )}

        {/* Create Game Dialog */}
        {createDialogVisible && (
          <MyGameCreate
            visible={createDialogVisible}
            onDismiss={() => setCreateDialogVisible(false)}
            parentId={folderId}
            navigation={navigation}
          />
        )}

        {/* Settings Dialog */}
        <MyGameSettingsDialog
          visible={settingsDialogVisible}
          onDismiss={closeSettingsDialog}
          gameItem={selectedGame}
          onSaved={() => {
            console.log("Settings saved for doc:", selectedGame?.id);
          }}
        />

        {/* Alert Dialog */}
        <Portal>
          <Dialog
            visible={alert.visible}
            onDismiss={() => setAlert({ ...alert, visible: false })}
          >
            <Dialog.Title>Alert</Dialog.Title>
            <Dialog.Content>
              <Text>{alert.message}</Text>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setAlert({ ...alert, visible: false })}>OK</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {/* Delete Confirmation Dialog */}
        <Portal>
          <Dialog
            visible={deleteDialogVisible}
            onDismiss={() => setDeleteDialogVisible(false)}
          >
            <Dialog.Title>Confirm Delete</Dialog.Title>
            <Dialog.Content>
              <Text>
                Are you sure you want to delete this card? All data will be permanently lost.
              </Text>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDeleteDialogVisible(false)}>Cancel</Button>
              <Button
                onPress={async () => {
                  setDeleteDialogVisible(false);
                  await handleDelete(itemToDelete);
                  setItemToDelete(null);
                }}
              >
                Delete
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </SafeAreaView>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  textSection: {
    flex: 1,
  },
  headerText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  tutorialContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    ...(Platform.OS === "android" ? { marginBottom: 0 } : {}),
  },
  youtubeImage: {
    width: 160,
    height: 90,
    alignSelf: "flex-end",
    borderRadius: 10,
  },
  tutorialText: {
    position: "absolute",
    bottom: 10,
    left: 10,
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "blue",
    borderRadius: 5,
    marginHorizontal: 10,
    marginVertical: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  searchInput: {
    flex: 1,
  },
  myGamesTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333",
    marginVertical: 10,
    marginLeft: 10,
    alignSelf: "center",
  },
  wrapContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 10,
  },
  cardWrapper: {
    width: 200,
    margin: 0,
  },
});
