import React, { useState, useEffect } from "react";
import { View, StyleSheet, Alert } from "react-native";
import {
  Text,
  Card,
  Button,
  Portal,
  Dialog,
  RadioButton,
  TextInput,
  Provider as PaperProvider,
} from "react-native-paper";

import { database } from "../../componenets/firebase";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

export default function MyGameStructure() {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // 1) Local state for all items from Firestore (public or user’s private)
  const [games, setGames] = useState([]);

  // For expanded folders
  const [expandedFolders, setExpandedFolders] = useState({});

  // Move dialog
  const [moveDialogVisible, setMoveDialogVisible] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [targetFolder, setTargetFolder] = useState(null);

  // Rename dialog
  const [renameDialogVisible, setRenameDialogVisible] = useState(false);
  const [renameTarget, setRenameTarget] = useState(null);
  const [newName, setNewName] = useState("");

  // Create folder dialog
  const [createFolderDialogVisible, setCreateFolderDialogVisible] =
    useState(false);
  const [folderTarget, setFolderTarget] = useState(null);
  const [folderName, setFolderName] = useState("");

  /**
   * 2) Listen to Firestore in real-time for:
   *    - All public docs (isPublic == true)
   *    - OR the current user’s private docs (userId == currentUser.uid, isPublic == false)
   * We merge them so we never see private docs from others.
   */
  useEffect(() => {
    if (!currentUser) {
      setGames([]);
      return;
    }

    let unsubPublic;
    let unsubPrivate;

    // local arrays for partial results
    let publicItems = [];
    let myPrivateItems = [];

    function mergeAndSet() {
      // Combine the two arrays
      const combined = [...publicItems, ...myPrivateItems];

      // Remove duplicates if any (shouldn't happen if doc IDs are unique)
      const uniqueMap = new Map();
      for (const item of combined) {
        uniqueMap.set(item.id, item);
      }
      const finalArray = Array.from(uniqueMap.values());

      setGames(finalArray);
    }

    // 1) Public docs
    const qPublic = query(
      //collection(database, "games"),
      collection(database, "MainGame"),
      where("isPublic", "==", true)
    );
    unsubPublic = onSnapshot(qPublic, (snapshot) => {
      publicItems = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      mergeAndSet();
    });

    // 2) Current user’s private docs
    const qPrivate = query(
      collection(database, "MainGame"),
      where("userId", "==", currentUser.uid),
      where("isPublic", "==", false)
    );
    unsubPrivate = onSnapshot(qPrivate, (snapshot) => {
      myPrivateItems = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      mergeAndSet();
    });

    // Cleanup
    return () => {
      if (unsubPublic) unsubPublic();
      if (unsubPrivate) unsubPrivate();
    };
  }, [currentUser]);

  /**
   * Build a nested hierarchy from the flat array
   * using parentId => children
   */
  const buildHierarchy = (parentId = null) => {
    return games
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        children: item.type === "Folder" ? buildHierarchy(item.id) : [],
      }));
  };

  // We'll store root items in state
  const [rootItems, setRootItems] = useState([]);

  useEffect(() => {
    setRootItems(buildHierarchy(null));
  }, [games]);

  // Expand/collapse folders
  const toggleFolder = (folderId) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  // ========== HELPER: Gather All Descendants of a Folder ==========
  const gatherDescendants = (folderId, visited = new Set()) => {
    // For each item whose parentId == folderId
    games.forEach((item) => {
      if (item.parentId === folderId) {
        if (!visited.has(item.id)) {
          visited.add(item.id);
          if (item.type === "Folder") {
            gatherDescendants(item.id, visited);
          }
        }
      }
    });
    return visited;
  };

  // ========== MOVE LOGIC ==========
  const openMoveDialog = (game) => {
    setSelectedGame(game);
    setTargetFolder(null);
    setMoveDialogVisible(true);
  };

  const closeMoveDialog = () => {
    setMoveDialogVisible(false);
    setSelectedGame(null);
    setTargetFolder(null);
  };

  /**
   * Gather all folders for the move dialog, indenting subfolders with "↳ "
   */
  const getFoldersHierarchy = (
    parentId = null,
    level = 0,
    result = [],
    visited = new Set()
  ) => {
    const children = games.filter(
      (g) => g.type === "Folder" && g.parentId === parentId
    );
    for (const folder of children) {
      if (visited.has(folder.id)) {
        console.warn(`Skipping cycle or duplicate folder: ${folder.id}`);
        continue;
      }
      visited.add(folder.id);

      const arrowChain = Array(level).fill("↳ ").join("");
      result.push({ folder, arrowChain });

      getFoldersHierarchy(folder.id, level + 1, result, visited);
    }
    return result;
  };

  const handleMove = async () => {
    if (!selectedGame) return;
    const newParentId = targetFolder ? targetFolder.id : null;

    // Avoid loops if user tries to move a folder into its own descendant
    if (selectedGame.type === "Folder") {
      const descendants = gatherDescendants(selectedGame.id);
      if (descendants.has(newParentId) || newParentId === selectedGame.id) {
        Alert.alert(
          "Invalid Move",
          "You cannot move a folder into itself or one of its descendants."
        );
        closeMoveDialog();
        return;
      }
    }

    // Update local state
    setGames((prev) =>
      prev.map((g) => {
        if (g.id === selectedGame.id) {
          return { ...g, parentId: newParentId };
        }
        return g;
      })
    );

    // Persist to Firestore
    try {
      await updateDoc(doc(database, "MainGame", selectedGame.id), {
        parentId: newParentId,
      });
      console.log("Move persisted to Firestore");
    } catch (err) {
      console.error("Error updating doc for move:", err);
    }

    closeMoveDialog();
  };

  // ========== RENAME LOGIC ==========
  const openRenameDialog = (game) => {
    setRenameTarget(game);
    setNewName(game.name || "");
    setRenameDialogVisible(true);
  };

  const closeRenameDialog = () => {
    setRenameDialogVisible(false);
    setRenameTarget(null);
    setNewName("");
  };

  const handleRename = async () => {
    if (!renameTarget) return;
    // Update local state
    setGames((prev) =>
      prev.map((g) => {
        if (g.id === renameTarget.id) {
          return { ...g, name: newName };
        }
        return g;
      })
    );
    // Also update Firestore
    try {
      await updateDoc(doc(database, "MainGame", renameTarget.id), {
        name: newName,
      });
      console.log("Rename persisted to Firestore");
    } catch (err) {
      console.error("Error updating doc for rename:", err);
    }

    closeRenameDialog();
  };

  // ========== DELETE LOGIC ==========
  const deleteItem = async (game) => {
    Alert.alert(
      "Delete",
      `Are you sure you want to delete ${game.name || game.id}?`,
      [
        { text: "Cancel", style: "cancel" },
        {
          text: "OK",
          onPress: async () => {
            // Remove locally
            setGames((prev) => prev.filter((g) => g.id !== game.id));

            // Also remove from Firestore if desired
            // import { deleteDoc } from 'firebase/firestore';
            // await deleteDoc(doc(database, 'games', game.id));
          },
        },
      ]
    );
  };

  // ========== CREATE FOLDER LOGIC ==========
  const openCreateFolderDialog = (game) => {
    setFolderTarget(game);
    setFolderName("");
    setCreateFolderDialogVisible(true);
  };

  const closeCreateFolderDialog = () => {
    setCreateFolderDialogVisible(false);
    setFolderTarget(null);
    setFolderName("");
  };

  const handleCreateFolder = async () => {
    if (!folderTarget) return;
    if (folderTarget.type !== "Folder") return;

    const newFolderId = `Folder-${Date.now()}`;
    const newFolderName = folderName || `NewFolder-${Date.now()}`;
    const newFolder = {
      id: newFolderId,
      name: newFolderName,
      type: "Folder",
      parentId: folderTarget.id,
      // any other fields (e.g. userId, createdAt, isPublic, etc.)
    };

    // 1) Update local state
    setGames((prev) => [...prev, newFolder]);

    // 2) Persist to Firestore if needed
    // import { setDoc } from 'firebase/firestore';
    // await setDoc(doc(database, 'games', newFolderId), newFolder);

    closeCreateFolderDialog();
  };

  /**
   * Recursively render each item in a card style
   */
  const renderItem = (item, level = 0) => {
    // "▼" or "►" for folders, "●" for matches
    let symbol = "●";
    if (item.type === "Folder") {
      const isExpanded = !!expandedFolders[item.id];
      symbol = isExpanded ? "▼" : "►";
    }

    const displayName = item.name || item.id;

    return (
      <View key={item.id} style={{ marginLeft: level * 12 }}>
        <Card style={styles.card}>
          <Card.Content style={styles.cardRow}>
            <Text
              style={styles.toggleSymbol}
              onPress={() => {
                if (item.type === "Folder") toggleFolder(item.id);
              }}
            >
              {symbol}
            </Text>

            <View style={styles.textContainer}>
              <Text style={styles.titleText}>{displayName}</Text>
              <Text style={styles.subtitleText}>
                {item.type === "Folder" ? "Folder" : "Match"}
              </Text>
            </View>

            <View style={styles.actionsContainer}>
              {item.type === "Folder" && (
                <Button
                  mode="text"
                  onPress={() => openCreateFolderDialog(item)}
                  labelStyle={styles.actionLabel}
                >
                  New Folder
                </Button>
              )}
              <Button
                mode="text"
                onPress={() => openMoveDialog(item)}
                labelStyle={styles.actionLabel}
              >
                Move
              </Button>
              <Button
                mode="text"
                onPress={() => openRenameDialog(item)}
                labelStyle={styles.actionLabel}
              >
                Rename
              </Button>
              <Button
                mode="text"
                onPress={() => deleteItem(item)}
                labelStyle={styles.actionLabel}
              >
                Delete
              </Button>
            </View>
          </Card.Content>
        </Card>

        {/* If folder is expanded, recursively render children */}
        {item.type === "Folder" &&
          expandedFolders[item.id] &&
          item.children.map((child) => renderItem(child, level + 1))}
      </View>
    );
  };

  return (
    <PaperProvider>
      <View style={styles.container}>
        <Text style={styles.header}>Structure Game</Text>
        {rootItems.map((item) => renderItem(item))}
      </View>

      {/* Move Dialog */}
      <Portal>
        <Dialog visible={moveDialogVisible} onDismiss={closeMoveDialog}>
          <Dialog.Title>
            Move {selectedGame?.name || selectedGame?.id}
          </Dialog.Title>
          <Dialog.Content>
            <Text>Select a folder to move into (or Root):</Text>
            <RadioButton.Group
              onValueChange={(value) => {
                if (value === "root") {
                  setTargetFolder(null);
                } else {
                  const folder = games.find((g) => g.id === value);
                  setTargetFolder(folder);
                }
              }}
              value={targetFolder ? targetFolder.id : "root"}
            >
              <RadioButton.Item label="(Root)" value="root" />
              {getFoldersHierarchy().map(({ folder, arrowChain }) => {
                const label = arrowChain + (folder.name || folder.id);
                return (
                  <RadioButton.Item
                    key={folder.id}
                    label={label}
                    value={folder.id}
                  />
                );
              })}
            </RadioButton.Group>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={closeMoveDialog}>Cancel</Button>
            <Button onPress={handleMove}>Move</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      {/* Rename Dialog */}
      <Portal>
        <Dialog visible={renameDialogVisible} onDismiss={closeRenameDialog}>
          <Dialog.Title>Rename</Dialog.Title>
          <Dialog.Content>
            <TextInput
              label="New Name"
              value={newName}
              onChangeText={setNewName}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={closeRenameDialog}>Cancel</Button>
            <Button onPress={handleRename}>Save</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      {/* Create Folder Dialog */}
      <Portal>
        <Dialog
          visible={createFolderDialogVisible}
          onDismiss={closeCreateFolderDialog}
        >
          <Dialog.Title>Create Folder</Dialog.Title>
          <Dialog.Content>
            <Text>Parent folder: {folderTarget?.name || folderTarget?.id}</Text>
            <TextInput
              label="Folder Name"
              value={folderName}
              onChangeText={setFolderName}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={closeCreateFolderDialog}>Cancel</Button>
            <Button onPress={handleCreateFolder}>Create</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    fontSize: 20,
    marginBottom: 12,
  },
  card: {
    marginVertical: 4,
    borderRadius: 8,
  },
  cardRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  toggleSymbol: {
    marginRight: 10,
    fontSize: 20,
    width: 24,
    textAlign: "center",
  },
  textContainer: {
    flex: 1,
  },
  titleText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  subtitleText: {
    fontSize: 12,
    color: "#666",
  },
  actionsContainer: {
    flexDirection: "row",
    marginLeft: 8,
  },
  actionLabel: {
    fontSize: 12,
    marginHorizontal: 4,
  },
});
