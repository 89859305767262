import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Switch,
  Platform,
  Modal,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import { doc, onSnapshot, deleteDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Button, Snackbar } from "react-native-paper";
import { database } from "../componenets/firebase";

/** Reusable + / - numeric input */
const NumberInputControl = ({ value, setValue, placeholder }) => {
  const handleIncrement = () => {
    let num = parseInt(value, 10);
    if (isNaN(num)) num = 0;
    setValue((num + 1).toString());
  };

  const handleDecrement = () => {
    let num = parseInt(value, 10);
    if (isNaN(num)) num = 0;
    setValue((num - 1).toString());
  };

  const handleChangeText = (text) => {
    // Allow minus sign + digits only
    if (/^-?\d*$/.test(text)) {
      setValue(text);
    }
  };

  return (
    <View style={styles.numericInputWrapper}>
      <TouchableOpacity onPress={handleDecrement} style={styles.numericButton}>
        <Text style={styles.numericButtonText}>–</Text>
      </TouchableOpacity>
      <TextInput
        style={styles.numericInput}
        value={value}
        onChangeText={handleChangeText}
        placeholder={placeholder}
        keyboardType="numeric"
      />
      <TouchableOpacity onPress={handleIncrement} style={styles.numericButton}>
        <Text style={styles.numericButtonText}>+</Text>
      </TouchableOpacity>
    </View>
  );
};

const AddPlayerScreen = ({ navigation, route }) => {
  const currentMainGameId = route.params.mainGameId.toString();

  // Points
  const [enablePoints, setEnablePoints] = useState(false);
  const [hasPoints, setHasPoints] = useState(false);
  const [winPoints, setWinPoints] = useState("0");
  const [losePoints, setLosePoints] = useState("0");
  const [drawPoints, setDrawPoints] = useState("0");
  const [attendancePoints, setAttendancePoints] = useState("0");
  const [absentPoints, setAbsentPoints] = useState("0");
  const [TeenGoalPoints, setTeenGoalPoints] = useState("0");

  // Ownership & players
  const [players, setPlayers] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const owner = players.find((p) => p.isOwner);
  const ownerEmail = owner ? owner.email : "No owner found";

  // Web-only modal
  const [showWebAlert, setShowWebAlert] = useState(false);

  // Snackbar
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const auth = getAuth();

  /** Toggles "Enable Points" in Firestore */
  const handleEnablePointsToggle = async (value) => {
    const gameRef = doc(database, "MainGame", currentMainGameId);
    await setDoc(gameRef, { pointsEnabled: value }, { merge: true });
    setEnablePoints(value);
  };

  /** Confirms "Delete Game" on web */
  const handleWebAlertResponse = async (response) => {
    setShowWebAlert(false);
    if (response === "delete") {
      const docRef = doc(database, "MainGame", currentMainGameId);
      await deleteDoc(docRef);
      navigation.navigate("My Games");
    }
  };

  /** Submits all points settings */
  const handleSubmit = async () => {
    const gameRef = doc(database, "MainGame", currentMainGameId);
    try {
      if (hasPoints) {
        await setDoc(
          gameRef,
          {
            winPoints: parseInt(winPoints, 10),
            losePoints: parseInt(losePoints, 10),
            drawPoints: parseInt(drawPoints, 10),
            attendancePoints: parseInt(attendancePoints, 10),
            absentPoints: parseInt(absentPoints, 10),
            TeenGoalPoints: parseInt(TeenGoalPoints, 10),
            pointsEnabled: true,
          },
          { merge: true }
        );
      } else {
        await setDoc(
          gameRef,
          { pointsEnabled: false },
          { merge: true }
        );
      }
      setSnackbarMessage("Game updated successfully!");
      setSnackbarVisible(true);
    } catch (error) {
      setSnackbarMessage("Error updating game: " + error.message);
      setSnackbarVisible(true);
    }
  };

  /** Listen for game updates (points, etc.) */
  useEffect(() => {
    const gameRef = doc(database, "MainGame", currentMainGameId);
    const unsubscribe = onSnapshot(gameRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.pointsEnabled !== undefined) {
          setEnablePoints(data.pointsEnabled);
          setHasPoints(data.pointsEnabled);
        }
        if (data.winPoints !== undefined) setWinPoints(data.winPoints.toString());
        if (data.losePoints !== undefined) setLosePoints(data.losePoints.toString());
        if (data.drawPoints !== undefined) setDrawPoints(data.drawPoints.toString());
        if (data.attendancePoints !== undefined) {
          setAttendancePoints(data.attendancePoints.toString());
        }
        if (data.absentPoints !== undefined) {
          setAbsentPoints(data.absentPoints.toString());
        }
        if (data.TeenGoalPoints !== undefined) {
          setTeenGoalPoints(data.TeenGoalPoints.toString());
        }
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId]);

  /** Listen for member changes */
  useEffect(() => {
    const currentUserId = auth.currentUser ? auth.currentUser.uid : "";
    const docRef = doc(database, "MainGame", currentMainGameId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const gameData = docSnap.data();
        if (Array.isArray(gameData.memberOfGame)) {
          const currentPlayer = gameData.memberOfGame.find(
            (p) => p.uid === currentUserId
          );
          if (currentPlayer?.isOwner) {
            setIsOwner(true);
          }
          setPlayers(
            gameData.memberOfGame.map((player, index) => ({
              id: index + 1,
              name: player.name,
              email: player.email,
              uid: player.uid,
              isAdmin: player.isAdmin || false,
              isOwner: player.isOwner || false,
            }))
          );
        }
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId, auth.currentUser]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <SafeAreaView style={styles.container}>
          <ScrollView contentContainerStyle={styles.scrollContainer}>
            <Text style={styles.title}>
              Owner: {owner ? owner.name : "Not set"} - {ownerEmail}
            </Text>

            {Platform.OS === "web" && (
              <Modal
                visible={showWebAlert}
                onRequestClose={() => setShowWebAlert(false)}
              >
                <View style={styles.modal}>
                  <Text style={styles.modalTitle}>
                    Are you sure you want to delete this game?
                  </Text>
                  <View style={styles.modalButtonsContainer}>
                    <Button onPress={() => setShowWebAlert(false)}>Cancel</Button>
                    <Button onPress={() => handleWebAlertResponse("delete")}>
                      Delete
                    </Button>
                  </View>
                </View>
              </Modal>
            )}

            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Points in Game</Text>
              <Text style={styles.sectionDescription}>
                To encourage attendance and winning, you can award or subtract
                points. This option adds an extra level of excitement.
              </Text>
            </View>

            {/* "Enable Points" row */}
            <View style={styles.pointsRow}>
              <Text style={styles.pointsLabel}>Enable Points:</Text>
              <View style={styles.enablePointsRow}>
                <Switch
                  value={enablePoints}
                  onValueChange={handleEnablePointsToggle}
                  trackColor={{ false: "#767577", true: "#81b0ff" }}
                  thumbColor={enablePoints ? "#f5dd4b" : "#f4f3f4"}
                />
                <Text style={styles.switchStatus}>
                  {enablePoints ? "Enabled" : "Disabled"}
                </Text>
              </View>
            </View>

            <Text style={styles.infoText}>
              If points are disabled, the game score will be used instead.
            </Text>

            {/* Points fields */}
            {hasPoints && (
              <>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Win Points:</Text>
                  <NumberInputControl
                    value={winPoints}
                    setValue={setWinPoints}
                    placeholder="Enter win points"
                  />
                </View>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Lose Points:</Text>
                  <NumberInputControl
                    value={losePoints}
                    setValue={setLosePoints}
                    placeholder="Enter lose points"
                  />
                </View>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Draw Points:</Text>
                  <NumberInputControl
                    value={drawPoints}
                    setValue={setDrawPoints}
                    placeholder="Enter draw points"
                  />
                </View>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Attendance Points:</Text>
                  <NumberInputControl
                    value={attendancePoints}
                    setValue={setAttendancePoints}
                    placeholder="Enter attendance points"
                  />
                </View>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Absent Points:</Text>
                  <NumberInputControl
                    value={absentPoints}
                    setValue={setAbsentPoints}
                    placeholder="Enter absent points"
                  />
                </View>
                <View style={styles.pointsRow}>
                  <Text style={styles.pointsLabel}>Teen Goal Points:</Text>
                  <NumberInputControl
                    value={TeenGoalPoints}
                    setValue={setTeenGoalPoints}
                    placeholder="Enter teen goal points"
                  />
                </View>
              </>
            )}

            <Button
              mode="contained"
              onPress={handleSubmit}
              style={styles.submitButton}
            >
              Submit
            </Button>
          </ScrollView>

          {/* Snackbar feedback */}
          <Snackbar
            visible={snackbarVisible}
            onDismiss={() => setSnackbarVisible(false)}
            duration={3000}
          >
            {snackbarMessage}
          </Snackbar>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

/** STYLES */
const labelWidth = 140;     // Adjust for consistent label area
const buttonWidth = 36;     // Width for minus/plus buttons
const inputWidth = 60;      // Width for numeric input

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  scrollContainer: {
    padding: 16,
    paddingBottom: 32,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 8,
  },
  sectionDescription: {
    fontSize: 14,
    color: "#555",
  },
  infoText: {
    fontSize: 12,
    color: "gray",
    marginBottom: 20,
  },

  /** Points row for both "Enable Points" and numeric inputs */
  pointsRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  pointsLabel: {
    width: labelWidth,
    fontSize: 16,
    textAlign: "right",
    marginRight: 12,
  },
  enablePointsRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  switchStatus: {
    marginLeft: 8,
    fontSize: 16,
  },

  submitButton: {
    marginTop: 24,
    alignSelf: "center",
    width: "60%",
  },

  /** Web-only modal */
  modal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
    color: "#fff",
  },
  modalButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },

  /** NumberInputControl styles */
  numericInputWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  numericButton: {
    width: buttonWidth,
    height: 40,
    backgroundColor: "#ddd",
    borderRadius: 4,
    marginHorizontal: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  numericButtonText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  numericInput: {
    width: inputWidth,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 6,
    fontSize: 16,
    textAlign: "center",
    marginHorizontal: 2,
  },
});

export default AddPlayerScreen;
