import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Card, Paragraph, Button, Text } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "../../componenets/firebase";
import dayjs from "dayjs";

// Import our live real‑time hook for folders
import { countTotalmembers } from "./getTotalMembersForFolder";

// New hook for listening to match document changes
function useLiveMatchMemberCount(matchId) {
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    if (!matchId) return;
    const docRef = doc(database, "games", matchId);
    const unsub = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCount(data.members || 0);
      }
    });
    return () => unsub();
  }, [matchId]);
  return count;
}

export default function MyGameItemCard({ item, onDelete, onSettings }) {
  const navigation = useNavigation();

  // Use live hooks to get member counts.
  // If it's a Folder, use the countTotalmembers hook.
  // If it's a Match, use the new useLiveMatchMemberCount hook.
  const liveMembers =
    item.type === "Folder"
      ? countTotalmembers(item.docId)
      : useLiveMatchMemberCount(item.docId);

  // "Enter" logic: navigate to MyMatch (for match) or MyGames2 (for folder).
  const handleEnterPress = () => {
    if (!item.docId) {
      alert("Document ID is missing. Please create the document first.");
      return;
    }
    if (item.type === "Match") {
      navigation.navigate("MyMatch", { matchId: item.docId });
    } else if (item.type === "Folder") {
      navigation.push("MyGames2", { folderId: item.docId });
    }
  };

  const cardBackgroundColor = item.type === "Folder" ? "#E0F7FA" : "#FFF8E1";

  return (
    <Card style={[styles.card, { backgroundColor: cardBackgroundColor }]}>
      {/* Display image if available */}
      {item.imageUrl && <Card.Cover source={{ uri: item.imageUrl }} />}
      <Card.Title
        style={{ alignItems: "center", justifyContent: "center" }}
        titleStyle={{ textAlign: "center", fontWeight: "bold" }}
        //subtitleStyle={{ textAlign: "center" }}
        title={item.name || item.docId}
        subtitle={`Type: ${item.type || ""}`}  
       />
      <Card.Content>        
        <Paragraph>createdAt: {item.createdAt ? dayjs(item.createdAt).format("MMM 'YY") : "-"}</Paragraph>
        <Paragraph>{item.isPublic ? "Public" : "Private"} (by {item.userId})</Paragraph>        
        <Paragraph>GameCategory: {item.gameCategory}</Paragraph>
        <View style={styles.membersRow}>
          <Paragraph>Members: </Paragraph>
          <Text style={styles.memberText}>{liveMembers}</Text>
        </View>
        <Paragraph style={styles.devId}>DocID: {item.docId}</Paragraph>
      </Card.Content>

      <Card.Actions>
        <Button onPress={handleEnterPress}>Enter</Button>
              {/* Icons row */}
      <View style={styles.iconsRow}>
        <TouchableOpacity onPress={() => onDelete(item)}>
          <MaterialIcons name="delete" size={20} color="red" style={styles.icon} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onSettings(item)}>
          <MaterialIcons name="settings" size={20} color="#333" style={styles.icon} />
        </TouchableOpacity>
      </View>
      </Card.Actions>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
  },
  iconsRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  icon: {
    marginHorizontal: 6,
  },
  membersRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  memberText: {
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 6,
  },
  devId: {
    fontSize: 10,
    color: "gray",
    marginTop: 8,
  },
});
